import React from "react";
import { Suspense } from 'react';
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "./store";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./packages/useCrashReport/useCrashReport";
import * as Sentry from "@sentry/browser";

import i18next from "./config/i18next.client"; // this is what makes the translation work. DO NOT REMOVE IT

import ReactDOM from "react-dom/client";

const sentryDsn = require('config/app.config').sentryDsn;

Sentry.init({
    dsn: sentryDsn,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Suspense fallback="...is loading">
                    <App />
                </Suspense>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// Interesting blog post about React: https://www.taniarascia.com/getting-started-with-react/
