import {Profile} from "./userDTO";

export const types = {
    SET_USER_PROFILE: "SET_USER_PROFILE",
    SET_SIGNED_IN: "SET_SIGNED_IN",
}

export function setUserProfile(profile: Profile) {
    return { type: types.SET_USER_PROFILE, profile }
}

export function setIsLoggedIn(isLoggedIn: boolean) {
    return { type: types.SET_SIGNED_IN, isLoggedIn }
}
