import { initReactI18next } from 'react-i18next';

import i18next from "i18next";
import backend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const config = require('./app.config');

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};


i18next
    // i18next-http-backend
    // loads translations from your server
    .use(backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(I18nextBrowserLanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
;

console.log('i18next-client', __dirname);
if (!i18next.isInitialized) {
    i18next
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            // initImmediate: false,
            detection: DETECTION_OPTIONS,
            debug: true,
            saveMissing: true,
            saveMissingTo: "current",
            // lng: 'en',
            fallbackLng: config.fallbackLng,
            whitelist: config.languages,
            react: {
                wait: false
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend:{
                // path where resources get loaded from
                loadPath: '/locales/{{lng}}/{{ns}}.json',

                // path to post missing resources
                // addPath: './public/locales/{{lng}}/{{ns}}.missing.json',

                // jsonIndent to use when storing json files
                // jsonIndent: 2
            }
        });
}

export default i18next;
