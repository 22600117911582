const styles = () => ({
    root: {

    },
    paper: {
        // width: '80vw',
        width: '100%',
        height: '67vh',
        maxHeight: '700px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        position: 'relative' as 'relative'
    },
    messagesBody: {
        width: 'calc( 100% - 40px )',
        margin: 10,
        overflowY: 'scroll' as 'scroll',
        height: 'calc( 100% - 10px )',
        border: 0,
    },
    message: {
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    username: {
        textAlign: 'right' as 'right',
        minWidth: '20%',
        float: 'right' as 'right'
    },
    messages: {
        width: '100%',
        height: '100%',
    }
});

export default styles;
