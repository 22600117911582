import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        maxHeight: '94%',
    },
    h3: {
        marginBottom: theme.spacing(2)
    },
    transmissionDataSection: {
        margin: theme.spacing(3, 2),
    },
    socialNetworkSection: {
        margin: theme.spacing(3, 2),
    },
    chat: {
        // borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        // padding: theme.spacing(2),
        // height: '100vh',
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    menuItemIcon: {
        display: 'flex',
        justifyContent: 'center',
    },
});

export default styles;
