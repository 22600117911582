import {Fragment, useEffect, useState} from "react";
import {fetchStreamTargetIntegrations} from "./streamTargetIntegrationActions";
import {connect} from "react-redux";
import {Grid, LinearProgress} from "@mui/material";
import {withStyles} from "@mui/styles";
import styles from "./StreamTargetIntegrations-jss";
import {StreamTargetIntegration} from "./streamTargetIntegrationDTO";
import StreamTargetDialogWidget from "../StreamTargetDialog/StreamTargetDialogWidget";
import StreamIntegrationCard from "./StreamIntegrationCard";
import {Profile} from "../User/userDTO";

type Properties = {
    classes: any,
    streamTargetIntegrations: Array<StreamTargetIntegration>,
    profile: Profile,
    fetchStreamTargetIntegrations: Function,
}

function StreamTargetIntegrations(props: Properties) {
    const {
        classes,
        streamTargetIntegrations,
        profile,
        fetchStreamTargetIntegrations,
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log(streamTargetIntegrations)
        setIsLoading(true);
        fetchStreamTargetIntegrations(profile.token);
    }, []);

    useEffect(() => {
        setIsLoading(false);
    }, [streamTargetIntegrations]);

    return (
        <Grid
            item
            container
            alignItems="flex-start"
            direction="row"
            spacing={2}
        >
            { isLoading ? (
                <LinearProgress className={classes.progressBar} />
            ) : (
                <>
                    {streamTargetIntegrations.map((streamTargetIntegration: StreamTargetIntegration) => (
                        <Fragment>
                            {streamTargetIntegration.code === 'instagram' && profile.email === 'facebook.user@facebook.com' ? null : (
                                <Grid item xs={4} key={ streamTargetIntegration.code }>
                                    <StreamIntegrationCard key={ streamTargetIntegration.code } streamTargetIntegration={streamTargetIntegration} />
                                </Grid>
                            )}
                        </Fragment>
                    ))}
                </>
            )}

            <StreamTargetDialogWidget />
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        streamTargetIntegrations: state.streamTargetIntegrations,
        profile: state.user.profile,
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        fetchStreamTargetIntegrations: (apiToken: string) => dispatch(fetchStreamTargetIntegrations(apiToken)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StreamTargetIntegrations));
