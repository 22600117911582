import {useState, useEffect, Fragment} from "react";
import { connect } from "react-redux";
import {
    Button, Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import styles from "./ListEventsPage-jss";
import classNames from "classnames";
import {Delete, Edit, Videocam} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Profile} from "../User/userDTO";
import {Dispatch} from "redux";
import {DeleteEventRequest, EventsUIState, LiveEventsMap} from "./EventsDTO";
import {deleteEventRequest, fetchEventsRequest} from "./eventsAtcions";
import {withStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import Page from "../../components/Page/Page";

type Properties = {
    classes: any,
    profile: Profile,
    eventsUI: EventsUIState,
    events: LiveEventsMap,
    fetchEventsRequest: Function,
    deleteEventRequest: Function,
}

function ListEventsPage(props: Properties) {
    const {
        classes,
        profile,
        eventsUI,
        events,
        fetchEventsRequest,
        deleteEventRequest,
    } = props;
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (profile == null || profile['token'] === undefined) {
            navigate("/");
            return;
        }
        fetchEventsRequest(profile.token);
    }, []);

    useEffect(() => {
        setLoading(eventsUI.isLoading);
    }, [eventsUI.isLoading]);

    const handleDelete = async (code: string) => {
        console.log('handleDelete()', code);
        deleteEventRequest({
            token: profile.token,
            slug: events[code].slug
        });
    }

    return (
        <Page desc="" title={t('Events')}>
            <Grid container direction="column" spacing={5} alignItems="flex-start">
                <Grid item>
                    <Button variant="contained" color="primary" className={classes.newEventButton} onClick={() => { navigate('/events/new') }}>
                        {t('Create an Event')}
                    </Button>
                </Grid>

                <Grid item container
                      direction="row"
                      alignItems="flex-start"
                      xs={10}
                      md={10}
                      lg={10}>
                    {isLoading ? (
                        <div className={classNames(classes.loaderArea)}>
                            <LinearProgress />
                        </div>
                    ): (
                        <Fragment>
                            {Object.keys(events).length > 0 ? (
                                <TableContainer>
                                    <Table className={classNames(classes.table)} aria-label="events table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="30%">{t('Event')}</TableCell>
                                                {/*<TableCell>{t('Created at')}</TableCell>*/}
                                                <TableCell width="60%"></TableCell>
                                                <TableCell width="5%"></TableCell>
                                                <TableCell width="5%"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(events).map(code => (
                                                <TableRow key={events[code].code}>
                                                    <TableCell>{events[code].title}</TableCell>
                                                    {/*<TableCell>{new Date(events[code].created_at).toLocaleDateString()}</TableCell>*/}
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            disableElevation
                                                            color="secondary"
                                                            onClick={() => navigate('/studio/' + events[code].code)}
                                                            startIcon={<Videocam />}
                                                        >
                                                            {t('Enter Live Studio')}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title={t('Edit')||''}>
                                                            <Button
                                                                color="inherit"
                                                                onClick={() => navigate('/events/' + events[code].code)}
                                                                startIcon={<Edit />}
                                                                aria-label={t('Edit')}
                                                            >{t('Edit')}</Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title={t("Delete")||''}>
                                                            <Button
                                                                color="inherit"
                                                                onClick={() => handleDelete(events[code].code)}
                                                                startIcon={<Delete />}
                                                                aria-label={t('Delete')}
                                                            >{t('Delete')}</Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : <div className={classNames(classes.noEventDiv)}>{t('You still haven\'t created any event')}</div>}
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        profile: state.user.profile,
        events: state.events,
        eventsUI: state.eventsUI,
    };
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        fetchEventsRequest: (token: string) => dispatch(fetchEventsRequest(token)),
        deleteEventRequest: (request: DeleteEventRequest) => dispatch(deleteEventRequest(request)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListEventsPage));
