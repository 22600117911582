const styles = theme => ({
    root: {
        display: 'flex',
    },
    newEventButton: {
        marginBottom: theme.spacing(4),
    },
    noEventDiv: {
        width: '100%'
    },
    actionButtons: {
        marginLeft: theme.spacing(1)
    }
});

export default styles;
