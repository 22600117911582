import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        maxHeight: '94%',
    },
    list: {
        listStyleType: "none",
        "& li:not(:last-child)": {
            marginBottom: theme.spacing(1),
        }
    },
});

export default styles;
