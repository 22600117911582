import {withStyles} from "@mui/styles";
import styles from "./AppStructure-jss";
import {Box, CssBaseline} from "@mui/material";
import LeftMenu from "../MainMenu/MainMenu";
import { styled } from '@mui/material/styles';
import {Outlet} from "react-router-dom";

type Properties = {
    classes: any,
    children: any,
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function AppStructure(props: Properties) {
    const {
        children,
    } = props;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <LeftMenu />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    )
}

export default withStyles(styles)(AppStructure)
