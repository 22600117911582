import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import analyticsClient from "../../services/analytics-client/analytics-client";
import Hotjar from "@hotjar/browser";

const hotjar = require('config/app.config').hotjar;

function usePageViews(userId?: string) {
    const location = useLocation();

    useEffect(() => {
        // if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
            analyticsClient.trackPageView(document.location.origin + location.pathname + location.search, userId);

            Hotjar.init(hotjar.siteId, hotjar.version);
            if (userId) {
                Hotjar.identify(userId, {});
            }

        // }
    }, [location])

    return null;
}

export default usePageViews;
