import React, {useEffect, useRef, useState} from 'react';

import WowzaWebRTCPlay from "./lib/WowzaWebRTCPlay";
import {Dispatch} from "redux";
import {withStyles} from "@mui/styles";
import styles from "../StreamTargetLive4tvApi/Live4tvApi-jss";
import {connect} from "react-redux";
import {StreamTargetList} from "../StreamTarget/streamTargetDTO";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {Alert, Button} from "@mui/material";
import {OndemandVideo, PlayArrow} from "@mui/icons-material";
import {Profile} from "../User/userDTO";

type Properties = {
    classes: any,
    liveEvent: any;
    streamTargets: StreamTargetList,
    profile: Profile,
}

const Player = (props: Properties) => {
    const signalingUrl = "wss://5fa0b37d2a2bd.streamlock.net/webrtc-session.json";
    const wsRef = useRef<WowzaWebRTCPlay | null>(null);
    const totalAttempts = useRef(0)
    const { classes, liveEvent, streamTargets, profile } = props;
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    console.log({liveEvent, streamTargets, profile});
    const streamName = liveEvent.event_transmission_accesses[0]?.reference;
    useEffect(() => {
        play();
    }, [streamName]);

    const play = () => {
        wsRef.current = new WowzaWebRTCPlay();
        wsRef.current.on({
            onError:errorHandler,
            onStateChanged: (state: any) => {
                console.log("Player.tsx State: ", state);
                if (state.connectionState === 'connected') {
                    connected();
                    setErrorMessage(null);
                } else {
                    errorHandler(state);
                }
            }
        });
        wsRef.current.set({
            videoElementPlay:document.getElementById('player-video'),
        });

        const settings = {
            applicationName:"restream",
            sdpURL: signalingUrl,
            streamName: streamName
        }
        wsRef.current.set(settings).then(() => {
            wsRef.current?.play();
        });
    }

    const connected = () => {
        console.log("Connected");
        let videoElement = document.getElementById('player-video') as HTMLVideoElement;
        // colocar um tempo de 3 segundos depois dar o play
        setTimeout(() => {
            videoElement.play();
        }, 3000);
    }

    const errorHandler = (error: any) => {
        setErrorMessage(t('Failed to connect to the stream, did you stop broadcasting? Check your broadcasting program (OBS|Vimeo) and try again.'))
    }

    return <>
         <video id={'player-video'} width={"500px"} controls autoPlay></video>
        {errorMessage && (
            <div>
                <Alert style={{maxWidth: "500px"}} severity="error" className={classNames(classes.alert)}>
                    {errorMessage}
                </Alert>

                <Button size="small" style={{margin: "10px"}} variant="contained" color="secondary" startIcon={<PlayArrow />} onClick={() => { setErrorMessage(null); play(); }}>{t('Try again')}</Button>
            </div>
        )}
        </>
};

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
        streamTargets: state.streamTargets,
        live4tvApiIntegration: state.live4tvApiIntegration,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Player));
