import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  title: {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    position: 'relative' as 'relative',
    textTransform: 'capitalize' as 'capitalize',
    fontSize: 28,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute' as 'absolute',
      bottom: 0,
      left: 0,
      width: 40,
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  },
  description: {
    maxWidth: 960,
    fontSize: 16,
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  whiteBg: {
    backgroundColor: 'transparent',
    margin: 0,
  },
  noMargin: {},
  colorMode: {
    backgroundColor: theme.palette.secondary.main,
    '& $title': {
      color: theme.palette.grey[100],
      '&:after': {
        borderBottom: `5px solid ${theme.palette.primary.light}`
      }
    },
    '& $description': {
      color: theme.palette.grey[100],
    }
  },
  overflowX: {
    width: '100%',
    overflowX: 'auto' as 'auto',
  }
});

export default styles;
