import {types} from "./eventsAtcions";
import {LiveEventsMap} from "./EventsDTO";

function events(state: LiveEventsMap = {}, action: { type: string, payload: LiveEventsMap }) {
    switch (action.type) {
        case types.FETCH_EVENTS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export default events;
