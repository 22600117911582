import {Auth, Profile, Segment} from "./userDTO";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {ChangeEvent, FormEvent, Fragment, useEffect, useState} from "react";
import useCrashReport from "../../packages/useCrashReport/useCrashReport";
import classNames from "classnames";
import {
    Alert,
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    Link, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from "@mui/material";
import PasswordField from "../../components/PasswordField";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import styles from "./SignUpForm-jss";
import {setIsLoggedIn, setUserProfile} from "./userActions";
import useGTM from "../../packages/useGTM/useGTM";
import {live4tvUserService} from "./live4tvUserService";
import {Business, Person} from "@mui/icons-material";
import MuiPhoneNumber from "mui-phone-number";

type Properties = {
    classes: any,
    auth: Auth,
    profile: Profile,
    setUserProfile: Function,
    setIsLoggedIn: Function,
}
function SignupPage(props: Properties) {
    const {
        classes,
        auth,
        profile,
        setUserProfile,
        setIsLoggedIn,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [segment, setSegment] = useState<Segment>('na');
    const [persona, setPersona] = useState('');
    const [personaOptions, setPersonaOptions] = useState<string[]>([]);
    const [personaOther, setPersonaOther] = useState('');
    const [whatsapp, setWhatsapp] = useState('');

    const navigate = useNavigate();

    const crashReporter = useCrashReport();

    const { t } = useTranslation();

    const gtm = useGTM({});


    useEffect(() => {
        console.debug('SignupPage');
    }, []);

    useEffect(() => {
        console.debug('signedIn', auth.isLoggedIn);
        if (auth.isLoggedIn) {
            crashReporter.setUser(profile.code);
            navigate('/events');
        }
    }, [auth.isLoggedIn]);

    useEffect(() => {
        if (segment === 'person') {
            setPersonaOptions(['Gamer', 'Marketing', 'Influencer', 'Journalist', 'Musician', 'Politician', 'Teacher', 'Student']);
        } else {
            setPersonaOptions(['Church', 'Radio', 'TV Station', 'Shop', 'Podcast', 'Studio', 'Public Sector', 'Liveshop', 'Sales', 'Auction']);
        }
    }, [segment]);

    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await live4tvUserService.signUp({
                firstName: firstName,
                lastName: lastName,
                username: username,
                password: password,
                segment: segment,
                persona: (persona === 'other') ? personaOther : persona,
                whatsapp: whatsapp,
            });
            console.debug('handleSignUp().signup', response);

            const user = await live4tvUserService.getUser(response.token);
            console.log('handleLogin().user', user);

            const profile: Profile = {
                firstname: user.firstname,
                lastnames: user.lastnames,
                code: user.code,
                email: user.email,
                username: user.username,
                token: response.token,
                roles: response.roles,
                segment: segment,
                persona: persona,
                whatsapp: whatsapp,
            }

            setUserProfile(profile);
            setIsLoggedIn(true);
            gtm.trackEvent('signup', {
                user: {
                    id: profile.code
                },
            });
        } catch (e: any) {
            console.debug('handleLogin().error', e);
            let errors = '';
            if (e.errors !== undefined) {
                e.errors.forEach((error: string) => errors += t(error) + '\n');
            } else {
                errors = t('Something didn\'t work well. Please, try again');
            }
            setFormError(errors);
        }
        setIsLoading(false);
    }

    return (
        <Grid container className={classNames(classes.root)} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={10} md={8} lg={6} container direction="column" spacing={2}>
                <Grid item>
                    <Paper className={classNames(classes.paperForm)} elevation={0} square>
                        {segment === 'na' ? (
                            <Grid item xs={12} container spacing={5} direction="column" justifyContent="center">
                                <Grid item>
                                    <Typography component="h1" variant="h5">
                                        {t("How do you want to use Live4.tv?")}
                                    </Typography>
                                    <Typography component="p">
                                        {t("Select how do you want to use Live4.tv. If for your company or for personal use.")}
                                    </Typography>
                                </Grid>

                                <Grid item container direction="row" spacing={5}>
                                    <Grid item xs={6}>
                                        <Button onClick={() => setSegment('person')} fullWidth variant="contained" startIcon={<Person />}>{t('For personal use')}<br/>({t('Legal Person')})</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => setSegment('entity')} fullWidth variant="contained" startIcon={<Business />}>{t('For mine/a company')}<br/>({t('Legal Entity')})</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            ) : (
                            <form onSubmit={handleSignUp} className={classNames(classes.form)}>
                                {isLoading ? <LinearProgress className={classNames(classes.progressBar)}/> : (
                                    <div>
                                        <Typography component="h1" variant="h5">
                                            {t("Signup")}
                                        </Typography>
                                        {formError ? <Alert severity="error"
                                                            className={classNames(classes.alert)}>{formError}</Alert> : ''}

                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                margin="dense"
                                                id="firstname"
                                                label={t("Your firstname")}
                                                type="text"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="lastname"
                                                label={t("Your lastnames")}
                                                type="text"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <TextField
                                                margin="dense"
                                                id="login"
                                                label={(segment === 'person') ? t("Enter with an email") : t("Enter with your company email")}
                                                type="email"
                                                fullWidth
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                        </FormControl>

                                        <FormControl fullWidth></FormControl>

                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <InputLabel htmlFor="password">{t("Define a password") + ' *'}</InputLabel>
                                            <PasswordField
                                                margin="dense"
                                                id="password"
                                                value={password}
                                                fullWidth
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                                required
                                            />
                                        </FormControl>

                                        <FormControl fullWidth></FormControl>

                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <InputLabel>{(segment === 'person') ? t("Select your profession") : t("Select your company segment")}</InputLabel>
                                            <Select
                                                fullWidth
                                                value={persona}
                                                onChange={(e) => setPersona(e.target.value as string)}>
                                                label={(segment === 'person') ? t("Select your profession") : t("Select your company segment")}
                                                {personaOptions.map((item: string) => (
                                                    <MenuItem key={item} value={item}>{t(item)}</MenuItem>
                                                ))}
                                                <MenuItem value="other">{t("Other")}</MenuItem>
                                            </Select>
                                            {persona === 'other' && (
                                                <TextField
                                                    margin="dense"
                                                    id="persona"
                                                    label={(segment === 'person') ? t("Enter your profession") : t("Enter your company segment")}
                                                    type="text"
                                                    fullWidth
                                                    value={personaOther}
                                                    onChange={(e) => setPersonaOther(e.target.value)}
                                                />
                                            )}
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel>{t("What is your WhatsApp?")}</InputLabel>
                                        </FormControl>
                                        <FormControl fullWidth></FormControl>
                                        <FormControl fullWidth>
                                            <MuiPhoneNumber inputClass={classes.whatsapp} id="whatsapp" value={whatsapp} defaultCountry={'br'} onChange={(value: any) => setWhatsapp(value as string)}/>
                                        </FormControl>

                                        <FormControl fullWidth></FormControl>
                                        <Button fullWidth size="large" type="submit" variant="contained" color="primary"
                                                className={classNames(classes.submit)}>{t("Signup")}</Button>
                                    </div>
                                )}

                                <div className={classNames(classes.serviceLinks)}>
                                    <Link href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.live4.tv/termos-de-servico', "_blank", "noreferrer");
                                    }} color="textPrimary">
                                        {t('Terms of Service')}
                                    </Link>

                                    <Link href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.live4.tv/politica-de-privacidade', "_blank", "noreferrer");
                                    }} color="textPrimary">
                                        {t("Privacy Policy")}
                                    </Link>
                                </div>
                            </form>
                        )}

                    </Paper>
                </Grid>

                <Grid item>
                    <Link href="#login" color="textPrimary">
                        {t("Do you already have an account? Click here to Login")}
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        auth: state.auth,
        profile: state.user.profile
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setIsLoggedIn: (signedIn: boolean) => dispatch(setIsLoggedIn(signedIn)),
        setUserProfile: (profile: Profile) => dispatch(setUserProfile(profile)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignupPage));
