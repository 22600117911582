module.exports = {
    live4tv: {
        // api_url: 'http://dev.live4.tv',
        api_url: 'https://api.live4.tv',
        studio_url: 'https://studio.live4.tv',
    },
    languages: ['en', 'pt'],
    fallbackLng: 'en',
    namespace: 'translation',
    supportedStreamTargets: [
        'instagram',
        'facebook',
        'youtube',
        'rtmp',
        'tiktok',
    ],
    twitch: {
        client_id: 'hnxjzncxd6qpuk2rew5a05g7cly8a3',
    },
    sentryDsn: 'https://ab7adff6de074ead93bbb9d21db672cb@o810798.ingest.sentry.io/5805124',
    plan: {
        code: 'standard',
    },
    webrtc: {
        wssURL: 'wss://5fa0b37d2a2bd.streamlock.net/webrtc-session.json',
        applicationName: 'live'
    },
    restream: {
        host: 'rtmp://n-sp-1.live4.tv',
    },
    gtm: {
        id: 'GTM-58XMD8NV',
    },
    hotjar: {
        siteId: '2128173',
        version: '6',
    },
    stripe: {
        public_key: 'pk_live_51M0Is1J6RgBGfFphfXVkFCSAxAYAk3zceZzCFYxEgA2O8Pqb8rALhtkJVctwroL0w0BQKVphgIWDqukph4tRgw8R00RLfqCVxP',
        plan_table_id: 'prctbl_1NcPDMJ6RgBGfFphUUGmwHMd',
        dashboard_url: 'https://billing.stripe.com/p/login/3cs6pgaQOahwaeQeUU',
    }
}
