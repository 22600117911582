import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {persistStore, persistReducer, createMigrate} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all, fork } from 'redux-saga/effects';
import {user, auth} from "./features/User/userReducers";
import createSagaMiddleware from "redux-saga";
import streamTargetIntegrations from "./features/StreamTargetIntegration/streamTargetIntegrationReducer";
import thunk from "redux-thunk";
import streamTargetDialog from "./features/StreamTargetDialog/streamTargetDialogReducer";
import streamTargets from "./features/StreamTarget/streamTargetReducer";
import streamTargetSaga from "./features/StreamTarget/streamTargetSaga";
import events from "./features/Events/eventsReducer"
import eventsSaga from "./features/Events/eventsSaga";
import eventsUI from "./features/Events/eventsUIReducer";
import channels from "./features/Channel/channelReducer";
import channelSaga from "./features/Channel/channelSaga";
import comments from "./features/Comments/commentsReducer";
import live4tvApiSaga from "./features/StreamTargetLive4tvApi/live4tvApiSaga";
import {live4tvApiIntegration} from "./features/StreamTargetLive4tvApi/live4tvApiReducer";
import mainMenuUI from "./components/MainMenu/MainMenuUIReducer";
import liveStreamSaga from "./features/LiveStreamData/liveStreamSaga";
import liveStreams from "./features/LiveStreamData/liveStreams";

const migrations = {
}

/**
 * Big learning about reducers:
 *
 * 1) Reducer that fails to rehydrate and the state gets reset everytime the app is open.
 *  R: Check if the reducer switch-case has a default option which returns the state when no mapped action is passed.
 */
const persistConfig = {
    key: (process.env.NODE_ENV === 'production') ? "root" : "root-dev",
    storage,
    blacklist: [
        'eventsUI'
    ],
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
};

const Live4TVUser = combineReducers({
    user,
    auth,
    events,
    streamTargets,
    streamTargetDialog,
    streamTargetIntegrations,
    comments,
    live4tvApiIntegration,
    channels,
    eventsUI,
    mainMenuUI,
    liveStreams,
});


const persistedReducer = persistReducer(persistConfig, Live4TVUser);

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
    persistedReducer,
    storeEnhancers(applyMiddleware(thunk, initialiseSagaMiddleware))
);

function* rootSagas() {
    yield all([
        fork(liveStreamSaga),
        fork(streamTargetSaga),
        fork(live4tvApiSaga),
        fork(eventsSaga),
        fork(channelSaga),
    ]);
}
initialiseSagaMiddleware.run(rootSagas);

let persistor = persistStore(store);

export { store, persistor };

// Interesting blog post about Redux: https://www.valentinog.com/blog/redux/
