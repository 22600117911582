import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LiveEvent} from "../Events/EventsDTO";
import {connect} from "react-redux";
import {Channel, ChannelMap} from "./ChannelDTO";
import {useEffect, useState} from "react";
import styles from "./ChannelSettings-jss";
import classNames from "classnames";
import StreamTargetInstagramSettings from "../InstagramSettings/InstagramSettings";
import {withStyles} from "@mui/styles";

type Properties = {
    classes: any,
    liveEvent: LiveEvent,
    channels: ChannelMap,
}

function ChannelSettings(props: Properties) {
    const {
        classes,
        liveEvent,
        channels,
    } = props;

    const [channel, setChannel] = useState<Channel>();

    const {t} = useTranslation();

    useEffect(() => {
        console.debug('channels', channels);
        setChannel(channels[liveEvent.code]);
    }, [channels]);

    return (
        <Grid container direction="column" className={classNames(classes.root)} spacing={5}>
            <Grid item>
                <Typography gutterBottom variant="h5">
                    {t('Settings')}
                </Typography>
            </Grid>

            <Grid item>
                {channel ? <StreamTargetInstagramSettings channel={channel} liveEvent={liveEvent} /> : null }
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        channels: state.channels,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ChannelSettings));
