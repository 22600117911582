import React, {useState} from "react";
import {IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function PasswordField(props) {
    const data = {...props};
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    return (
        <OutlinedInput
            {...data}
            type={ showPassword ? 'text' : 'password' }
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}
