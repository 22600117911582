import {StreamTargetType} from "../../features/StreamTarget/streamTargetDTO";
import {Facebook, Instagram, LiveTv, SvgIconComponent, YouTube} from "@mui/icons-material";
import {SiTwitch, SiTiktok} from "react-icons/si";
import {IconType} from "react-icons/lib";

export const streamTargetService = {
    getIconForTarget(targetType: string): SvgIconComponent|IconType {
        switch (targetType) {
            case StreamTargetType.INSTAFEED:
            case StreamTargetType.INSTAGRAM:
                return Instagram;
            case StreamTargetType.FACEBOOK:
                return Facebook;
            case StreamTargetType.YOUTUBE:
                return YouTube;
            case StreamTargetType.TWITCH:
                return SiTwitch;
            case StreamTargetType.TIKTOK:
                return SiTiktok;
            default:
                return LiveTv;
        }
    },
}
