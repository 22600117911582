import {AxiosResponse} from "axios";
import {CreateLiveEventRequest, DeleteEventRequest, LiveEvent, UpdateLiveEventRequest} from "./EventsDTO";
import {httpClient} from "../../services/live4tv-client/http-client";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;

export const live4tvEventsService = {
    async getUserEvents(token: string): Promise<AxiosResponse<Array<LiveEvent>>> {
        const url = '/api/v1/events';

        return await httpClient.get<Array<LiveEvent>>(url, {
            baseURL: LIVE4_TV_BASE_URL,
            headers: { 'Authorization': 'Bearer ' + token }
        });
    },

    async createLiveEvent(createLiveEventRequest: CreateLiveEventRequest): Promise<LiveEvent> {
        const url = '/api/v1/events';

        const response = await httpClient.post<LiveEvent>(
            url,
            createLiveEventRequest.event,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: { 'Authorization': 'Bearer ' + createLiveEventRequest.token },
            }
        );

        return response.data;
    },

    async deleteLiveEvent(deleteEventRequest: DeleteEventRequest) {
        const url = `/api/v1/events/${deleteEventRequest.slug}`;

        const response = await httpClient.delete(
            url,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: { 'Authorization': 'Bearer ' + deleteEventRequest.token },
            }
        );

        return response.data;
    },

    async updateLiveEvent(updateLiveEventRequest: UpdateLiveEventRequest): Promise<LiveEvent> {
        const url = `/api/v1/events/${updateLiveEventRequest.event.slug}`;

        const response = await httpClient.put<LiveEvent>(
            url,
            updateLiveEventRequest.event,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: { 'Authorization': 'Bearer ' + updateLiveEventRequest.token },
            }
        );

        return response.data;
    },
};
