import {withStyles} from "@mui/styles";
import styles from "../Channel/ChannelSettings-jss";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import React from "react";
import {Box, Button, Card, CardContent, Divider, Grid, Typography} from "@mui/material";

type Properties = {
    classes: any,
}

function AffiliatePage(props: Properties) {
    const {
        classes,
    } = props;

    const {t} = useTranslation();

    return (
        <Page desc="" title={t('Refer & earn 10%')}>

            <Grid container alignItems="center" direction="row" spacing={10} xs={12}>
                <Grid item alignItems="center" direction="column" xs={12} container>
                    <img src="https://assets.live4.tv/Indique-and-Ganhe-10-percent-recorrente.png" alt="Affiliate" width="45%" />
                </Grid>

                <Grid item alignItems="center" direction="column" xs={12} container>
                    <Card sx={{ display: 'flex' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {t('Create your affiliate link, share it and earn 10% of the revenue generated by your referrals')}
                                </Typography>
                                <ol>
                                    <li>{t('Create your link')}</li>
                                    <li>{t('Share it')}</li>
                                    <li>{t('Earn 10% for every person that subscribe')}</li>
                                    <li>{t('Enjoy the monthly recurrent payment')}</li>
                                </ol>
                            </CardContent>
                            <Divider />
                            <Grid container item sx={{ p: 3 }} direction="row" xs={12}>
                                <Grid item>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <Button variant="contained" href="https://live4-tv.getrewardful.com/signup" target="_blank">{t('Create your affiliate link now')}</Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <Button variant="contained" color="secondary" href="https://www.live4.tv/blog/indique-o-live4-tv-e-ganhe-10-recorrente-por-cada-assinatura" target="_blank">{t('Learn more ...')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}

export default withStyles(styles)(AffiliatePage);
