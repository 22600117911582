import {Alert, Divider, Grid, IconButton, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {withStyles} from "@mui/styles";
import styles from "./ExternalStreamSource-jss";
import {useTranslation} from "react-i18next";
import {Check, FileCopyOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

function ExternalStreamSource(props) {
    const {
        classes,
        liveEvent
    } = props;

    const [copyIcon, setCopyIcon] = useState({
            url: <FileCopyOutlined />,
            key: <FileCopyOutlined />
        }
    );

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        if (liveEvent.event_transmission_accesses?.length === 0) {
            navigate('/events');
        }
    }, []);

    const copyToClipBoard = (text, kind) => {
        window.navigator.clipboard.writeText(text);
        setCopyIcon({...copyIcon, [kind]: <Check />});
        setTimeout(() => setCopyIcon({...copyIcon, [kind]: <FileCopyOutlined />}), 500);
    }

    const getIcon = (kind) => {
        return copyIcon[kind];
    }

    return (
        <Grid
            item
            container
            alignItems="center"
        >
            <Grid item xs={12}>
                <Typography gutterBottom variant="h5">
                    {t('Stream Data')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider variant="middle" />
            </Grid>
            { liveEvent !== undefined && liveEvent.event_transmission_accesses?.length > 0 ? (
                <Grid item xs={12}>
                    <TextField
                        label={t("Server")}
                        fullWidth
                        variant="filled"
                        helperText={t("Use this URL to live stream")}
                        value={liveEvent.event_transmission_accesses[0].transmission_url}
                        onFocus={(event) => event.target.select()}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" color="default" onClick={ () => copyToClipBoard(liveEvent.event_transmission_accesses[0].transmission_url, 'url') }>
                                    {getIcon('url')}
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
            ) : null }

            { liveEvent !== undefined && liveEvent.event_transmission_accesses?.length > 0 ? (
                <Grid item xs={12}>
                    <TextField
                        label={t("Key")}
                        fullWidth
                        variant="filled"
                        helperText={t("Use this Key to live stream")}
                        value={liveEvent.event_transmission_accesses[0].reference}
                        onFocus={(event) => event.target.select()}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" color="default" onClick = { () => copyToClipBoard(liveEvent.event_transmission_accesses[0].reference, 'key')}>
                                    {getIcon('key')}
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
            ) : null }

            <Grid item xs={12}>
                <Alert severity="info" className={classNames(classes.alert)}>
                    {t("TIP: To live stream, use an app that supports RTMP (as")} <a onClick={(event) => { event.preventDefault(); window.open('https://obsproject.com/', "_blank", 'noreferrer') }} href="#">OBS Studio</a> {t("or")} <a onClick={(event) => { event.preventDefault(); window.open('https://zoom.us/', "_blank", 'noreferrer') }} href="#">Zoom)</a>. {t("And configure the above Server and Key")}.
                </Alert>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(ExternalStreamSource)
