import React, {useEffect} from 'react';
import classNames from 'classnames';
import {withStyles} from "@mui/styles";
import {Box, Typography} from "@mui/material";
import styles from "./pageStyle-jss";
import {Dispatch} from "redux";
import {setMainMenuTitle} from "../MainMenu/MainMenuUIActions";
import {connect} from "react-redux";

type Properties = {
    classes: any,
    title: string,
    children: any,
    setMainMenuTitle: Function,
    desc?: string,
    whiteBg?: boolean,
    overflowX?: boolean,
}

function Page(props: Properties) {
    const {
        classes,
        title,
        children,
        setMainMenuTitle,
        desc = '',
        whiteBg = false,
        overflowX = false,
    } = props;

    useEffect(() => {
        setMainMenuTitle(title);
        document.title = `${title} | Live4.tv Studio`;
    }, []);

    return (
        <Box>
            <Typography component="p" className={classes.description}>{desc}</Typography>
            <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
                {children}
            </section>
        </Box>
    );
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        setMainMenuTitle: (title:string) => dispatch(setMainMenuTitle(title)),
    };
}


export default  withStyles(styles) (connect(null, mapDispatchToProps)(Page));
