import {Dispatch} from "redux";
import {Live4tvApiStreamTarget, Live4tvLiveStreamRequest} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {
    subscribeToComments,
} from "../StreamTargetLive4tvApi/live4tvApiActions";
import {useEffect, useState} from "react";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {Profile} from "../User/userDTO";
import {useNavigate, useParams} from "react-router-dom";
import {LiveEventsMap} from "../Events/EventsDTO";
import {useTranslation} from "react-i18next";
import {ChannelMap} from "../Channel/ChannelDTO";
import Page from "../../components/Page/Page";
import styles from "./CommentsPage-jss";
import {Grid, IconButton} from "@mui/material";
import {Facebook, FullscreenExit, FullscreenSharp, Instagram, YouTube, ZoomIn, ZoomOut} from "@mui/icons-material";
import classNames from "classnames";
import {Comment, LiveEventCommentsMap} from "./CommentsDTO";
import {StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {SiTiktok, SiTwitch} from "react-icons/si";

type Properties = {
    classes: any,
    profile: Profile,
    channels: ChannelMap,
    comments: LiveEventCommentsMap,
    events: LiveEventsMap,
    streamTargets: Array<Live4tvApiStreamTarget>,
    subscribeToComments: (subscribeToCommentsRequest: Live4tvLiveStreamRequest) => void,
}

function CommentsPage(props: Properties) {
    const {
        classes,
        profile,
        channels,
        comments,
        events,
        streamTargets,
        subscribeToComments,
    } = props;

    const {eventCode} = useParams<{eventCode: string}>();

    const [fontSize, setFontSize] = useState(16);
    const [isFullScreen, setFullScreen] = useState(false);
    const [liveEvent, setLiveEvent] = useState<any>();

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        if (eventCode === undefined) navigate('/events');

        if (eventCode !== undefined && events[eventCode] !== undefined) {
            const channel = channels[eventCode];
            setLiveEvent(events[eventCode]);

            subscribeToComments({
                profile,
                liveStreamUuid: channels[eventCode].liveStreamUuid,
                liveEvent: events[eventCode],
                streamTargets,
                settings: channel.settings,
            })

        }
    }, []);

    const increaseFontSize = () => {
        setFontSize(fontSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize(fontSize - 2);
    };

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setFullScreen(false);
        } else {
            document.documentElement.requestFullscreen();
            setFullScreen(true);
        }
    };

    const getIcon = (type: StreamTargetType) => {
        switch (type) {
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.INSTAGRAM:
                return  <Instagram />
            case StreamTargetType.YOUTUBE:
                return  <YouTube />
            case StreamTargetType.TIKTOK:
                return  <SiTiktok />
            case StreamTargetType.TWITCH:
                return  <SiTwitch />
            default:
                return <></>
        }
    }

    const getComments = () => {
        let htmlComments: Array<any> = [];

        if (liveEvent && comments[liveEvent.code]) {
            comments[liveEvent.code].forEach((comment: Comment) => {
                htmlComments.push(<Grid item direction="column" className={classNames(classes.message)}>
                    <img className={classNames(classes.profilePic)} src={comment.photo} alt={comment.username} />
                    <span className={classNames(classes.socialIcon)}>{getIcon(comment.streamTargetType)}</span>
                    {/*<i className={classNames(classes.socialIcon)}>Facebook</i>*/}
                    <strong>{comment.username}:</strong> {comment.message}
                </Grid>)
            })
        }

        return htmlComments;
    }

    return (
        <Page title={t('Comments')}>
            <Grid
                container
                direction="row"
                alignItems="center"
            >
                <Grid item direction="column">
                    <div className={classNames(classes.iconButtons)}>
                        <IconButton color="primary" onClick={increaseFontSize}>
                            <ZoomIn />
                        </IconButton>
                        <IconButton color="primary" onClick={decreaseFontSize}>
                            <ZoomOut />
                        </IconButton>
                        <IconButton color="primary" onClick={toggleFullScreen}>
                            {isFullScreen ? <FullscreenExit /> : <FullscreenSharp />}
                        </IconButton>
                    </div>
                </Grid>

                <Grid item direction="row" className={classNames(classes.chatContainer)}>
                    { getComments() }
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        events: state.events,
        comments: state.comments,
        channels: state.channels,
        streamTargets: state.streamTargets,
        profile: state.user.profile,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        subscribeToComments: (subscribeToCommentsRequest: Live4tvLiveStreamRequest) => dispatch(subscribeToComments(subscribeToCommentsRequest)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CommentsPage))
