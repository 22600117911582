import {Dispatch} from "redux";
import {streamTargetIntegrationAPI} from "./streamTargetIntegrationAPI";
import {StreamTargetIntegration} from "./streamTargetIntegrationDTO";
import useFeatureFlags from "../FeatureFlag/useFeatureFlags";

const supportedStreamIntegrations = require('config/app.config').supportedStreamTargets;

export const types = {
    FETCH_STREAM_TARGET_INTEGRATIONS: 'FETCH_STREAM_TARGET_INTEGRATIONS',
    FETCH_STREAM_TARGET_INTEGRATIONS_SUCCESS: 'FETCH_STREAM_TARGET_INTEGRATIONS_SUCCESS',
    FETCH_STREAM_TARGET_INTEGRATIONS_FAILED: 'FETCH_STREAM_TARGET_INTEGRATIONS_FAILED',
};

export const fetchStreamTargetIntegrations = (apiToken: string) => async (dispatch: Dispatch) => {
    let streamTargetIntegrations: Array<StreamTargetIntegration> = [];

    // @todo remove this block when all the social networks are moved to the new API
    try {
        streamTargetIntegrations = await streamTargetIntegrationAPI.getStreamNetworks(apiToken);
        streamTargetIntegrations = streamTargetIntegrations.filter(item => {
            return supportedStreamIntegrations.find((target: string) => target === item.code ) !== undefined;
        })
        streamTargetIntegrations.forEach((item: StreamTargetIntegration) => {
            item.is_live4tv_api_integration = false;
        });
    } catch (e) {
        dispatch(fetchStreamTargetIntegrationsFailed());
    }
    // <# end remove this part when all the social networks are moved to the new API

    try {
        const [live4tvApi] = useFeatureFlags(apiToken, 'live4tv_api');
        if (await live4tvApi.isActive()) {
            const live4tvSocialNetworks = await streamTargetIntegrationAPI.getLive4tvSocialNetworks(apiToken);

            // @todo remove this part when all the social networks are moved to the new API
            live4tvSocialNetworks.forEach((item: StreamTargetIntegration) => {
                item.is_live4tv_api_integration = true;
            });
            streamTargetIntegrations = streamTargetIntegrations.filter(item => {
                return !live4tvSocialNetworks.some((target: StreamTargetIntegration) => target.code === item.code);
            })
            // <# end remove this part when all the social networks are moved to the new API

            streamTargetIntegrations = streamTargetIntegrations.concat(live4tvSocialNetworks);
        }
    } catch (e) {
        dispatch(fetchStreamTargetIntegrationsFailed());
    }

    console.debug('Stream Network Integrations', streamTargetIntegrations);
    dispatch(fetchStreamTargetIntegrationsSuccess(streamTargetIntegrations));
}

export function fetchStreamTargetIntegrationsSuccess(streamTargetIntegrations: Array<StreamTargetIntegration>) {
    return { type: types.FETCH_STREAM_TARGET_INTEGRATIONS_SUCCESS, streamTargetIntegrations }
}

export function fetchStreamTargetIntegrationsFailed() {
    return { type: types.FETCH_STREAM_TARGET_INTEGRATIONS_FAILED }
}
