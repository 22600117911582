import {StreamTarget, StreamTargetType} from "./streamTargetDTO";
import {Button, TableCell, TableRow} from "@mui/material";
import {Delete, Facebook, Instagram, LiveTv, YouTube} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {removeStreamTarget} from "./streamTargetActions";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import classNames from "classnames";
import {withStyles} from "@mui/styles";
import styles from "./StreamTargetAccount-jss";
import {SiTwitch, SiTiktok} from "react-icons/si";

type Properties = {
    classes: any,
    streamTarget: StreamTarget,
    removeStreamTarget: Function
};

function StreamTargetAccount(props: Properties) {
    const {
        classes,
        streamTarget,
        removeStreamTarget,
    } = props;

    const {t} = useTranslation();

    function handleDelete() {
        removeStreamTarget(streamTarget);
    }

    function getIconForTarget(targetName: string) {
        switch (targetName) {
            case StreamTargetType.INSTAFEED:
            case StreamTargetType.INSTAGRAM:
                return <Instagram />
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.YOUTUBE:
                return <YouTube />
            case StreamTargetType.TWITCH:
                return <SiTwitch />
            case StreamTargetType.TIKTOK:
                return <SiTiktok />
            default:
                return <LiveTv />
        }
    }

    return (
        <TableRow key={ streamTarget.key }>
            <TableCell className={classNames(classes.tableCellIcon)}>{getIconForTarget(streamTarget.type)}</TableCell>
            <TableCell className={classNames(classes.tableCellKey)}>{streamTarget.title}</TableCell>
            <TableCell className={classNames(classes.tableCellDelete)}>
                <Button color="inherit" aria-label={t('Delete')} onClick={ handleDelete } startIcon={<Delete />}>
                    {t('Delete')}
                </Button>
            </TableCell>
        </TableRow>
    );
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        removeStreamTarget: (streamTarget: StreamTarget) => dispatch(removeStreamTarget(streamTarget)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(StreamTargetAccount));
