import {LiveEvent} from "../Events/EventsDTO";
import {ChannelSettingsMap} from "../Channel/ChannelDTO";
import {CommentStatus} from "../Comments/CommentsDTO";

export enum StreamTargetType {
    INSTAGRAM = "instagram",
    FACEBOOK = "facebook",
    YOUTUBE = "youtube",
    TIKTOK = "tiktok",
    RTMP = "rtmp",
    TWITCH = "twitch",
    INSTAFEED = "instafeed",
    LIVE4TVAPI = "live4tvapi",
}

export enum StreamTargetStatus {
    IDLE = "IDLE",
    STARTING = "STARTING",
    START_FAILED = "START_FAILED",
    LIVE = "LIVE",
    STOPPING = "STOPPING",
    STOP_FAILED = "STOP_FAILED",
}

export enum ScreenFormat {
    VERTICAL_90_CLOCKWISE = "VERTICAL_90_CLOCKWISE",
    DEFAULT = "DEFAULT",
    SCALE_TOP = "SCALE_TOP",
    SCALE_CENTER = "SCALE_CENTER",
}

export type StreamTargetList = {
    [key: string]: StreamTarget
}

export interface StreamTargetTransmission {
    url: string,
    key: string,
    id?: string,
    username?: string,
    password?: string,
}

export interface StreamTarget {
    key: string,
    title: string,
    type: StreamTargetType,
    status: StreamTargetStatus,
    commentStatus: CommentStatus,
    isLive4tvApi: boolean,
    transmission?: StreamTargetTransmission,
    backup_transmission?: StreamTargetTransmission,
    latestError?: Error
}

export interface Live4tvStreamTargetConfig {
    host: string,
    key: string,
    name: string,
    username?: string,
    password?: string,
}

export interface Live4tvStreamTarget {
    type: StreamTargetType,
    access_reference: string,
    configs?: Live4tvStreamTargetConfig,
}

export interface LiveStreamRequest {
    token: string,
    liveStreamUuid: string,
    liveEvent: LiveEvent,
    streamTarget: StreamTarget,
    settings: ChannelSettingsMap,
}
