import {
    ScreenFormat,
} from "../StreamTarget/streamTargetDTO";
import {ChannelSettings, ChannelSettingsType} from "../Channel/ChannelDTO";

export interface InstagramChannelSettings extends ChannelSettings {
    type: ChannelSettingsType.INSTAGRAM,
    saveToIGTV: boolean,
    background: string|undefined,
    screenFormat: ScreenFormat,
    allowComments: boolean,
}

export function isInstanceOfInstagramChannelSettings(object: any): object is InstagramChannelSettings {
    return object && object.type === ChannelSettingsType.INSTAGRAM
}
