import {
    LiveStreamRequest,
    StreamTarget,
    StreamTargetList,
    StreamTargetStatus,
} from "./streamTargetDTO";
import {types} from "./streamTargetActions";
import {CommentStatus} from "../Comments/CommentsDTO";

export const streamTargetsInitialState:StreamTargetList = {};

function streamTargets(state: any = streamTargetsInitialState, action: {
    type: string,
    streamTarget: StreamTarget,
    liveStreamRequest?: LiveStreamRequest,
}) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case types.ADD_STREAM_TARGET:
        case types.ADD_OR_UPDATE_STREAM_TARGET:
            newState[action.streamTarget.key] = action.streamTarget;
            break;
        case types.RESET_STREAM_TARGET_STATUS:
            newState[action.streamTarget.key].status = StreamTargetStatus.IDLE;
            newState[action.streamTarget.key].commentStatus = CommentStatus.NOT_STARTED;
            break;
        case types.SET_STREAM_TARGET_STATUS:
            newState[action.streamTarget.key].status = action.streamTarget.status;
            break;
        case types.CREATE_AND_START_LIVE_STREAM_REQUEST:
            if (!action.liveStreamRequest) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.STARTING;
            break;
        case types.CREATE_AND_START_LIVE_STREAM_SUCCESS:
            if (!action.liveStreamRequest) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.LIVE;
            break;
        case types.CREATE_AND_START_LIVE_STREAM_FAIL:
            if (!action.liveStreamRequest) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.START_FAILED;
            break;
        case types.STOP_LIVE_STREAM_REQUEST:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.STOPPING;
            break;
        case types.STOP_LIVE_STREAM_SUCCESS:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.IDLE;
            break;
        case types.STOP_LIVE_STREAM_FAIL:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].status = StreamTargetStatus.STOP_FAILED;
            break;
        case types.START_LISTENING_TO_COMMENTS_REQUEST:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.STARTING;
            break;
        case types.START_LISTENING_TO_COMMENTS_FAIL:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.START_FAILED;
            break;
        case types.START_LISTENING_TO_COMMENTS_SUCCESS:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.LISTENING;
            break;
        case types.STOP_LISTENING_TO_COMMENTS_REQUEST:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.STOPPING;
            break;
        case types.STOP_LISTENING_TO_COMMENTS_SUCCESS:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.NOT_STARTED;
            break;
        case types.STOP_LISTENING_TO_COMMENTS_FAIL:
            if (action.liveStreamRequest === undefined) {
                return newState;
            }
            if (newState[action.liveStreamRequest.streamTarget.key] === undefined) {
                return newState;
            }
            newState[action.liveStreamRequest.streamTarget.key].commentStatus = CommentStatus.LISTENING;
            break;
        case types.REMOVE_STREAM_TARGET:
            delete newState[action.streamTarget.key];
            break;
        case types.CLEAR_STREAM_TARGETS:
            return streamTargetsInitialState;
        default:
            return state;
    }

    return newState;
}

export default streamTargets;
