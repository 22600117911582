import {Channel, ChannelMap, ChannelSettings, ChannelStatus} from "./ChannelDTO";
import {types} from "./channelActions";
import {StreamTarget} from "../StreamTarget/streamTargetDTO";

export const channelInitialState:ChannelMap = {};

function channels(
    state: ChannelMap = channelInitialState,
    action: {
        type: string,
        channel: Channel,
        streamTarget?: StreamTarget,
        status?: ChannelStatus,
        settings?: ChannelSettings,
        channels?: ChannelMap,
        uuid?: string,
    }
) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case types.CREATE_CHANNEL_REQUEST:
            if (state[action.channel.key] !== undefined) {
                return newState;
            }

            if (state[action.channel.key] !== undefined) {
                return newState;
            }

            newState[action.channel.key] = action.channel;
            break;
        case types.ADD_STREAM_TARGET_TO_CHANNEL_REQUEST:
            if (action.streamTarget === undefined) {
                return newState;
            }

            newState[action.channel.key].streamTargets[action.streamTarget.key] = action.streamTarget;

            break;
        case types.REMOVE_STREAM_TARGET_FROM_CHANNEL_REQUEST:
            if (action.streamTarget === undefined) {
                return newState;
            }

            delete newState[action.channel.key].streamTargets[action.streamTarget.key];

            break;
        case types.UPDATE_CHANNEL_STATUS_REQUEST:
            if (action.status === undefined) {
                return newState;
            }

            newState[action.channel.key].status = action.status;

            break;
        case types.UPDATE_CHANNEL_SETTINGS_REQUEST:
            if (action.settings === undefined) {
                return newState;
            }

            if (newState[action.channel.key].settings === undefined) { // this block is here for backwards compatibility with v1.*
                newState[action.channel.key].settings = {};
            }

            newState[action.channel.key].settings[action.settings.type] = action.settings;

            break;
        case types.UPDATE_CHANNEL_LIVE_STREAM_UUID_REQUEST:
            if (action.uuid === undefined) {
                return newState;
            }
            if (action.channel === undefined) {
                return newState;
            }
            newState[action.channel.key].liveStreamUuid = action.uuid;

            break;
        default:
            return state;
    }

    return newState;
}

export default channels;
