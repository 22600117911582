import {StreamTargetType} from "../StreamTarget/streamTargetDTO";

export interface ChannelStreamTarget {
    key: string,
    type: StreamTargetType,
}

export interface ChannelStreamTargets {
    [key: string]: ChannelStreamTarget,
}

export enum ChannelStatus {
    IDLE = "IDLE",
    STARTING = "STARTING",
    LIVE = "LIVE",
    STOPPING = "STOPPING",
}

export enum ChannelSettingsType {
    YOUTUBE = "YOUTUBE",
    INSTAGRAM = "INSTAGRAM",
    COMMENTS = "COMMENTS",
}

export interface ChannelSettings {
    type: ChannelSettingsType,
}

export interface ChannelSettingsMap {
    [key: string]: ChannelSettings,
}

export interface Channel {
    key: string,
    name: string,
    liveStreamUuid: string,
    status: ChannelStatus,
    streamTargets: ChannelStreamTargets,
    settings: ChannelSettingsMap,
}

export interface ChannelMap {
    [key: string]: Channel,
}
