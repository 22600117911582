import {connect} from "react-redux";
import styles from "./EventPage-jss";
import {FormEvent, useEffect, useState} from "react";
import {Alert, Button, DialogContent, FormControl, LinearProgress, TextField} from "@mui/material";
import classNames from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createEventRequest, updateEventRequest} from "./eventsAtcions";
import {Dispatch} from "redux";
import {Profile} from "../User/userDTO";
import {CreateLiveEventRequest, EventsUIState, LiveEvent, LiveEventsMap, UpdateLiveEventRequest} from "./EventsDTO";
import {resetUI, setLiveEvent} from "./eventsUIActions";
import {withStyles} from "@mui/styles";
import Page from "../../components/Page/Page";

type Properties = {
    classes: any,
    profile: Profile,
    events: LiveEventsMap,
    eventsUI: EventsUIState,
    eventCode: string,
    resetUI: Function,
    updateLiveEvent: Function,
    createEventRequest: Function,
    updateEventRequest: Function,
}

function EventPage(props: Properties) {
    type operation = 'create' | 'update';

    const {
        classes,
        profile,
        events,
        eventsUI,
        resetUI,
        updateLiveEvent,
        createEventRequest,
        updateEventRequest,
    } = props;

    const {eventCode} = useParams<{eventCode: string}>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [currentOperation, setCurrentOperation] = useState<operation>('create');
    const [success, setSuccess] = useState<string>('');
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        console.debug('eventCode', eventCode);

        if (eventCode !== undefined && events[eventCode] !== undefined) {
            const liveEvent: LiveEvent = events[eventCode];
            updateLiveEvent(liveEvent);
        }
    }, []);

    useEffect(() => {
        if (eventsUI.liveEvent !== undefined) {
            setCurrentOperation('update');
            setTitle(eventsUI.liveEvent.title);
            setDescription(eventsUI.liveEvent.details);
        } else {
            cleanEvent();
        }
    }, [eventsUI.liveEvent]);

    useEffect(() => {
        setIsLoading(eventsUI.isLoading);
        setError(t(eventsUI.error));
    }, [eventsUI.isLoading, eventsUI.error]);


    useEffect(() => {
        if (eventsUI.isCreateSuccess && eventsUI.liveEvent !== undefined && events[eventsUI.liveEvent.code] !== undefined) {
            setSuccess(t('Event created successfully!'));
            cleanEvent();
            resetUI();
            navigate('/studio/' + eventsUI.liveEvent.code);
        }
    }, [eventsUI.liveEvent, eventsUI.isCreateSuccess, events])

    useEffect(() => {
        if (eventsUI.isUpdateSuccess) {
            setSuccess(t('Event updated successfully!') || '');
        }
    }, [eventsUI.isUpdateSuccess])

    const cleanEvent = () => {
        setCurrentOperation('create');
        setTitle('');
        setDescription('');
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        switch (currentOperation) {
            case "create":
                await createEvent();
                break;
            case "update":
                await updateEvent();
                break;
        }
    }

    const updateEvent = async () => {
        console.log('updateEvent()');
        if (eventCode !== undefined && events[eventCode] !== undefined) {
            updateEventRequest({
                token: profile.token,
                event: {
                    slug: events[eventCode].slug,
                    title: title,
                    details: description,
                }
            });
        }
    }

    const createEvent = async () => {
        console.log('createEvent()');
        createEventRequest({
            token: profile.token,
            event: {
                title: title,
                details: description,
            }
        });
    }

    return (
        <Page desc="" title={t('Create an Event')}>
            <Alert severity="info" className={classNames(classes.alert)}>
                {t('Set the title and description that you want to be posted to your social networks when you start streaming')}.
            </Alert>

            <br />
            { error ? <Alert severity="error" className={classNames(classes.alert)}>{ error }</Alert> : '' }
            { success ? <Alert severity="success" className={classNames(classes.alert)}>{ success }</Alert> : '' }

            <form onSubmit={handleSubmit}>
                { isLoading ? <DialogContent><LinearProgress className={classNames(classes.progressBar)} /></DialogContent> : (
                    <>
                        <FormControl fullWidth className={classNames(classes.textField)}>
                        </FormControl>

                        <FormControl fullWidth className={classNames(classes.textField)}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="title"
                                label={t('Event title')}
                                required={true}
                                type="text"
                                fullWidth
                                value={title}
                                inputProps={{maxLength: 100}}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classNames(classes.textField)}>
                            <TextField
                                margin="dense"
                                id="description"
                                label={t('Event description')}
                                required={true}
                                fullWidth
                                multiline
                                rows={15}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classNames(classes.textField)}>
                        </FormControl>

                        <Button
                            fullWidth
                            className={classNames(classes.submitButton)}
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                        >
                            { currentOperation === 'create' ? t('Create Event') : t('Update Event')}
                        </Button>
                    </>
                )}
            </form>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        events: state.events,
        eventsUI: state.eventsUI,
        profile: state.user.profile,
    };
};

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        createEventRequest: (createLiveEventRequest: CreateLiveEventRequest) => dispatch(createEventRequest(createLiveEventRequest)),
        updateEventRequest: (updateLiveEventRequest: UpdateLiveEventRequest) => dispatch(updateEventRequest(updateLiveEventRequest)),
        resetUI: () => dispatch(resetUI()),
        updateLiveEvent: (liveEvent: LiveEvent) => dispatch(setLiveEvent(liveEvent)),
    };
}

export default withStyles(styles) (connect(mapStateToProps, mapDispatchToProps)(EventPage));
