import {types} from "./commentsActions";
import {Comment, LiveEventCommentsMap} from "./CommentsDTO";
import {LiveEvent} from "../Events/EventsDTO";

export function comments(state: LiveEventCommentsMap = {}, action: {type: string, comment: Comment, liveEvent?: LiveEvent}) {
    let newState: LiveEventCommentsMap = Object.assign({}, state);

    switch (action.type) {
        case types.ADD_COMMENT:
            if (!action.liveEvent) {
                return newState;
            }

            if (!newState[action.liveEvent.code]) {
                newState[action.liveEvent.code] = [];
            }

            // avoid duplicates
            if (newState[action.liveEvent.code].find((comment: Comment) => comment.key === action.comment.key)) {
                return newState;
            }

            newState[action.liveEvent.code].push(action.comment);
            break;
        case types.CLEAR_COMMENTS:
            newState = {};
            break;
    }

    return newState;
}

export default comments;
