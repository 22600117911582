import {
    LiveStreamRequest,
    StreamTarget,
} from "./streamTargetDTO";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";

export const types = {
    SET_STREAM_TARGET_STATUS: 'SET_STREAM_TARGET_STATUS',
    RESET_STREAM_TARGET_STATUS: 'RESET_STREAM_TARGET_STATUS',

    ADD_OR_UPDATE_STREAM_TARGET: 'ADD_OR_UPDATE_STREAM_TARGET',
    REMOVE_STREAM_TARGET: 'REMOVE_STREAM_TARGET',
    CLEAR_STREAM_TARGETS: 'CLEAR_STREAM_TARGETS',

    ADD_STREAM_TARGET_REQUEST: 'ADD_STREAM_TARGET_REQUEST',
    ADD_STREAM_TARGET: 'ADD_STREAM_TARGET',

    CREATE_AND_START_LIVE_STREAM_REQUEST: 'CREATE_AND_START_LIVE_STREAM_REQUEST',
    CREATE_AND_START_LIVE_STREAM_SUCCESS: 'CREATE_AND_START_LIVE_STREAM_SUCCESS',
    CREATE_AND_START_LIVE_STREAM_FAIL: 'CREATE_AND_START_LIVE_STREAM_FAIL',

    STOP_LIVE_STREAM_REQUEST: 'STOP_LIVE_STREAM_REQUEST',
    STOP_LIVE_STREAM_SUCCESS: 'STOP_LIVE_STREAM_SUCCESS',
    STOP_LIVE_STREAM_FAIL: 'STOP_LIVE_STREAM_FAIL',

    START_LISTENING_TO_COMMENTS_REQUEST: "START_LISTENING_TO_COMMENTS_REQUEST",
    START_LISTENING_TO_COMMENTS_SUCCESS: "START_LISTENING_TO_COMMENTS_SUCCESS",
    START_LISTENING_TO_COMMENTS_FAIL: "START_LISTENING_TO_COMMENTS_FAIL",

    STOP_LISTENING_TO_COMMENTS_REQUEST: "STOP_LISTENING_TO_COMMENTS_REQUEST",
    STOP_LISTENING_TO_COMMENTS_SUCCESS: "STOP_LISTENING_TO_COMMENTS_SUCCESS",
    STOP_LISTENING_TO_COMMENTS_FAIL: "STOP_LISTENING_TO_COMMENTS_FAIL",

    SEND_A_COMMENT_REQUEST: 'SEND_A_COMMENT_REQUEST',
    SEND_A_COMMENT_SUCCESS: 'SEND_A_COMMENT_SUCCESS',
    SEND_A_COMMENT_FAIL: 'SEND_A_COMMENT_FAIL',
};

export function setStreamTargetStatus(streamTarget: StreamTarget) {
    return { type: types.SET_STREAM_TARGET_STATUS, streamTarget }
}

export function resetStreamTargetStatus(streamTarget: StreamTarget) {
    return { type: types.RESET_STREAM_TARGET_STATUS, streamTarget }
}

export function addOrUpdateStreamTarget(streamTarget: StreamTarget) {
    return { type: types.ADD_OR_UPDATE_STREAM_TARGET, streamTarget }
}

export function removeStreamTarget(streamTarget: StreamTarget) {
    return { type: types.REMOVE_STREAM_TARGET, streamTarget }
}

export function clearStreamTargets() {
    return { type: types.CLEAR_STREAM_TARGETS }
}

export function addStreamTarget(streamTarget: StreamTarget) {
    return { type: types.ADD_STREAM_TARGET, streamTarget }
}

export function addNewStreamTarget(streamTargetIntegration: StreamTargetIntegration) {
    return { type: types.ADD_STREAM_TARGET_REQUEST, streamTargetIntegration }
}

export function createAndStartLiveStreamRequest(liveStreamRequest: LiveStreamRequest) {
    return { type: types.CREATE_AND_START_LIVE_STREAM_REQUEST, liveStreamRequest }
}

export function createAndStartLiveStreamSuccess(liveStreamRequest: LiveStreamRequest) {
    return { type: types.CREATE_AND_START_LIVE_STREAM_SUCCESS, liveStreamRequest }
}

export function createAndStartLiveStreamFail(liveStreamRequest: LiveStreamRequest) {
    return { type: types.CREATE_AND_START_LIVE_STREAM_FAIL, liveStreamRequest }
}

export function stopLiveStreamRequest(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LIVE_STREAM_REQUEST, liveStreamRequest }
}

export function stopLiveStreamSuccess(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LIVE_STREAM_SUCCESS, liveStreamRequest }
}

export function stopLiveStreamFail(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LIVE_STREAM_FAIL, liveStreamRequest }
}

export function startListeningToCommentsRequest(liveStreamRequest: LiveStreamRequest) {
    return { type: types.START_LISTENING_TO_COMMENTS_REQUEST, liveStreamRequest }
}

export function startListeningToCommentsFail(liveStreamRequest: LiveStreamRequest) {
    return { type: types.START_LISTENING_TO_COMMENTS_FAIL, liveStreamRequest }
}

export function startListeningToCommentsSuccess(liveStreamRequest: LiveStreamRequest) {
    return { type: types.START_LISTENING_TO_COMMENTS_SUCCESS, liveStreamRequest }
}

export function stopListeningToCommentsRequest(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LISTENING_TO_COMMENTS_REQUEST, liveStreamRequest }
}

export function stopListeningToCommentsFail(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LISTENING_TO_COMMENTS_FAIL, liveStreamRequest }
}

export function stopListeningToCommentsSuccess(liveStreamRequest: LiveStreamRequest) {
    return { type: types.STOP_LISTENING_TO_COMMENTS_SUCCESS, liveStreamRequest }
}

export function sendACommentRequest(liveStreamRequest: LiveStreamRequest, message: string) {
    return { type: types.SEND_A_COMMENT_REQUEST, liveStreamRequest, message }
}

export function sendACommentSuccess(liveStreamRequest: LiveStreamRequest) {
    return { type: types.SEND_A_COMMENT_SUCCESS, liveStreamRequest }
}

export function sendACommentFail(liveStreamRequest: LiveStreamRequest) {
    return { type: types.SEND_A_COMMENT_FAIL, liveStreamRequest }
}
