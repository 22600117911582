import {Channel, ChannelSettings, ChannelStatus, ChannelStreamTarget} from "./ChannelDTO";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";

export const types = {
    CREATE_CHANNEL_REQUEST: "CREATE_CHANNEL_REQUEST",

    ADD_STREAM_TARGET_TO_CHANNEL_REQUEST: "ADD_STREAM_TARGET_TO_CHANNEL_REQUEST",
    REMOVE_STREAM_TARGET_FROM_CHANNEL_REQUEST: "REMOVE_STREAM_TARGET_FROM_CHANNEL_REQUEST",

    ADD_NEW_STREAM_TARGET_TO_CHANNEL_REQUEST: "ADD_NEW_STREAM_TARGET_TO_CHANNEL_REQUEST",

    UPDATE_CHANNEL_STATUS_REQUEST: "UPDATE_CHANNEL_STATUS_REQUEST",

    UPDATE_CHANNEL_SETTINGS_REQUEST: "UPDATE_CHANNEL_SETTINGS",

    UPDATE_CHANNEL_LIVE_STREAM_UUID_REQUEST: "UPDATE_CHANNEL_LIVE_STREAM_UUID_REQUEST",
}

export function createChannelRequest(channel: Channel) {
    return { type: types.CREATE_CHANNEL_REQUEST, channel };
}

export function addStreamTargetToChannelRequest(channel: Channel, streamTarget: ChannelStreamTarget) {
    return {type: types.ADD_STREAM_TARGET_TO_CHANNEL_REQUEST, channel, streamTarget};
}

export function removeStreamTargetFromChannelRequest(channel: Channel, streamTarget: ChannelStreamTarget) {
    return {type: types.REMOVE_STREAM_TARGET_FROM_CHANNEL_REQUEST, channel, streamTarget};
}

export function addNewStreamTargetToChannelRequest(channel: Channel, streamTargetIntegration: StreamTargetIntegration) {
    return {type: types.ADD_NEW_STREAM_TARGET_TO_CHANNEL_REQUEST, channel, streamTargetIntegration};
}

export function updateChannelStatusRequest(channel: Channel, status: ChannelStatus) {
    return {type: types.UPDATE_CHANNEL_STATUS_REQUEST, channel, status};
}

export function updateChannelSettingsRequest(channel: Channel, settings: ChannelSettings) {
    return {type: types.UPDATE_CHANNEL_SETTINGS_REQUEST, channel, settings};
}

export function updateChannelStreamTargetUuidRequest(channel: Channel, uuid: string) {
    return {type: types.UPDATE_CHANNEL_LIVE_STREAM_UUID_REQUEST, channel, uuid};
}
