import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import styles from './StudioPage-jss';
import {withStyles} from "@mui/styles";
import ExternalStreamSource from "./components/StreamSource/ExternalStreamSource";
import LiveEventStreamTarget from "./LiveEventStreamTarget/LiveEventStreamTarget";
import {ChatBubble, Palette, Settings, OndemandVideo} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import * as React from "react";
import {useEffect, useState} from "react";
import {StudioRightMenuItem, StudioRightSection} from "./StudioDTO";
import Comments from "../Comments/Comments";
import ChannelSettings from "../Channel/ChannelSettings";
import {Dispatch} from "redux";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import {styled} from "@mui/material/styles";
import Page from "components/Page/Page";
import WidgetsMenu from "../Widgets/WidgetsMenu";
import Player from "../Preview/Player";
// import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

type Properties = {
    classes: any,
    events: any,
}

const RIGHT_MENU: Array<StudioRightMenuItem> = [
    {
        key: StudioRightSection.LIVE_SETTINGS,
        icon: <Palette fontSize="large" />
    },
    {
        key: StudioRightSection.COMMENTS,
        icon: <ChatBubble fontSize="large" />
    }
    // ,
    // {
    //     key: StudioRightSection.VIDEO_PREVIEW,
    //     icon: <OndemandVideo fontSize="large" />
    // }
    // {
    //     key: StudioRightSection.WIDGETS_AND_OVERLAYS,
    //     icon: <Widgets fontSize="large" />
    // }
];
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function StudioPage(props: Properties) {
    const {
        classes,
        events,
    } = props;

    const [rightSection, setRightSection] = useState<string>(StudioRightSection.LIVE_SETTINGS);

    const [liveEvent, setLiveEvent] = useState<any>();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const {eventCode} = useParams<{eventCode: string}>();

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        if (eventCode === undefined || events[eventCode] === undefined) navigate('/events');

        if (eventCode !== undefined && events[eventCode] !== undefined) {
            setLiveEvent(events[eventCode]);
            console.debug('Studio.liveEvent', eventCode, events[eventCode], liveEvent);
        }
    }, [])


    const getRightSideElement = () => {
        console.debug('Studio.getRightSideElement', liveEvent);
        switch (rightSection) {
            case StudioRightSection.LIVE_SETTINGS:
                return <ChannelSettings liveEvent={liveEvent} />
            case StudioRightSection.WIDGETS_AND_OVERLAYS:
                return <WidgetsMenu liveEvent={liveEvent} />
            case StudioRightSection.VIDEO_PREVIEW:
                return <Player liveEvent={liveEvent} />
            default:
                return <Comments liveEvent={liveEvent} />
        }
    }

    const getMenuItems = () => {
        const items: Array<any> = [];
        RIGHT_MENU.forEach((item: StudioRightMenuItem) => {
            items.push(<ListItem key={item.key} button className={classNames(classes.menuItem)} selected={rightSection === item.key} onClick={() => setRightSection(item.key)}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <ListItemIcon className={classNames(classes.menuItemIcon)}>{ item.icon }</ListItemIcon>
                    <ListItemText primary={t(item.key)} />
                </Grid>
            </ListItem>)
        });

        return items;
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Page title={(eventCode && events[eventCode] !== undefined) ? events[eventCode].title : ''}>
            <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <Settings />
                </IconButton>
            </AppBar>

            { (liveEvent !== undefined ) ? (
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        container
                        direction="row"
                        alignItems="flex-start"
                        xs={10}
                        md={10}
                        lg={10}
                        spacing={5}
                    >
                        {/* Left */}
                        <Grid item container xs={12} md={10} lg={6} direction="row" spacing={3}>
                            <Grid item>
                                <ExternalStreamSource liveEvent={liveEvent} />
                            </Grid>
                            <Grid item>
                                <Divider orientation="horizontal" variant="fullWidth" />
                            </Grid>
                            <Grid item>
                                <LiveEventStreamTarget liveEvent={liveEvent} />
                            </Grid>
                        </Grid>

                        {/* Right */}
                        <Grid item container xs={12} md={12} lg={5} direction="row" className={classNames(classes.chat)}>
                            { getRightSideElement() }
                        </Grid>
                    </Grid>

                    <Grid item>
                        <DrawerHeader />
                    </Grid>

                    <Grid item container xs={2} direction="row">
                        <Drawer
                            variant="temporary"
                            anchor="bottom"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'},
                            }}
                        >
                            <List>
                                { getMenuItems() }
                            </List>
                        </Drawer>

                        <Drawer
                            variant="permanent"
                            anchor="right"
                            sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}
                        >
                            <List>
                                { getMenuItems() }
                            </List>
                        </Drawer>
                    </Grid>
                </Grid>
            ) : null }
        </Page>
    )
}

const mapStateToProps = function (state: any) {
    return {
        events: state.events,
    };
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StudioPage));
