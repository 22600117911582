import {Comment} from "./CommentsDTO";
import {LiveEvent} from "../Events/EventsDTO";

export const types = {
    ADD_COMMENT: 'ADD_COMMENT',
    CLEAR_COMMENTS: 'CLEAR_COMMENTS',
}

export function clearComments() {
    return { type: types.CLEAR_COMMENTS };
}

export function addComment(liveEvent: LiveEvent, comment: Comment) {
    return { type: types.ADD_COMMENT, liveEvent, comment };
}
