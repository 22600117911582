import {connect} from "react-redux";
import classNames from "classnames";
import {Alert, Button, FormControlLabel, Grid, Paper, Switch, TextField, Tooltip, Typography} from "@mui/material";
import {Facebook, Info, Instagram, Send, YouTube} from "@mui/icons-material";
import styles from "./Comments-jss";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Dispatch} from "redux";
import {
    LiveStreamRequest,
    StreamTarget,
    StreamTargetList,
    StreamTargetStatus,
    StreamTargetType
} from "../StreamTarget/streamTargetDTO";
import {LiveEvent} from "../Events/EventsDTO";
import {Channel, ChannelMap, ChannelSettingsType} from "../Channel/ChannelDTO";
import {
    Comment,
    CommentsChannelSettings,
    CommentsSettingsType,
    CommentStatus,
    isInstanceOfCommentsSettingsType,
    LiveEventCommentsMap
} from "./CommentsDTO";
import {updateChannelSettingsRequest} from "../Channel/channelActions";
import {
    sendACommentRequest,
    startListeningToCommentsRequest,
    stopListeningToCommentsRequest
} from "../StreamTarget/streamTargetActions";
import {Profile} from "../User/userDTO";
import {withStyles} from "@mui/styles";
import {SiTiktok, SiTwitch} from "react-icons/si";
import delay from "../../services/helpers/delay";
import {
    Live4tvLiveStreamRequest,
    isInstanceOfLive4tvApiStreamTarget,
    Live4tvApiStreamTarget,
} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {
    sendACommentToLive4tvApi,
    startListeningToLive4tvApiComments,
    stopListeningToLive4tvApiComments, stopSubscriptionToComments,
    subscribeToComments
} from "../StreamTargetLive4tvApi/live4tvApiActions";

type Properties = {
    classes: any,
    liveEvent: LiveEvent,
    comments: LiveEventCommentsMap,
    channels: ChannelMap,
    streamTargets: StreamTargetList,
    profile: Profile,
    updateChannelSettingsRequest: Function,
    sendACommentRequest: Function,
    startListeningToCommentsRequest: Function,
    stopListeningToCommentsRequest: Function,
    startListeningToLive4tvApiComments: Function,
    stopListeningToLive4tvApiComments: Function,
    sendACommentToLive4tvApi: Function,
    subscribeToComments: Function,
    stopSubscriptionToComments: Function,
}

function Comments(props: Properties) {
    const {
        classes,
        liveEvent,
        comments,
        channels,
        streamTargets,
        profile,
        updateChannelSettingsRequest,
        sendACommentRequest,
        startListeningToCommentsRequest,
        stopListeningToCommentsRequest,
        startListeningToLive4tvApiComments,
        stopListeningToLive4tvApiComments,
        sendACommentToLive4tvApi,
        subscribeToComments,
        stopSubscriptionToComments,
    } = props;

    const [channel, setChannel] = useState<Channel>();

    const [isCommentsMonitoringEnabled, setIsCommentsMonitoringEnabled] = useState<boolean>(false);

    const [isCommentsStarted, setCommentsStarted] = useState<boolean>(false);

    const [message, setMessage] = useState<string>('');
    const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

    const {t} = useTranslation();

    useEffect(() => {
        console.debug('Comments.channels', channels, liveEvent);
        let ch = channels[liveEvent.code];
        setChannel(ch);

        if (ch) {
            const commentsSettings = ch.settings[CommentsSettingsType.COMMENTS];
            if (commentsSettings && isInstanceOfCommentsSettingsType(commentsSettings)) {
                setIsCommentsMonitoringEnabled(commentsSettings.isEnabled);
            }
            setCommentsStarted(isListeningToComments(ch));
        }
    }, [channels,]);

    useEffect(() => {
        let div = document.getElementById('chat')
        if (comments[liveEvent.code] && comments[liveEvent.code].length > 0 && div) {
            div.scrollTop = div.scrollHeight;
        }
    }, [comments]);

    useEffect(() => {
        console.debug('Comments', 'streamTargets changed', streamTargets);
        if (channel) {
            setCommentsStarted(isListeningToComments(channel));
        }
    }, [streamTargets]);

    useEffect(() => {
        console.debug('isCommentsStarted', isCommentsStarted, channel);
        if (channel) {
            const targets: Array<Live4tvApiStreamTarget> = [];
            for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                const t = streamTargets[channelStreamTargetKey];
                if (isInstanceOfLive4tvApiStreamTarget(t)) {
                    targets.push(t);
                }
            }

            if (isCommentsStarted) {
                subscribeToComments({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent,
                    streamTargets: targets,
                    settings: channel.settings,
                });
            } else {
                stopSubscriptionToComments({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent,
                    streamTargets: targets,
                    settings: channel.settings,
                });
            }
        }
    }, [isCommentsStarted]);

    const isListeningToComments = (ch: Channel): boolean => {
        const listening = Object.keys(ch.streamTargets).find((channelStreamTargetKey) => {
                return streamTargets[channelStreamTargetKey].commentStatus === CommentStatus.LISTENING;
            });

        return listening !== undefined;
    }

    const handleIsEnabled = async (e: any) => {
        console.debug('handleIsEnabled', e.target.checked, channel);
        if (channel) {
            const settings: CommentsChannelSettings = {
                type: ChannelSettingsType.COMMENTS,
                isEnabled: e.target.checked,
            }

            if (settings.isEnabled) {
                const live4tvApiStreamTargets = [];

                for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                    let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];
                    console.debug('Comments.handleIsEnabled', 'streamTarget', streamTarget);

                    if (streamTarget.status === StreamTargetStatus.LIVE && [CommentStatus.NOT_STARTED, CommentStatus.START_FAILED].includes(streamTarget.commentStatus)) {
                        if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                            live4tvApiStreamTargets.push(streamTarget);
                        } else {

                            const request: LiveStreamRequest = {
                                streamTarget,
                                liveStreamUuid: channel.liveStreamUuid,
                                liveEvent,
                                token: profile.token,
                                settings: channel.settings,
                            }

                            startListeningToCommentsRequest(request);
                            await delay.sleep(2000);
                        }
                    }
                }

                if (live4tvApiStreamTargets.length > 0) {
                    startListeningToLive4tvApiComments({
                        profile,
                        liveStreamUuid: channel.liveStreamUuid,
                        streamTargets: live4tvApiStreamTargets,
                        liveEvent,
                        settings: channel.settings,
                    })
                }
            } else {
                const live4tvApiStreamTargets = [];

                for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                    let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];

                    if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                        live4tvApiStreamTargets.push(streamTarget);
                    } else {
                        const request: LiveStreamRequest = {
                            streamTarget,
                            liveStreamUuid: channel.liveStreamUuid,
                            liveEvent,
                            token: profile.token,
                            settings: channel.settings,
                        }

                        stopListeningToCommentsRequest(request);
                        await delay.sleep(2000);
                    }

                    if (live4tvApiStreamTargets.length > 0) {
                        stopListeningToLive4tvApiComments({
                            profile,
                            liveStreamUuid: channel.liveStreamUuid,
                            streamTargets: live4tvApiStreamTargets,
                            liveEvent,
                            settings: channel.settings,
                        })
                    }
                }
            }

            updateChannelSettingsRequest(channel, settings);
        }
    }

    const getIcon = (type: StreamTargetType) => {
        switch (type) {
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.INSTAGRAM:
                return  <Instagram />
            case StreamTargetType.YOUTUBE:
                return  <YouTube />
            case StreamTargetType.TIKTOK:
                return  <SiTiktok />
            case StreamTargetType.TWITCH:
                return  <SiTwitch />
            default:
                return <></>
        }
    }

    const sendMessage = async () => {
        setIsSendingMessage(true);
        if (channel) {
            const live4tvApiStreamTargets = [];

            for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];

                if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                    live4tvApiStreamTargets.push(streamTarget);
                } else {
                    const request: LiveStreamRequest = {
                        streamTarget: streamTarget,
                        liveStreamUuid: channel.liveStreamUuid,
                        liveEvent: liveEvent,
                        token: profile.token,
                        settings: channel.settings
                    }
                    sendACommentRequest(request, message);
                    await delay.sleep(2000);
                }
            }

            if (live4tvApiStreamTargets.length > 0) {
                sendACommentToLive4tvApi({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    streamTargets: live4tvApiStreamTargets,
                    liveEvent,
                    settings: channel.settings,
                }, message);
            }
        }

        setIsSendingMessage(false);
        setMessage('');
    }

    const listComments = () => {
        let htmlComments: Array<any> = [];
        if (comments[liveEvent.code]) {
            comments[liveEvent.code].map((comment: Comment) => {
                htmlComments.push(<Grid item alignItems="center" key={comment.key} className={classNames(classes.message)}>
                    <span>{getIcon(comment.streamTargetType)}</span>
                    <span>&nbsp;&nbsp;</span>
                    <span>{comment.message}</span>
                    <sub className={classNames(classes.username)}>{comment.username}</sub>
                </Grid>)

            })
        }

        return htmlComments;
    }

    return (
        <Grid
            item
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
            className={classNames(classes.root)}
        >
            <Grid item>
                <Typography gutterBottom variant="h5">
                    {t("Live Comments")}
                    <Tooltip title={t('Send message is currently supported for Facebook Pages, YouTube, and Instagram accounts. Facebook groups and profiles are not supported yet.') || ''}>
                        <Button startIcon={<Info />}></Button>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
            >
                <FormControlLabel
                    checked={isCommentsMonitoringEnabled}
                    control={<Switch />}
                    label={t('Enable comments monitoring')}
                    onChange={handleIsEnabled}
                />
            </Grid>
            { isCommentsStarted || (comments[liveEvent.code] && comments[liveEvent.code].length > 0) ? (
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="start"
                        alignItems="start"
                        spacing={2}
                        className={classNames(classes.paper)}
                        display="flex"
                    >
                        <Paper className={classNames(classes.messages)}>
                            <div id="chat" className={classNames(classes.messagesBody)}>
                                {listComments()}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={2}>
                        <form
                            onSubmit={(e: any) => {
                                e.preventDefault();
                                sendMessage();
                            }}
                        >
                            <Grid
                                item
                                container
                                direction="row"
                                // justify="center"
                                alignItems="flex-start"
                                spacing={2}>
                                <Grid item xs={8}>
                                    <TextField
                                        id="outlined-basic"
                                        label={t('Message')}
                                        variant="standard"
                                        // multiline
                                        // rows={2}
                                        fullWidth
                                        value={message}
                                        onChange={(e: any) => setMessage(e.target.value)}
                                        disabled={isSendingMessage}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        fullWidth
                                        endIcon={<Send />}
                                        size="large"
                                        disabled={isSendingMessage}
                                    >{t('Send')}</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            ) : (
                <Grid item>
                    <Alert severity="info" className={classNames(classes.alert)}>
                        {t("INFO: Here you will follow your live stream comments from all the bound social networks")}.
                    </Alert>
                </Grid>
            ) }
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        comments: state.comments,
        channels: state.channels,
        streamTargets: state.streamTargets,
        profile: state.user.profile,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        updateChannelSettingsRequest: (channel: Channel, settings: CommentsChannelSettings) => dispatch(updateChannelSettingsRequest(channel, settings)),
        sendACommentRequest: (liveStreamRequest: LiveStreamRequest, message: string) => dispatch(sendACommentRequest(liveStreamRequest, message)),
        startListeningToCommentsRequest: (liveStreamRequest: LiveStreamRequest) => dispatch(startListeningToCommentsRequest(liveStreamRequest)),
        stopListeningToCommentsRequest: (liveStreamRequest: LiveStreamRequest) => dispatch(stopListeningToCommentsRequest(liveStreamRequest)),
        startListeningToLive4tvApiComments: (startListeningToCommentsRequest: Live4tvLiveStreamRequest) => dispatch(startListeningToLive4tvApiComments(startListeningToCommentsRequest)),
        stopListeningToLive4tvApiComments: (stopListeningToCommentsRequest: Live4tvLiveStreamRequest) => dispatch(stopListeningToLive4tvApiComments(stopListeningToCommentsRequest)),
        subscribeToComments: (subscribeToCommentsRequest: Live4tvLiveStreamRequest) => dispatch(subscribeToComments(subscribeToCommentsRequest)),
        stopSubscriptionToComments: (stopSubscriptionToCommentsRequest: Live4tvLiveStreamRequest) => dispatch(stopSubscriptionToComments(stopSubscriptionToCommentsRequest)),
        sendACommentToLive4tvApi: (sendACommentRequest: Live4tvLiveStreamRequest, message: string) => dispatch(sendACommentToLive4tvApi(sendACommentRequest, message)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Comments))
