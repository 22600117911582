import {types} from "./MainMenuUIActions";

export type MainMenuUIState = {
    title: string,
};

const initialState: MainMenuUIState = {
    title: '',
}

function mainMenuUI(state: MainMenuUIState = initialState, action: { type: string, payload: string }) {
    switch (action.type) {
        case types.SET_MAIN_MENU_TITLE:
            return { ...state, title: action.payload };
        default:
            return state;
    }
}

export default mainMenuUI;
