import styles from "./ChannelControl-jss";
import {connect} from "react-redux";
import {Channel, ChannelMap, ChannelStatus, ChannelStreamTargets} from "./ChannelDTO";
import {LiveEvent} from "../Events/EventsDTO";
import {Profile} from "../User/userDTO";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    LiveStreamRequest,
    StreamTarget,
    StreamTargetList,
    StreamTargetStatus,
    StreamTargetType
} from "../StreamTarget/streamTargetDTO";
import {PlayArrow, Stop} from "@mui/icons-material";
import {
    createAndStartLiveStreamRequest,
    stopLiveStreamRequest,
    stopLiveStreamSuccess
} from "../StreamTarget/streamTargetActions";
import {useEffect, useState} from "react";
import {updateChannelStatusRequest, updateChannelStreamTargetUuidRequest} from "./channelActions";
import useFeatureFlags from "../FeatureFlag/useFeatureFlags";
import {withStyles} from "@mui/styles";
import delay from "../../services/helpers/delay";
import {isInstanceOfLive4tvApiStreamTarget, Live4tvLiveStreamRequest,} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {
    createAndStartLive4tvApiLiveStream,
    stopLive4tvApiLiveStream,
} from "../StreamTargetLive4tvApi/live4tvApiActions";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";

type Properties = {
    classes: any,
    liveEvent: LiveEvent,
    channels: ChannelMap,
    streamTargets: StreamTargetList,
    profile: Profile,
    updateChannelStatusRequest: Function,
    createAndStartLiveStreamRequest: Function,
    stopLiveStreamRequest: Function,
    stopLiveStreamSuccess: Function,
    createAndStartLive4tvApiLiveStream: Function,
    stopLive4tvApiLiveStream: Function,
    updateChannelStreamTargetUuidRequest: Function,
    setError: Function,
}

function ChannelControlStartButton(props: Properties) {
    const {
        liveEvent,
        channels,
        streamTargets,
        profile,
        updateChannelStatusRequest,
        createAndStartLiveStreamRequest,
        stopLiveStreamRequest,
        stopLiveStreamSuccess,
        createAndStartLive4tvApiLiveStream,
        stopLive4tvApiLiveStream,
        updateChannelStreamTargetUuidRequest,
        setError,
    } = props;

    const [channel, setChannel] = useState<Channel>();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [flagGoLive] = useFeatureFlags(profile.token, 'app_can_go_live');

    useEffect(() => {
    }, []);

    useEffect(() => {
        console.debug('channels', channels);
        setChannel(channels[liveEvent.code]);
    }, [channels]);

    useEffect(() => {
        console.debug('streamTargets', streamTargets);
        checkChannelStatus();
    }, [streamTargets]);

    useEffect(() => {
        checkChannelStatus();
    }, [channel])

    const checkChannelStatus = () => {
        console.debug('checkChannelStatus', channel);
        if (channel !== undefined) {
            let status: ChannelStatus = channel.status;
            const keys = Object.keys(channel.streamTargets);

            const countKeys = keys.length;
            let countIdle = 0;

            keys.forEach((channelStreamTargetKey: string) => {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];
                if (streamTarget.status === StreamTargetStatus.LIVE) {
                    status = ChannelStatus.LIVE;
                    return;
                }

                if ([StreamTargetStatus.IDLE, StreamTargetStatus.STOP_FAILED, StreamTargetStatus.START_FAILED].includes(streamTarget.status)) {
                    countIdle++;
                }
            });

            if (countKeys === countIdle) {
                status = ChannelStatus.IDLE;
            }

            console.debug('checkChannelStatus.status', status);
            updateChannelStatusRequest(channel, status);
        }
    }

    const createAndStartAll = async () => {
        if (channel) {
            updateChannelStatusRequest(channel, ChannelStatus.STARTING);

            setError(undefined);

            let targets: ChannelStreamTargets = {};
            if (channel) {
                targets = channel.streamTargets;
            }

            const flag = await flagGoLive.validate({
                streamTargets: targets,
            });
            if (!flag.is_active) {
                setError(t('app_can_go_live.' + flag.reason, Object.assign({}, {interpolation: {escapeValue: false}}, flag.additional_data)));
                if (flag.reason === 'feature_flag.user.has_no_active_plan') {
                    navigate('/plan');
                    return;
                }

                updateChannelStatusRequest(channel, ChannelStatus.IDLE);
                return;
            }

            const live4tvApiStreamTargets = [];

            for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];

                if ([StreamTargetStatus.IDLE, StreamTargetStatus.START_FAILED, StreamTargetStatus.STOP_FAILED].includes(streamTarget.status)) {
                    live4tvApiStreamTargets.push(streamTarget);
                }
            }

            if (live4tvApiStreamTargets.length > 0) {
                createAndStartLive4tvApiLiveStream({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent,
                    streamTargets: live4tvApiStreamTargets,
                    settings: channel.settings,
                })
            }
        }
    }

    const stopAll = async () => {
        if (channel) {
            updateChannelStatusRequest(channel, ChannelStatus.STOPPING);

            const live4tvApiStreamTargets = [];

            for (const channelStreamTargetKey of Object.keys(channel.streamTargets)) {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];
                if (streamTarget.status === StreamTargetStatus.LIVE) {
                    if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                        live4tvApiStreamTargets.push(streamTarget);

                    } else { // @todo remove the code from this else when all the stream targets are migrated to the new live4tv api
                        const request: LiveStreamRequest = {
                            streamTarget,
                            liveStreamUuid: channel.liveStreamUuid,
                            liveEvent,
                            token: profile.token,
                            settings: channel.settings,
                        }
                        stopLiveStreamRequest(request);
                        await delay.sleep(2000);
                    }

                }
                // we need to handle for some issues when the streaming not starting, then the spinner at display the looping forever we should stop it and turn to the idle status again
                if (streamTarget.status === StreamTargetStatus.STARTING) {
                    const request: LiveStreamRequest = {
                        streamTarget,
                        liveStreamUuid: channel.liveStreamUuid,
                        liveEvent,
                        token: profile.token,
                        settings: channel.settings,
                    };
                    stopLiveStreamSuccess(request);
                }
            }

            if (live4tvApiStreamTargets.length > 0) {
                stopLive4tvApiLiveStream({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    streamTargets: live4tvApiStreamTargets,
                    settings: channel.settings,
                    liveEvent,
                })
            }

            // reset the uuid event to avoid use the same uuid to another live
            updateChannelStreamTargetUuidRequest(channels[liveEvent.code], `${liveEvent.code}_${uuidv4()}`);
        }
    }

    return (
        <>
            {(channel !== undefined && Object.keys(channel.streamTargets).length > 0) ?
                <>
                    { ([ChannelStatus.IDLE, ChannelStatus.STARTING].includes(channel.status)) ?
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label={t("Start All Targets")}
                            startIcon={<PlayArrow />}
                            onClick={createAndStartAll}
                            disabled={channel.status === ChannelStatus.STARTING}
                        >
                            { t("Start All Targets") }
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label={t("Stop All Targets")}
                            startIcon={<Stop />}
                            onClick={stopAll}
                            disabled={channel.status === ChannelStatus.STOPPING}
                        >
                            { t("Stop All Targets") }
                        </Button>
                    }
                </>
            : null }
        </>
    )
}

const mapStateToProps = function (state: any) {
    return {
        streamTargets: state.streamTargets,
        channels: state.channels,
        profile: state.user.profile,
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        updateChannelStatusRequest: (channel: Channel, status: ChannelStatus) => dispatch(updateChannelStatusRequest(channel, status)),
        createAndStartLiveStreamRequest: (request: LiveStreamRequest) => dispatch(createAndStartLiveStreamRequest(request)),
        stopLiveStreamRequest: (request: LiveStreamRequest) => dispatch(stopLiveStreamRequest(request)),
        stopLiveStreamSuccess: (request: LiveStreamRequest) => dispatch(stopLiveStreamSuccess(request)),
        createAndStartLive4tvApiLiveStream: (createAndStartLiveRequest: Live4tvLiveStreamRequest) => dispatch(createAndStartLive4tvApiLiveStream(createAndStartLiveRequest)),
        stopLive4tvApiLiveStream: (stopLiveRequest: Live4tvLiveStreamRequest) => dispatch(stopLive4tvApiLiveStream(stopLiveRequest)),
        updateChannelStreamTargetUuidRequest: (channel: Channel, uuid: string) => dispatch(updateChannelStreamTargetUuidRequest(channel, uuid)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChannelControlStartButton));
