import {connect} from "react-redux";
import {TableContainer, Table, TableBody, TableHead, TableRow, TableCell} from "@mui/material";
import {StreamTargetList} from "./streamTargetDTO";
import {withStyles} from "@mui/styles";
import styles from "./StreamTargetAccountList-jss";
import StreamTargetAccount from "./StreamTargetAccount";
import {useEffect} from "react";
import {listTargets} from "../StreamTargetLive4tvApi/live4tvApiActions";
import {Dispatch} from "redux";
import {Live4tvListStreamTargetsRequest} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {Profile} from "../User/userDTO";

type Properties = {
    classes: any,
    streamTargets: StreamTargetList,
    profile: Profile,
    listTargets: Function,
}

function StreamTargetAccountList(props: Properties) {
    const {
        streamTargets,
        profile,
        listTargets,
    } = props;

    useEffect(() => {
        listTargets({
            profile,
        });
    }, []);

    useEffect(() => {
        console.debug('StreamTargetAccountList', 'streamTargets', streamTargets);
    }, [streamTargets]);

    function getStreamTargetAccounts(): Array<any> {
        let targets: Array<any> = [];
        Object.keys(streamTargets).forEach((key: string) => {
            targets.push(<StreamTargetAccount key={streamTargets[key].key} streamTarget={streamTargets[key]} />);
        })

        return targets;
    }

    return (
        <TableContainer>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getStreamTargetAccounts()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        listTargets: (payload: Live4tvListStreamTargetsRequest) => dispatch(listTargets(payload)),
    }
}


const mapStateToProps = function (state: any) {
    return {
        streamTargets: state.streamTargets,
        profile: state.user.profile,
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StreamTargetAccountList));
