import {
    Live4tvLiveStreamRequest,
    Live4tvApiAuthRequest,
    Live4tvApiStreamTarget, Live4tvListStreamTargetsRequest, Live4tvRemoveStreamTargetRequest,
} from "./live4tvApiDTO";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";

export const types = {
    ADD_LIVE4TV_TARGET_REQUEST: 'ADD_LIVE4TV_TARGET_REQUEST',
    ADD_LIVE4TV_TARGET_SUCCESS: 'ADD_LIVE4TV_TARGET_SUCCESS',
    ADD_LIVE4TV_TARGET_CANCELED: 'ADD_LIVE4TV_TARGET_CANCELED',

    LIVE4TV_LOGIN_REQUEST: 'LIVE4TV_LOGIN_REQUEST',
    LIVE4TV_LOGIN_SUCCESS: 'LIVE4TV_LOGIN_SUCCESS',
    LIVE4TV_LOGIN_FAIL: 'LIVE4TV_LOGIN_FAIL',

    LIVE4TV_CHECKPOINT_REQUEST: 'LIVE4TV_CHECKPOINT_REQUEST',
    LIVE4TV_CHECKPOINT_SUCCESS: 'LIVE4TV_CHECKPOINT_SUCCESS',

    LIVE4TV_CREATE_AND_START_LIVE_STREAM_REQUEST: "LIVE4TV_CREATE_AND_START_LIVE_STREAM_REQUEST",

    LIVE4TV_STOP_LIVE_STREAM_REQUEST: "LIVE4TV_STOP_LIVE_STREAM_REQUEST",

    LIVE4TV_START_LISTENING_TO_COMMENTS_REQUEST: "LIVE4TV_START_LISTENING_TO_COMMENTS_REQUEST",

    LIVE4TV_STOP_LISTENING_TO_COMMENTS_REQUEST: "LIVE4TV_STOP_LISTENING_TO_COMMENTS_REQUEST",

    LIVE4TV_SEND_A_COMMENT_REQUEST: "LIVE4TV_SEND_A_COMMENT_REQUEST",
    LIVE4TV_SEND_A_COMMENT_FAIL: "LIVE4TV_SEND_A_COMMENT_FAIL",

    LIVE4TV_SUBSCRIBE_TO_COMMENTS_REQUEST: "LIVE4TV_SUBSCRIBE_TO_COMMENTS_REQUEST",

    LIVE4TV_STOP_SUBSCRIPTION_TO_COMMENTS_REQUEST: "LIVE4TV_STOP_SUBSCRIPTION_TO_COMMENTS_REQUEST",

    LIVE4TV_TOGGLE_COMMENTS_REQUEST: "LIVE4TV_TOGGLE_COMMENTS_REQUEST",

    LIVE4TV_RESET_INTEGRATION: "LIVE4TV_RESET_INTEGRATION",

    LIVE4TV_LIST_TARGETS_REQUEST: "LIVE4TV_LIST_TARGETS_REQUEST",

    LIVE4TV_DELETE_TARGET_REQUEST: "LIVE4TV_DELETE_TARGET_REQUEST",
}

export function addLive4tvApiTargetRequest( streamTargetIntegration: StreamTargetIntegration ) {
    return { type: types.ADD_LIVE4TV_TARGET_REQUEST, streamTargetIntegration };
}

export function cancelAddLive4tvApi() {
    return { type: types.ADD_LIVE4TV_TARGET_CANCELED };
}

export function loginToLive4tvApi(live4tvApiAuthRequest: Live4tvApiAuthRequest) {
    return { type: types.LIVE4TV_LOGIN_REQUEST, payload: live4tvApiAuthRequest }
}

export function loginToLive4tvApiSuccess(live4tvApiStreamTarget: Live4tvApiStreamTarget) {
    return { type: types.LIVE4TV_LOGIN_SUCCESS, payload: live4tvApiStreamTarget };
}

export function createAndStartLive4tvApiLiveStream(createAndStartLiveRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_CREATE_AND_START_LIVE_STREAM_REQUEST, payload: createAndStartLiveRequest };
}

export function stopLive4tvApiLiveStream(stopLiveRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_STOP_LIVE_STREAM_REQUEST, payload: stopLiveRequest };
}

export function startListeningToLive4tvApiComments(startListeningToCommentsRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_START_LISTENING_TO_COMMENTS_REQUEST, payload: startListeningToCommentsRequest };
}

export function stopListeningToLive4tvApiComments(stopListeningToCommentsRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_STOP_LISTENING_TO_COMMENTS_REQUEST, payload: stopListeningToCommentsRequest };
}

export function sendACommentToLive4tvApi(sendACommentRequest: Live4tvLiveStreamRequest, message: string) {
    return { type: types.LIVE4TV_SEND_A_COMMENT_REQUEST, payload: sendACommentRequest, message };
}

export function sendACommentToLive4tvApiFail(sendACommentRequest: Live4tvLiveStreamRequest, message: string) {
    return { type: types.LIVE4TV_SEND_A_COMMENT_FAIL, payload: sendACommentRequest, message };
}

export function subscribeToComments(subscribeToCommentsRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_SUBSCRIBE_TO_COMMENTS_REQUEST, payload: subscribeToCommentsRequest };
}

export function stopSubscriptionToComments(stopSubscriptionToCommentsRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_STOP_SUBSCRIPTION_TO_COMMENTS_REQUEST, payload: stopSubscriptionToCommentsRequest };
}

export function toggleComments(toggleCommentsRequest: Live4tvLiveStreamRequest) {
    return { type: types.LIVE4TV_TOGGLE_COMMENTS_REQUEST, payload: toggleCommentsRequest };
}

export function checkpointRequired(checkpointRequiredRequest: Live4tvApiAuthRequest, infoMessage: string) {
    return { type: types.LIVE4TV_CHECKPOINT_REQUEST, payload: checkpointRequiredRequest, infoMessage };
}

export function checkpointSuccess(checkpointRequiredRequest: Live4tvApiAuthRequest) {
    return { type: types.LIVE4TV_CHECKPOINT_SUCCESS, payload: checkpointRequiredRequest };
}

export function resetIntegration() {
    return { type: types.LIVE4TV_RESET_INTEGRATION };
}

export function listTargets(live4tvListStreamTargetsRequest: Live4tvListStreamTargetsRequest) {
    return { type: types.LIVE4TV_LIST_TARGETS_REQUEST, payload: live4tvListStreamTargetsRequest };
}

export function deleteLive4TvTarget(payload: Live4tvRemoveStreamTargetRequest) {
    return { type: types.LIVE4TV_DELETE_TARGET_REQUEST, payload };
}
