import {connect} from "react-redux";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {withStyles} from "@mui/styles";
import styles from "./StreamTargetPage-jss";
import {Grid, Typography} from "@mui/material";
import StreamTargetIntegrations from "../StreamTargetIntegration/StreamTargetIntegrations";
import StreamTargetAccountList from "./StreamTargetAccountList";
import {Dispatch} from "redux";
import Page from "../../components/Page/Page";

type Properties = {
    classes: any
};

function StreamTargetPage(props: Properties) {
    const {
    } = props;

    const {t} = useTranslation();

    return (
        <Page title={t('Stream destinations')}>
            <Grid
                item
                container
                alignItems="flex-start"
                direction="row"
                spacing={5}
            >
                <Grid
                    item
                    container
                    alignItems="flex-start"
                    direction="row"
                    spacing={2}
                >
                    <StreamTargetIntegrations />
                </Grid>

                <Grid
                    item
                    container
                    alignItems="flex-start"
                    direction="row"
                    spacing={2}
                >
                </Grid>

                <Grid
                    item
                    container
                    alignItems="flex-start"
                    direction="row"
                    spacing={2}
                >
                    <Typography variant="h5">{t('Accounts')}</Typography>
                    <StreamTargetAccountList />
                </Grid>
            </Grid>
        </Page>
    );
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(StreamTargetPage));
