import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    addTargetMenuButton: {
        marginTop: theme.spacing(2),
    },
});

export default styles;
