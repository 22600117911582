import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {

    },
    wrapIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap' as 'wrap',
        '& *': {
            marginLeft: theme.spacing(1)
        }
    },
    dialog: {
        // minWidth: '500px',
    },
    dataGrid: {
        minWidth: '500px',
        width: '100%',
    },
    progressBar: {
        width: "100%",
    },
});

export default styles;
