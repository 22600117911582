import { types } from "./streamTargetIntegrationActions";
import {StreamTargetIntegration} from "./streamTargetIntegrationDTO";

const initialState: Array<StreamTargetIntegration> = [];

function streamTargetIntegrations(state: any = initialState, payload: { type: string, streamTargetIntegrations: Array<StreamTargetIntegration> }) {
    switch (payload.type) {
        case types.FETCH_STREAM_TARGET_INTEGRATIONS_SUCCESS:
            return Object.values(payload.streamTargetIntegrations);
        case types.FETCH_STREAM_TARGET_INTEGRATIONS_FAILED:
        default:
            return state;
    }
}

export default streamTargetIntegrations;
