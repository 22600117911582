import TagManager from "react-gtm-module";

const gtmConfig = require('config/app.config').gtm;

const tagManagerArgs = {
    gtmId: gtmConfig.id
}

let client: any = null;

export const analyticsClient = {
    getClient() {
        if (!client) {
            TagManager.initialize(tagManagerArgs);
        }

        client = (window as any).dataLayer;

        return client;
    },

    async trackPageView(path: string, userId?: string) {
        const title = document.title;

        console.log('trackPageView()', title, path);
        let pageView: any = {
            event: 'pageview',
            page: {
                url: path,
                title,
            }
        };
        pageView.user = {};
        if (userId) {
            pageView.user.id = userId;
        }

        this.getClient().push(pageView);
    },

    async trackEvent(eventName: string, eventParams: { [key: string]: string }) {
        const eventStructure = {
            event: eventName,
        };
        const event = Object.assign({}, eventStructure, eventParams);
        console.log('trackEvent()', event);
        this.getClient().push(event);
    },
}

export default analyticsClient;
