import {httpClient} from "../../services/live4tv-client/http-client";
import {StreamTargetType} from "../StreamTarget/streamTargetDTO";

export type SocialNetworkAccount = {
    uuid: string;
    name: string;
    type: string;
}

export type ActionRequiredResponse = {
    uuid: string;
    code: 302;
    message: 'checkpoint' | '2fa' | 'login';
    response_type: 'redirect' | string;
    reference: string;
    metadata : object;
    method: 'GET';
    url: string;
    params: object;
}

export type AccountResponse = {
    uuid: string;
    code: number;
    message: string;
}

export type CreateAndStartLiveStreamRequest = {
    title: string,
    description: string,
    accounts: Array<string>
}

export type LiveStreamResponse = {
    uuid: string;
    streamUrl: string,
    streamKey: string,
    username?: string,
    password?: string,
    code: number,
    message: string,
}

export type CreateAndStartLiveStreamResponse = {
    uuid: string;
    accounts: Array<LiveStreamResponse | ActionRequiredResponse>;
}

export type StopLiveStreamRequest = {
    accounts: Array<string>;
    saveLive: boolean;
}


export type StopLiveStreamResponse = {
    uuid: string;
    accounts: Array<AccountResponse | ActionRequiredResponse>;
}

export type StartListeningToLiveStreamCommentsRequest = {
    accounts: Array<string>;
}

export type StartListeningToLiveStreamCommentsResponse = {
    uuid: string;
    accounts: Array<AccountResponse | ActionRequiredResponse>;
}

export type StopListeningToLiveStreamCommentsRequest = {
    accounts: Array<string>;
}

export type StopListeningToLiveStreamCommentsResponse = {
    uuid: string;
    accounts: Array<AccountResponse | ActionRequiredResponse>;
}

export type FetchLiveStreamCommentsResponse = {
    uuid: string;
    count: number;
    lastCommentTimestamp: number;
    comments: Array<{
        uuid: string;
        socialNetwork: StreamTargetType;
        message: string;
        sentAt: number;
        user: {
            photo: string,
            username: string,
            uuid: string,
        };
    }>
}

export type SendACommentToLiveStreamRequest = {
    message: string;
}

export type SendACommentToLiveStreamResponse = {
    uuid: string;
    accounts: Array<AccountResponse | ActionRequiredResponse>;
}

export type MuteCommentsRequest = {
    accounts: Array<string>;
}

export type MuteCommentsResponse = {
    uuid: string;
    accounts: Array<AccountResponse | ActionRequiredResponse>;
}

export type Live4tvListAccountsResponse = Array<SocialNetworkAccount>;

export type Live4tvListLiveStreamsResponse = {
    total: number;
    data: Array<{uuid: string, title: string, description: string, started_at: string}>
};

export const live4tvLive4tvApiService = {
    async getUrlAuthorizationUrl(apiToken: string, network: string, uuid: string, userEmail: string,): Promise<string> {
        const url = `/api/v2/stream-network/proxy?cmd=social-network-account/${network}/authorise-url`;

        try {
            const response = await httpClient.post<{ url: string }>(url,
                {
                    userExternalId: userEmail,
                    accountUuid: uuid,
                },
                {
                headers: {
                    'Authorization': `Bearer ${apiToken}`
                }
            });
            if (response.status === 200) {
                return response.data.url;
            }

            console.log(`Error access live4tv API '${url}', responseCode: ${response.status}, ${response.data}`);
        } catch (error: any) {
            console.log(`Error access live4tv API '${url}' ${error?.message}`);
            throw error;
        }

        throw new Error('error');
    },

    async getSocialNetworkAccount(apiToken: string, userReference: string, uuid: string): Promise<SocialNetworkAccount> {
        const url = `/api/v2/stream-network/proxy?cmd=social-network-account/${userReference}/${uuid}`;

        const response = await httpClient.get<SocialNetworkAccount>(url, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        });

        return response.data;
    },

    async createAndStartLiveStream(
        apiToken: string,
        liveStreamUuid: string,
        createAndStartLiveStreamRequest: CreateAndStartLiveStreamRequest,
    ): Promise<CreateAndStartLiveStreamResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/create-and-start`;

        const response = await httpClient.post<CreateAndStartLiveStreamResponse>(url,
            createAndStartLiveStreamRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                },
            });

        return response.data;
    },

    async stopLiveStream(
        apiToken: string,
        liveStreamUuid: string,
        stopLiveStreamRequest: StopLiveStreamRequest
    ): Promise<StopLiveStreamResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/stop`;

        const response = await httpClient.post<CreateAndStartLiveStreamResponse>(url,
            stopLiveStreamRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                },
            });

        return response.data;
    },

    async startListeningToComments(
        apiToken: string,
        liveStreamUuid: string,
        startListeningToLiveStreamCommentsRequest: StartListeningToLiveStreamCommentsRequest
    ): Promise<StartListeningToLiveStreamCommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments/start-listening`;

        const response = await httpClient.post<StartListeningToLiveStreamCommentsResponse>(url,
            startListeningToLiveStreamCommentsRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            },
        );

        return response.data;
    },

    async stopListeningToComments(
        apiToken: string,
        liveStreamUuid: string,
        stopListeningToLiveStreamCommentsRequest: StopListeningToLiveStreamCommentsRequest
    ): Promise<StopListeningToLiveStreamCommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments/stop-listening`;

        const response = await httpClient.post<StartListeningToLiveStreamCommentsResponse>(url,
            stopListeningToLiveStreamCommentsRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            },
        );

        return response.data;
    },

    async fetchLiveStreamComments(
        apiToken: string,
        liveStreamUuid: string,
        lastCommentTimestamp: number,
    ): Promise<FetchLiveStreamCommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments?lastCommentTimestamp=${lastCommentTimestamp}`;

        const response = await httpClient.get<FetchLiveStreamCommentsResponse>(url, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        });

        return response.data;
    },

    async sendACommentToLiveStream(
        apiToken: string,
        liveStreamUuid: string,
        sendACommentToLiveStreamRequest: SendACommentToLiveStreamRequest,
    ): Promise<SendACommentToLiveStreamResponse | ActionRequiredResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments`;

        const response = await httpClient.post<SendACommentToLiveStreamResponse>(url,
            sendACommentToLiveStreamRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            },
        );

        return response.data;
    },

    async mute(
        apiToken: string,
        liveStreamUuid: string,
        muteRequest: MuteCommentsRequest,
    ): Promise<MuteCommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments/mute`;

        const response = await httpClient.post<MuteCommentsResponse>(url,
            muteRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            });

        return response.data;
    },

    async unmute(
        apiToken: string,
        liveStreamUuid: string,
        muteRequest: MuteCommentsRequest,
    ): Promise<MuteCommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=live-streams/${liveStreamUuid}/comments/unmute`;

        const response = await httpClient.post<MuteCommentsResponse>(url,
            muteRequest,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            });

        return response.data;
    },

    async listAccounts(
        apiToken: string,
        userReference: string,
    ): Promise<Live4tvListAccountsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=social-network-account/${userReference}`;

        const response = await httpClient.get<Live4tvListAccountsResponse>(url,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            });

        return response.data;
    },

    async deleteAccount(
        apiToken: string,
        uuid: string,
    ): Promise<Live4tvListAccountsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=social-network-account/${uuid}`;

        const response = await httpClient.delete<Live4tvListAccountsResponse>(url,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            });

        return response.data;
    },

    async getLiveStreamList(
        apiToken: string,
        userReference: string,
    ): Promise<Live4tvListLiveStreamsResponse> {
        const url = '/api/v2/stream-network/live-streams';
        const response = await httpClient.get<Live4tvListLiveStreamsResponse>(url,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                }
            });

        return response.data;
    },

    async getLiveStreamDataDownload(
        apiToken: string,
        liveStreamUuid: string,
    ): Promise<string> {
        const url = `/api/v2/stream-network/comments-download?liveStreamId=${liveStreamUuid}`;

        const response = await httpClient.get<string>(url, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        });

        return response.data;
    }
}
