import {connect} from "react-redux";
import {Dispatch} from "redux";
import {setIsLoggedIn, setUserProfile} from "./userActions";
import {clearComments} from "../Comments/commentsActions";
import {clearStreamTargets} from "../StreamTarget/streamTargetActions";
import {Profile} from "./userDTO";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

type Properties = {
    setIsLoggedIn: Function,
    setUserProfile: Function,
    clearComments: Function,
    clearStreamTargets: Function,
}

function LogoutPage(props: Properties) {
    const {
        clearComments,
        clearStreamTargets,
        setIsLoggedIn,
        setUserProfile,
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
        clearComments();
        clearStreamTargets();
        setIsLoggedIn(false);
        setUserProfile({});

        navigate('/');
    }, []);

    return null;
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        setIsLoggedIn: (signedIn: boolean) => dispatch(setIsLoggedIn(signedIn)),
        setUserProfile: (profile: Profile) => dispatch(setUserProfile(profile)),
        clearComments: () => dispatch(clearComments()),
        clearStreamTargets: () => dispatch(clearStreamTargets()),
    };
}

export default (connect(null, mapDispatchToProps))(LogoutPage);
