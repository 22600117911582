import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        minHeight: '50vh',
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    form: {
        width: '100%',
    },
    submit: {
        float: 'right' as 'right',
        marginTop: theme.spacing(3)
    },
    serviceLinks: {
        // display: 'flex',
        marginTop: theme.spacing(3),
        textAlign: 'center' as 'center',
        '& a': {
            display: 'inline-block',
            marginTop: theme.spacing(5),
        },
        '& a:last-child': {
            marginLeft: theme.spacing(3),
        },
    },
    whatsapp: {
        margin: theme.spacing(10),
        // backgroundColor: '#25d366',
        border: 'solid 1px black',
    }
});

export default styles;
