import {httpClient} from "../../services/live4tv-client/http-client";
import {AxiosResponse} from "axios";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;

export type Subscription = {
    code: string,
    currency: string,
    created_at: string,
    current_cycle: number,
    expiration_date: string,
    price: number,
    psp_subscription_reference: string,
    starting_cycle: number,
    starting_price: number,
    status: 'ACTIVE' | 'PAUSED' | 'CANCELED' | 'PENDING' | 'CREATED',
    updated_at: string
}

const live4tvPlanService = {
    async getActivePlan(token: string): Promise<AxiosResponse<Array<Subscription>>> {
        const url = `/api/v1/subscription/active`;

        return await httpClient.get<Array<Subscription>>(
            url,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        );
    },
}

export default live4tvPlanService;
