const styles = theme => ({
    root: {
    },

    submitButton: {
        marginTop: theme.spacing(4),
    }
});

export default styles;
