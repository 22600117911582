import live4tvStreamTargetsService from "./live4tvFeatureFlagClientService";
import {FlagParams, FlagResponse} from "./FeatureFlagDTO";

class FeatureCheck {
    private readonly token: string;
    private readonly feature: string;

    constructor(token: string, flag: string) {
        this.token = token;
        this.feature = flag;
    }

    async validate(params?: FlagParams): Promise<FlagResponse> {
        try {
            return  await live4tvStreamTargetsService.getFlag(this.token, this.feature, params);

        } catch (e) {
            console.error('FeatureCheck.isActive.error', e);
            return {
                flag: this.feature,
                is_active: false,
                reason: 'request_failed',
                additional_data: {},
            };
        }
    }

    async isActive(params?: FlagParams): Promise<boolean> {
        try {
            const response = await live4tvStreamTargetsService.getFlag(this.token, this.feature, params);

            return response.is_active;

        } catch (e) {
            console.error('FeatureCheck.isActive.error', e);

            return false;
        }

    }
}

function useFeatureFlags(token: string, ...features: Array<string>): Array<FeatureCheck> {
    const f: Array<FeatureCheck> = [];
    features.map((feature: string) => {
        f.push(new FeatureCheck(token, feature));
    })

    return f;
}

export default useFeatureFlags;
