import {StreamTargetDialog} from "./streamTargetDialogDTO";

export const types = {
    OPEN_STREAM_TARGET_DIALOG: 'OPEN_STREAM_TARGET_DIALOG',
    CLOSE_STREAM_TARGET_DIALOG: 'CLOSE_STREAM_TARGET_DIALOG',
}

export function openStreamTargetDialog(dialog: StreamTargetDialog) {
    return { type: types.OPEN_STREAM_TARGET_DIALOG, dialog };
}

export function closeStreamTargetDialog() {
    return {type: types.CLOSE_STREAM_TARGET_DIALOG };
}
