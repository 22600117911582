import {connect} from "react-redux";
import {FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Channel, ChannelMap, ChannelSettingsType, ChannelStatus} from "../Channel/ChannelDTO";
import {withStyles} from "@mui/styles";
import styles from "./InstagramSettings-jss";
import {useEffect, useRef, useState} from "react";
import {
    ScreenFormat,
    StreamTarget, StreamTargetList,
} from "../StreamTarget/streamTargetDTO";
import {updateChannelSettingsRequest} from "../Channel/channelActions";
import {
    InstagramChannelSettings,
    isInstanceOfInstagramChannelSettings
} from "./InstagramSettingsDTO";
import {LiveEvent} from "../Events/EventsDTO";
import {Profile} from "../User/userDTO";
import {
    Live4tvLiveStreamRequest,
    isInstanceOfLive4tvApiStreamTarget,
    Live4tvApiStreamTarget
} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {toggleComments} from "../StreamTargetLive4tvApi/live4tvApiActions";

type Properties = {
    classes: any,
    channels: ChannelMap,
    channel: Channel,
    liveEvent: LiveEvent,
    streamTargets: StreamTargetList,
    profile: Profile,
    updateChannelSettingsRequest: Function,
    toggleComments: Function,
}

function InstagramSettings(props: Properties) {
    const {
        classes,
        channels,
        channel,
        liveEvent,
        streamTargets,
        profile,
        updateChannelSettingsRequest,
        toggleComments,
    } = props;

    const [saveLive, setSaveLive] = useState<boolean>(true);
    const [allowComments, setAllowComments] = useState<boolean>(true);
    const [screenFormat, setScreenFormat] = useState<ScreenFormat>(ScreenFormat.VERTICAL_90_CLOCKWISE);
    const [background, setBackground] = useState<string|undefined>(undefined);

    const timer = useRef<null|NodeJS.Timeout>();

    const {t} = useTranslation();

    useEffect(() => {
        const settings = channel.settings[ChannelSettingsType.INSTAGRAM];
        if (isInstanceOfInstagramChannelSettings(settings)) {
            configureSettings(settings);
        }
        timer.current = null;
    }, [])

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        const settings: InstagramChannelSettings = {
            type: ChannelSettingsType.INSTAGRAM,
            background: background,
            screenFormat: screenFormat,
            saveToIGTV: saveLive,
            allowComments: allowComments,
        };

        timer.current = setTimeout(() => {
            console.debug('Updating settings for channel', channels[channel.key], settings);
            updateChannelSettingsRequest(channels[channel.key], settings);
        }, 500);
    }, [saveLive, screenFormat, background, allowComments]);

    useEffect(() => {
        if (channels[channel.key] && channels[channel.key].status === ChannelStatus.LIVE) {
            toggleAllowComments();
        }
    }, [channel.settings[ChannelSettingsType.INSTAGRAM]]);

    const configureSettings = (settings: InstagramChannelSettings) => {
        setSaveLive(settings.saveToIGTV);
        setAllowComments(settings.allowComments);
        setScreenFormat(settings.screenFormat);
        setBackground(settings.background);
    }

    const updateBackground = (e: any) => {
        e.preventDefault();
        console.debug('screenFormar.updateBackground.changed', e.target.files[0].path);
        setBackground(e.target.files[0].path);
    }

    const toggleAllowComments = () => {
        console.debug('toggleAllowComments.changed');

        if (channel) {
            const live4tvApiStreamTargets: Array<Live4tvApiStreamTarget> = [];

            Object.keys(channel.streamTargets).forEach((channelStreamTargetKey: string) => {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];

                if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                    live4tvApiStreamTargets.push(streamTarget);
                }
            });

            if (live4tvApiStreamTargets.length > 0) {
                const request: Live4tvLiveStreamRequest = {
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    streamTargets: live4tvApiStreamTargets,
                    liveEvent,
                    settings: channel.settings,
                }
                toggleComments(request);
            }
        }
    }

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>
                <Typography gutterBottom variant="h6">
                    {t('Instagram')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="resolution">{t('Adjust the Screen Format')}</InputLabel>
                    <Select
                        labelId="resolution"
                        id="resolution"
                        value={screenFormat}
                        label={t('Screen Format')}
                        onChange={(e: any) => { setScreenFormat( e.target.value ) }}
                        disabled={[ChannelStatus.STARTING, ChannelStatus.STOPPING, ChannelStatus.LIVE].includes(channel.status) || false}
                    >
                        <MenuItem value={ScreenFormat.DEFAULT}>{t('Normal')}</MenuItem>
                        <MenuItem value={ScreenFormat.VERTICAL_90_CLOCKWISE}>{t('Rotate to Horizontal')}</MenuItem>
                        <MenuItem value={ScreenFormat.SCALE_TOP}>{t('Scale on Top')}</MenuItem>
                        <MenuItem value={ScreenFormat.SCALE_CENTER}>{t('Scale on Center')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth></FormControl>

                {/*{ ([ScreenFormat.SCALE_TOP, ScreenFormat.SCALE_CENTER].includes(screenFormat)) ? (*/}
                {/*    <>*/}
                {/*        <FormControl fullWidth></FormControl>*/}
                {/*        <InputLabel id="background-image">{ t('Select a Background Image (720x1280 pixels)') }</InputLabel>*/}
                {/*        <br/>*/}
                {/*        <FormControl fullWidth>*/}
                {/*            <input*/}
                {/*                accept="image/*"*/}
                {/*                className={classes.input}*/}
                {/*                id="background-image"*/}
                {/*                onChange={updateBackground}*/}
                {/*                type="file"*/}
                {/*            />*/}
                {/*        </FormControl>*/}
                {/*    </>*/}
                {/*): null }*/}
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    checked={allowComments}
                    control={<Switch />} label={t('Allow comments on Instagram')}
                    onChange={(e: any) => {setAllowComments( e.target.checked )}}
                    // disabled={[ChannelStatus.STARTING, ChannelStatus.STOPPING, ChannelStatus.LIVE].includes(channel.status) || false} --> This is only important on IGTV
                />
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel checked={saveLive} control={<Switch />} label={t('Save and post live recording to Instagram')} onChange={(e: any) => {setSaveLive( e.target.checked )}} />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        channels: state.channels,
        streamTargets: state.streamTargets,
        profile: state.user.profile,
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        updateChannelSettingsRequest: (channel: Channel, settings: InstagramChannelSettings) => dispatch(updateChannelSettingsRequest(channel, settings)),
        toggleComments: (commentsRequest: Live4tvLiveStreamRequest) => dispatch(toggleComments(commentsRequest)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstagramSettings));
