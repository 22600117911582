import {types} from "./eventsUIActions";
import {EventsUIState, LiveEvent} from "./EventsDTO";

const initialState: EventsUIState = {
    isLoading: false,
    isCreateSuccess: false,
    isUpdateSuccess: false,
    error: '',
}

function eventsUI(state: EventsUIState = initialState, action: { type: string, payload: boolean|string|LiveEvent }) {
    switch (action.type) {
        case types.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case types.SET_ERROR:
            return { ...state, error: action.payload, isLoading: false, isUpdateSuccess: false, isCreateSuccess: false };
        case types.SET_CREATE_SUCCESS:
            return { ...state, isCreateSuccess: action.payload, isLoading: false }
        case types.SET_UPDATE_SUCCESS:
            return { ...state, isUpdateSuccess: action.payload, isLoading: false }
        case types.SET_LIVE_EVENT:
            return { ...state, liveEvent: action.payload }
        case types.RESET_UI:
            return initialState;
        default:
            return state;
    }
}

export default eventsUI;
