import {StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {addNewStreamTarget} from "../StreamTarget/streamTargetActions";
import {connect} from "react-redux";
import {withStyles} from "@mui/styles";
import styles from "./StreamTargetIntegrations-jss";
import {Button, Card, CardActions, Chip} from "@mui/material";
import {Facebook, Instagram, LiveTv, YouTube} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Dispatch} from "redux";
import {SiTwitch, SiTiktok} from "react-icons/si";
import {StreamTargetIntegration} from "./streamTargetIntegrationDTO";

type Properties = {
    classes: any,
    streamTargetIntegration: StreamTargetIntegration,
    addNewStreamTarget: Function,
}

function StreamIntegrationCard(props: Properties) {
    const {
        classes,
        streamTargetIntegration,
        addNewStreamTarget,
    } = props;

    const {t} = useTranslation();

    function handleAddTarget(streamTargetIntegration: StreamTargetIntegration): void {
        console.log('handleAddTarget():', streamTargetIntegration.code);

        addNewStreamTarget(streamTargetIntegration);
    }

    function getIconForTarget(targetName: string) {
        switch (targetName) {
            case StreamTargetType.INSTAFEED:
            case StreamTargetType.INSTAGRAM:
                return <Instagram />
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.YOUTUBE:
                return <YouTube />
            case StreamTargetType.TWITCH:
                return <SiTwitch />
            case StreamTargetType.TIKTOK:
                return <SiTiktok />
            default:
                return <LiveTv />
        }
    }

    return (
        <Card className={classes.root}>
            <CardActions>
                <Button
                    className={classes.button}
                    variant="text"
                    color="inherit"
                    onClick={ () => handleAddTarget(streamTargetIntegration) }
                    size="large"
                    startIcon={getIconForTarget(streamTargetIntegration.code)}
                >{t("Add")} {t(streamTargetIntegration.code)} { streamTargetIntegration.code === StreamTargetType.TIKTOK ?
                    <>
                        &nbsp;&nbsp;
                        <Chip size="small" label={ t('PRO') } color="primary" />
                    </>
                    : null }</Button>
            </CardActions>
        </Card>
    );
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        addNewStreamTarget: (streamTargetIntegration: StreamTargetIntegration) => dispatch(addNewStreamTarget(streamTargetIntegration)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(StreamIntegrationCard));
