import {
    Button,
    Chip,
    CircularProgress,
    Divider, Grid, IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Add, Delete, Facebook, Instagram, LiveTv, PlayArrow, Stop, YouTube} from "@mui/icons-material";
import {
    LiveStreamRequest,
    StreamTarget,
    StreamTargetList,
    StreamTargetStatus,
    StreamTargetType
} from "../StreamTarget/streamTargetDTO";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";
import {fetchStreamTargetIntegrations} from "../StreamTargetIntegration/streamTargetIntegrationActions";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {streamTargetService} from "../../services/stream-target";
import {
    addNewStreamTargetToChannelRequest,
    addStreamTargetToChannelRequest,
    removeStreamTargetFromChannelRequest, updateChannelStreamTargetUuidRequest
} from "./channelActions";
import {Channel, ChannelMap, ChannelStatus, ChannelStreamTargets} from "./ChannelDTO";
import {LiveEvent} from "../Events/EventsDTO";
import classNames from "classnames";
import {createAndStartLiveStreamRequest, stopLiveStreamRequest} from "../StreamTarget/streamTargetActions";
import {Profile} from "../User/userDTO";
import styles from "./ChannelControl-jss";
import ChannelControlStartButton from "./ChannelControlStartButton";
import useFeatureFlags from "../FeatureFlag/useFeatureFlags";
import {withStyles} from "@mui/styles";
import {SiTwitch, SiTiktok} from "react-icons/si";
import {v4 as uuidv4} from "uuid";
import {
    Live4tvLiveStreamRequest,
    isInstanceOfLive4tvApiStreamTarget, Live4tvListStreamTargetsRequest,
} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {
    createAndStartLive4tvApiLiveStream, listTargets,
    stopLive4tvApiLiveStream
} from "../StreamTargetLive4tvApi/live4tvApiActions";
import {useNavigate} from "react-router-dom";

type Properties = {
    classes: any,
    liveEvent: LiveEvent,
    streamTargets: StreamTargetList,
    channels: ChannelMap,
    profile: Profile,
    streamTargetIntegrations: Array<StreamTargetIntegration>,
    fetchStreamTargetIntegrations: Function,
    addNewStreamTargetToChannelRequest: Function,
    updateChannelStreamTargetUuidRequest: Function,
    addStreamTargetToChannelRequest: Function,
    removeStreamTargetFromChannelRequest: Function,
    createAndStartLiveStreamRequest: Function,
    stopLiveStreamRequest: Function,
    createAndStartLive4tvApiLiveStream: Function,
    stopLive4tvApiLiveStream: Function,
    listTargets: Function,
    setError: Function,
}

function ChannelControl(props: Properties) {
    const {
        classes,
        liveEvent,
        streamTargets,
        channels,
        profile,
        streamTargetIntegrations,
        fetchStreamTargetIntegrations,
        addNewStreamTargetToChannelRequest,
        updateChannelStreamTargetUuidRequest,
        addStreamTargetToChannelRequest,
        removeStreamTargetFromChannelRequest,
        createAndStartLiveStreamRequest,
        stopLiveStreamRequest,
        createAndStartLive4tvApiLiveStream,
        stopLive4tvApiLiveStream,
        listTargets,
        setError,
    } = props;

    const [addStreamTargetMenuEl, setAddStreamTargetMenuEl] = useState(null);
    const isAddStreamTargetMenuOpen = Boolean(addStreamTargetMenuEl);

    const [channel, setChannel] = useState<Channel>();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [flagGoLive] = useFeatureFlags(profile.token, 'app_can_go_live');

    useEffect(() => {
        listTargets({
            profile,
        });
        fetchStreamTargetIntegrations(profile.token);
        updateChannelStreamTargetUuidRequest(channels[liveEvent.code], `${liveEvent.code}_${uuidv4()}`);
    }, []);

    useEffect(() => {
        console.debug('channels', channels);
        setChannel(channels[liveEvent.code]);
        cleanDeletedStreamTargets();
    }, [channels]);

    const cleanDeletedStreamTargets = () => {
        if (channels[liveEvent.code]) {
            Object.keys(channels[liveEvent.code].streamTargets).forEach((key: string) => {
                if (streamTargets[key] === undefined) {
                    removeStreamTargetFromChannelRequest(channels[liveEvent.code], channels[liveEvent.code].streamTargets[key]);
                }
            });
        }
    }

    const handleAddStreamTarget = (streamTarget: StreamTarget) => {
        console.debug('handleAddStreamTarget', streamTarget);
        setAddStreamTargetMenuEl(null);
        addStreamTargetToChannelRequest(channel, streamTarget);
    }

    const handleAddStreamTargetAccount = (streamTargetIntegration: StreamTargetIntegration) => {
        console.debug('handleAddStreamTargetAccount', streamTargetIntegration);
        setAddStreamTargetMenuEl(null);
        addNewStreamTargetToChannelRequest(channel, streamTargetIntegration);
    }

    const listStreamTargetMenuItems = () => {
        let targets: Array<any> = [];
        Object.keys(streamTargets).forEach((key: string) => {
            let target: StreamTarget = streamTargets[key];
            if (channel !== undefined
                && channel.streamTargets !== undefined
                && channel.streamTargets[target.key] === undefined
            ) {
                const Icon = streamTargetService.getIconForTarget(target.type);
                targets.push(<MenuItem key={target.key} onClick={() => handleAddStreamTarget(target)}>
                    <ListItemIcon><Add fontSize="small" /></ListItemIcon>
                    <ListItemIcon><Icon fontSize="small" /></ListItemIcon>
                    <ListItemText>{target.title}</ListItemText>
                </MenuItem>)
            }
        });

        return targets;
    }

    const listStreamTargetIntegrationsMenuItems = () => {
        let  integrations: Array<any> = [];
        streamTargetIntegrations.forEach((streamTargetIntegration: StreamTargetIntegration) => {
            if (streamTargetIntegration.code === 'instagram' && profile.email === 'facebook.user@facebook.com') {
            } else {
                integrations.push(<MenuItem key={streamTargetIntegration.code} onClick={() => handleAddStreamTargetAccount(streamTargetIntegration)}>
                    <ListItemIcon><Add fontSize="small" /></ListItemIcon>
                    <ListItemText>{t('Add new {{integration}}', {integration: t(streamTargetIntegration.code)})}</ListItemText>
                    { streamTargetIntegration.code === StreamTargetType.TIKTOK ?
                        <>
                            &nbsp;&nbsp;
                            <Chip size="small" label={ t('PRO') } color="primary" />
                        </>
                        : null }
                </MenuItem>)
            }
        });

        return integrations;
    }

    function getStatusChip(status: StreamTargetStatus) {
        const label = t(status) || '';

        switch (status) {
            case StreamTargetStatus.IDLE:
            case StreamTargetStatus.STOPPING:
            case StreamTargetStatus.STARTING:
                return <Chip size="small" label={label} />
            case StreamTargetStatus.LIVE:
                return <Chip size="small" label={label} color="primary" />
            case StreamTargetStatus.START_FAILED:
            case StreamTargetStatus.STOP_FAILED:
                return <Chip size="small" label={label} color="secondary" />
            default:
                return <></>;
        }

    }

    function getIconForTarget(targetName: string) {
        switch (targetName) {
            case StreamTargetType.INSTAFEED:
            case StreamTargetType.INSTAGRAM:
                return <Instagram />
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.YOUTUBE:
                return <YouTube />
            case StreamTargetType.TWITCH:
                return <SiTwitch />
            case StreamTargetType.TIKTOK:
                return <SiTiktok />
            default:
                return <LiveTv />
        }
    }

    function handleDelete(streamTarget: StreamTarget) {
        console.debug('handleDelete', streamTarget);
        removeStreamTargetFromChannelRequest(channel, streamTarget);
    }

    async function startLive(streamTarget: StreamTarget) {
        setError(undefined);

        let targets: ChannelStreamTargets = {};
        if (channel) {
            targets = channel.streamTargets;
        }

        const flag = await flagGoLive.validate({
            streamTargets: targets,
        });
        if (!flag.is_active) {
            setError(t('app_can_go_live.' + flag.reason, Object.assign({}, {interpolation: {escapeValue: false}}, flag.additional_data)));

            if (flag.reason === 'feature_flag.user.has_no_active_plan') {
                navigate('/plan');
                return;
            }

            return;
        }

        if (channel) {
            if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                createAndStartLive4tvApiLiveStream({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent,
                    streamTargets: [streamTarget],
                    settings: channel.settings,
                })
            } else { // @todo: remove this else when live4tv api is released for all social networks
                createAndStartLiveStreamRequest({
                    token: profile.token,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent: liveEvent,
                    streamTarget: streamTarget,
                    settings: channel.settings,
                });
            }
        }
    }

    function stopLive(streamTarget: StreamTarget) {
        if (channel) {
            if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                stopLive4tvApiLiveStream({
                    profile,
                    liveStreamUuid: channel.liveStreamUuid,
                    streamTargets: [streamTarget],
                    settings: channel.settings,
                    liveEvent,
                })
            } else {
                stopLiveStreamRequest({
                    token: profile.token,
                    liveStreamUuid: channel.liveStreamUuid,
                    liveEvent: liveEvent,
                    streamTarget: streamTarget,
                    settings: channel.settings,
                });
            }
        }
    }

    function getChannelTargets(): Array<JSX.Element> {
        let targets: Array<any> = [];

        if (channel !== undefined) {
            Object.keys(channel.streamTargets).forEach((channelStreamTargetKey: string) => {
                let streamTarget: StreamTarget = streamTargets[channelStreamTargetKey];

                targets.push(<TableRow key={ channelStreamTargetKey }>
                    <TableCell>{getStatusChip(streamTarget.status)}</TableCell>
                    <TableCell><Button size="small" color="inherit" variant="text" startIcon={getIconForTarget(streamTarget.type)}>{streamTarget.title}</Button></TableCell>
                    <TableCell>
                        {([StreamTargetStatus.IDLE, StreamTargetStatus.START_FAILED, StreamTargetStatus.STOP_FAILED].includes(streamTarget.status)) ? (
                            <Button size="small" variant="contained" color="secondary" onClick={() => startLive(streamTarget)} startIcon={<PlayArrow />}>{t('Start')}</Button>
                        ): null}

                        {([StreamTargetStatus.STARTING, StreamTargetStatus.STOPPING].includes(streamTarget.status)) ? (
                            <CircularProgress />
                        ): null }

                        {(StreamTargetStatus.LIVE === streamTarget.status) ? (
                            <Button size="small" variant="contained" color="secondary" onClick={() => stopLive(streamTarget)} startIcon={<Stop />}>{t('Stop')}</Button>
                        ): null}
                    </TableCell>
                    <TableCell>
                        <IconButton size="small" color="inherit" aria-label={t('Delete')} onClick={ () => handleDelete(streamTarget) }>
                            <Delete />
                        </IconButton>
                    </TableCell>
                </TableRow>)
            });
        }

        return targets;
    }

    return (
        <Grid container item direction="row" className={classNames(classes.root)} spacing={2}>
            <Grid item xs={12} container justifyContent="flex-start">
                <Button
                    id="add-stream-target-button"
                    color="inherit"
                    aria-controls={isAddStreamTargetMenuOpen ? 'stream-target-options-list' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isAddStreamTargetMenuOpen ? 'true' : undefined}
                    onClick={(e: any) => setAddStreamTargetMenuEl(e.currentTarget)}
                >
                    <Add /> {t('Add target')}
                </Button>

                <Menu
                    id="stream-target-options-list"
                    anchorEl={addStreamTargetMenuEl}
                    open={isAddStreamTargetMenuOpen}
                    onClose={() => setAddStreamTargetMenuEl(null)}
                    MenuListProps={{
                        'aria-labelledby': 'add-stream-target-button',
                    }}
                >
                    { listStreamTargetMenuItems() }

                    <Divider />

                    { listStreamTargetIntegrationsMenuItems() }

                </Menu>
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getChannelTargets()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end" className={classNames(classes.addTargetMenuButton)}>
                <ChannelControlStartButton liveEvent={liveEvent} setError={setError} />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        streamTargets: state.streamTargets,
        streamTargetIntegrations: state.streamTargetIntegrations,
        channels: state.channels,
        profile: state.user.profile,
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        fetchStreamTargetIntegrations: (apiToken: string) => dispatch(fetchStreamTargetIntegrations(apiToken)),
        addStreamTargetToChannelRequest: (channel: Channel, streamTarget: StreamTarget) => dispatch(addStreamTargetToChannelRequest(channel, streamTarget)),
        removeStreamTargetFromChannelRequest: (channel: Channel, streamTarget: StreamTarget) => dispatch(removeStreamTargetFromChannelRequest(channel, streamTarget)),
        addNewStreamTargetToChannelRequest: (channel: Channel, streamTargetIntegration: StreamTargetIntegration) => dispatch(addNewStreamTargetToChannelRequest(channel, streamTargetIntegration)),
        updateChannelStreamTargetUuidRequest: (channel: Channel, uuid: string) => dispatch(updateChannelStreamTargetUuidRequest(channel, uuid)),
        createAndStartLiveStreamRequest: (request: LiveStreamRequest) => dispatch(createAndStartLiveStreamRequest(request)),
        stopLiveStreamRequest: (request: LiveStreamRequest) => dispatch(stopLiveStreamRequest(request)),
        createAndStartLive4tvApiLiveStream: (createAndStartLiveRequest: Live4tvLiveStreamRequest) => dispatch(createAndStartLive4tvApiLiveStream(createAndStartLiveRequest)),
        stopLive4tvApiLiveStream: (stopLiveRequest: Live4tvLiveStreamRequest) => dispatch(stopLive4tvApiLiveStream(stopLiveRequest)),
        listTargets: (payload: Live4tvListStreamTargetsRequest) => dispatch(listTargets(payload)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChannelControl));
