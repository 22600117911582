import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        minHeight: '50vh',
        backgroundColor: '#fff',
    },
    paperForm: {
        padding: theme.spacing(5),
    },
    alert: {
        marginBottom: theme.spacing(2),
    }
});

export default styles;
