import * as Sentry from "@sentry/browser";
import { CaptureConsole } from '@sentry/integrations';
import {configureScope} from "@sentry/browser";

const sentryDsn = require('config/app.config').sentryDsn;

function useCrashReport() {
    if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
        Sentry.init({
            dsn: sentryDsn,
            integrations: [
                new CaptureConsole({
                    levels: ['error']
                })
            ],
            release: process.env.REACT_APP_VERSION,
            environment: process.env.REACT_APP_ENVIRONMENT,
            maxBreadcrumbs: 50,
        });
    }

    return {
        setUser: (id) => {
            configureScope((scope) => {
                scope.setUser({ id: id });
            });
        }
    };
}

export default useCrashReport;
