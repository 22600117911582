const styles = () => ({
    root: {

    },
    tableCellIcon: {
        width: '5%'
    },
    tableCellKey: {
        width: '90%'
    },
    tableCellDelete: {
        width: '5%'
    }
});

export default styles;
