import { types } from "./userActions";
import {Profile} from "./userDTO";

export function user(state: {profile: null|Profile} = { profile: null }, action: any) {
    switch (action.type) {
        case types.SET_USER_PROFILE:
            return { ...state, profile: action.profile };
        default:
            return state;
    }
}

export function auth(state: {isLoggedIn: boolean} = { isLoggedIn: false }, action: any) {
    switch (action.type) {
        case types.SET_SIGNED_IN:
            return { ...state, isLoggedIn: action.isLoggedIn };
        default:
            return state;
    }
}
