import {useTranslation} from "react-i18next";
import {Alert, Grid, Typography} from "@mui/material";
import StreamTargetDialogWidget from "../../StreamTargetDialog/StreamTargetDialogWidget";
import {withStyles} from "@mui/styles";
import styles from "./LiveEventStreamTarget-jss";
import {connect} from "react-redux";
import ChannelControl from "../../Channel/ChannelControl";
import {LiveEvent} from "../../Events/EventsDTO";
import {useState} from "react";
import classNames from "classnames";

type Properties = {
    classes: any,
    liveEvent: LiveEvent,
}

function LiveEventStreamTarget(props: Properties) {
    const {
        classes,
        liveEvent,
    } = props;

    const [error, setError] = useState();

    const {t} = useTranslation();

    return (
        <Grid
            item
            container
            direction="column"
            spacing={2}
        >

            <Grid item>
                <Typography gutterBottom variant="h5">
                    {t("Stream destinations")}
                </Typography>
            </Grid>

            <Grid item>
                {error ? (
                    <Alert severity="warning" className={classNames(classes.alert)}>
                        <div dangerouslySetInnerHTML={{ __html: error }}></div>
                    </Alert>
                ) : null }
            </Grid>

            <Grid item container>
                <ChannelControl liveEvent={liveEvent} setError={setError} />
            </Grid>

            <StreamTargetDialogWidget />
        </Grid>
    )
}

export default withStyles(styles)(connect()(LiveEventStreamTarget));
