import {put, take, takeEvery, select, all} from "redux-saga/effects";
import {addStreamTargetToChannelRequest, removeStreamTargetFromChannelRequest, types} from "./channelActions";
import {Channel, ChannelMap} from "./ChannelDTO";
import {addNewStreamTarget, resetStreamTargetStatus} from "../StreamTarget/streamTargetActions";
import {StreamTarget} from "../StreamTarget/streamTargetDTO";
import {types as streamTargetActionTypes} from "../StreamTarget/streamTargetActions";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";

function* channelSaga() {
    yield takeEvery(types.ADD_NEW_STREAM_TARGET_TO_CHANNEL_REQUEST, addNewStreamTargetToChannelRequested)
    yield takeEvery(streamTargetActionTypes.REMOVE_STREAM_TARGET, removeStreamTargetFromAllChannelsRequest)
    yield takeEvery(types.ADD_STREAM_TARGET_TO_CHANNEL_REQUEST, addStreamTargetToChannelRequested);
}

function* addNewStreamTargetToChannelRequested(action: {type: string, channel: Channel, streamTargetIntegration: StreamTargetIntegration}) {
    yield put(addNewStreamTarget(action.streamTargetIntegration));

    let targetAction: {type: string, streamTarget: StreamTarget};
    while (targetAction = yield take(streamTargetActionTypes.ADD_STREAM_TARGET)) {
        console.debug('[SAGA]', 'addNewStreamTargetToChannelRequested', targetAction);
        yield put(addStreamTargetToChannelRequest(action.channel, targetAction.streamTarget))
    }
}

function* addStreamTargetToChannelRequested(action: {type: string, channel: Channel, streamTarget: StreamTarget}) {
    console.debug('[SAGA]', 'addStreamTargetToChannelRequested', action);
    yield put(resetStreamTargetStatus(action.streamTarget));
}

function* removeStreamTargetFromAllChannelsRequest(action: {type: string, streamTarget: StreamTarget}) {
    let channels: ChannelMap = yield select((state) => state.channels );
    yield all(Object.keys(channels).map((key: string) =>
        put(removeStreamTargetFromChannelRequest(channels[key], action.streamTarget))
    ));
}

export default channelSaga;
