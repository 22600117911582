import {httpClient} from "../../services/live4tv-client/http-client";
import {StreamTargetIntegration} from "./streamTargetIntegrationDTO";

export interface SocialNetworksResponse {
    count: number;
    social_networks: Array<StreamTargetIntegration>;
}

export const streamTargetIntegrationAPI = {
    async getStreamNetworks(apiToken: string): Promise<Array<StreamTargetIntegration>> {
        const url = '/api/v2/stream-networks';
        const response = await httpClient.get<Array<StreamTargetIntegration>>(url, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        });

        return response.data;
    },

    async getLive4tvSocialNetworks(apiToken: string): Promise<Array<StreamTargetIntegration>> {
        const url = '/api/v2/stream-network/proxy?cmd=social-networks';
        const response = await httpClient.get<SocialNetworksResponse>(url, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        });

        return response.data.social_networks;
    }
}
