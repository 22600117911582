import {useTranslation} from "react-i18next";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {Profile} from "../User/userDTO";
import {useEffect} from "react";
import styles from "./PlanPage-jss";
import {Dispatch} from "redux";
import Page from "../../components/Page/Page";
import live4tvPlanService from "./live4tvPlanService";
import {useNavigate} from "react-router-dom";
import useGTM from "../../packages/useGTM/useGTM";

type Properties = {
    classes: any,
    profile: Profile,
}

function SubscriptionSuccessPage(props: Properties) {
    const {
        classes,
        profile,
    } = props;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const gtm = useGTM({profile});

    useEffect(() => {
        checkActivePlan();
    }, []);

    const checkActivePlan = async () => {
        try {
            const activePlans = await live4tvPlanService.getActivePlan(profile.token);
            if (activePlans.data.length > 0) {
                await gtm.trackEvent('plan_signed', {
                    value: activePlans.data[0].price,
                    currency: activePlans.data[0].currency || 'BRL',
                });
                navigate('/plan');
            }
        } catch (e) {
            setTimeout(checkActivePlan, 2000);
        }
    }

    return (
        <Page desc="" title={t('Successful Subscription')}>
            <Grid container direction="column" spacing={5} alignItems="center">
                <Grid item container alignItems="center" direction="column" spacing={3} xs={12}>
                    <Grid item xs={12} alignItems="center">
                        <CircularProgress />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" component="div">
                            {t('Please wait while we are processing your subscription')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SubscriptionSuccessPage));
