import {connect} from "react-redux";
import {Button, Grid, Typography} from "@mui/material";
import {ExitToApp} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {setIsLoggedIn, setUserProfile} from "../User/userActions";
import {clearStreamTargets} from "../StreamTarget/streamTargetActions";
import {Dispatch} from "redux";
import {Profile} from "../User/userDTO";
import {clearComments} from "../Comments/commentsActions";
import {useNavigate} from "react-router-dom";
import Page from "../../components/Page/Page";

type Properties = {
    profile: Profile,
    clearComments: Function,
    clearStreamTargets: Function,
    setIsLoggedIn: Function,
    setUserProfile: Function,
}

function ConfigsPage(props: Properties) {
    const navigate = useNavigate();

    const {t} = useTranslation();

    const handleExit = (e: any) => {
        console.log('handleExit()');
        e.preventDefault();
        props.clearComments();
        props.clearStreamTargets();
        props.setIsLoggedIn(false);
        props.setUserProfile({});
        navigate('/');
    }

    return (
        <Page title={t('Configurations')} desc="">
            <Grid container direction="column" spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        { t('Login') }
                    </Typography>
                    <br />
                    <Button variant="contained" onClick={handleExit} startIcon={<ExitToApp />} color="secondary">
                        { t('Logout') }
                    </Button>
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
    };
};

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        setIsLoggedIn: (signedIn: boolean) => dispatch(setIsLoggedIn(signedIn)),
        setUserProfile: (profile: Profile) => dispatch(setUserProfile(profile)),
        clearComments: () => dispatch(clearComments()),
        clearStreamTargets: () => dispatch(clearStreamTargets()),
    };
}
export default (connect(mapStateToProps, mapDispatchToProps)(ConfigsPage))
