import {
    CreateLiveEventRequest,
    CreateLiveEventResult,
    DeleteEventRequest,
    LiveEventsMap,
    UpdateLiveEventRequest, UpdateLiveEventResult
} from "./EventsDTO";

export const types = {
    CREATE_EVENTS_REQUEST: "CREATE_EVENTS_REQUEST",
    CREATE_EVENTS_SUCCESS: "CREATE_EVENTS_SUCCESS",
    CREATE_EVENTS_FAIL: "CREATE_EVENTS_FAIL",

    UPDATE_EVENTS_REQUEST: "UPDATE_EVENTS_REQUEST",
    UPDATE_EVENTS_SUCCESS: "UPDATE_EVENTS_SUCCESS",
    UPDATE_EVENTS_FAIL: "UPDATE_EVENTS_FAIL",

    FETCH_EVENTS_REQUEST: "FETCH_EVENTS_REQUEST",
    FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
    FETCH_EVENTS_FAIL: "FETCH_EVENTS_FAIL",

    DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
    DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
    DELETE_EVENT_FAIL: "DELETE_EVENT_FAIL",
}

export function fetchEventsRequest(token: string) {
    return {type: types.FETCH_EVENTS_REQUEST, token}
}

export function fetchEventsSuccess(payload: LiveEventsMap) {
    return {type: types.FETCH_EVENTS_SUCCESS, payload}
}

export function fetchEventsFail(error: string) {
    return {type: types.FETCH_EVENTS_FAIL, error}
}

export function deleteEventRequest(payload: DeleteEventRequest) {
    return {type: types.DELETE_EVENT_REQUEST, payload}
}

export function deleteEventSuccess(payload: DeleteEventRequest) {
    return {type: types.DELETE_EVENT_SUCCESS, payload}
}

export function deleteEventFail(error: string) {
    return {type: types.DELETE_EVENT_FAIL, error}
}

export function createEventRequest(payload: CreateLiveEventRequest) {
    return {type: types.CREATE_EVENTS_REQUEST, payload}
}

export function createEventSuccess(payload: CreateLiveEventResult) {
    return {type: types.CREATE_EVENTS_SUCCESS, payload}
}

export function createEventFail(error: string) {
    return {type: types.CREATE_EVENTS_FAIL, error}
}

export function updateEventRequest(payload: UpdateLiveEventRequest) {
    return {type: types.UPDATE_EVENTS_REQUEST, payload}
}

export function updateEventSuccess(payload: UpdateLiveEventResult) {
    return {type: types.UPDATE_EVENTS_SUCCESS, payload}
}

export function updateEventFail(error: string) {
    return {type: types.UPDATE_EVENTS_FAIL, error}
}
