import {StreamTargetDialog} from "./streamTargetDialogDTO";
import {types} from "./streamTargetDialogActions";

const initialState: StreamTargetDialog = {
    isOpen: false,
}
function streamTargetDialog(state: StreamTargetDialog = initialState, action: {type: string, dialog: StreamTargetDialog}) {
    switch (action.type) {
        case types.OPEN_STREAM_TARGET_DIALOG:
            return Object.assign({}, state, action.dialog);
        case types.CLOSE_STREAM_TARGET_DIALOG:
            return initialState;
        default:
            return state;
    }
}

export default streamTargetDialog;
