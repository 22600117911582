const styles = () => ({
    root: {
        backgroundColor: '#fff',
        height: '100vh',
    },
    chatContainer: {
        border: '1px solid #ccc',
        padding: '10px',
        height: '70vh',
        width: '100%',
        backgroundColor: '#dcf1fe',
        overflowY: 'auto' as 'auto',
        position: 'relative' as 'relative',
    },
    message: {
        backgroundColor: '#ffffff',
        color: '#000000',
        margin: '10px',
        padding: '10px',
        borderRadius: '5px',
    },
    profilePic: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '10px',
    },
    socialIcon: {
        fontSize: '24px',
        marginRight: '5px',
    },
    iconButtons: {
        position: 'absolute' as 'absolute',
        top: '10px',
        right: '10px',
    },
});

export default styles;
