import {Theme} from "@mui/material";

const styles = (_theme: Theme) => ({
    root: {
    },
    images: {

    }
});

export default styles;
