import {LiveStream, LiveStreamMap} from "./LiveStreamDTO";
import {types} from "./liveStreamActions";

export type LiveStreamState = {
    liveStreams: LiveStreamMap,
    liveStreamDownloadData: {
        liveStream: LiveStream,
        content: string
    },
};

const initialState: LiveStreamState = {
  liveStreams: {},
  liveStreamDownloadData: {
      liveStream: {
            uuid: '',
            title: '',
            details: '',
            started_at: '',
      },
      content: ''
  },
};

function liveStreams(state: LiveStreamState = initialState, action: { type: string, payload: LiveStreamMap}) {
  switch (action.type) {
    case types.FETCH_LIVE_STREAMS_SUCCESS:
        return {
            ...state,
            liveStreams: action.payload
        };
    case types.FETCH_LIVE_STREAM_DATA_DOWNLOAD_SUCCESS:
        return {
            ...state,
            liveStreamDownloadData: action.payload
        };
    case types.FETCH_LIVE_STREAM_DATA_DOWNLOADED_SUCCESS:
        return {
            ...state,
            liveStreamDownloadData: ''
        };
    default:
      return state;
  }
}

export default liveStreams;