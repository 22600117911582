import {StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {ChannelSettings, ChannelSettingsType} from "../Channel/ChannelDTO";

export interface Comment {
    key: string,
    streamTargetType: StreamTargetType,
    time: Date,
    username: string,
    message: string,
    photo?: string,
}

export interface LiveEventCommentsMap {
    [key: string]: Array<Comment>,
}

export enum CommentsSettingsType {
    COMMENTS = "COMMENTS"
}

export interface CommentsChannelSettings extends ChannelSettings {
    type: ChannelSettingsType.COMMENTS,
    isEnabled: boolean,
}

export function isInstanceOfCommentsSettingsType(object: any): object is CommentsChannelSettings {
    return object && object.type === ChannelSettingsType.COMMENTS;
}

export enum CommentStatus {
    NOT_STARTED = "NOT_STARTED",
    STARTING = "STARTING",
    LISTENING = "LISTENING",
    START_FAILED = "START_FAILED",
    STOPPING = "STOPPING",
}
