import {call, put, select, takeEvery} from "redux-saga/effects";
import {
    createAndStartLiveStreamFail,
    startListeningToCommentsRequest,
    stopListeningToCommentsRequest,
    stopLiveStreamFail,
    types
} from "./streamTargetActions";
import {
    Live4tvStreamTarget,
    Live4tvStreamTargetConfig,
    LiveStreamRequest,
    ScreenFormat,
    StreamTarget,
    StreamTargetList,
    StreamTargetType,
} from "./streamTargetDTO";
import {LiveEvent} from "../Events/EventsDTO";
import live4tvStreamTargetsService from "./live4tvStreamTargetsService";
import {ChannelSettingsMap, ChannelSettingsType} from "../Channel/ChannelDTO";
import {CommentsSettingsType, isInstanceOfCommentsSettingsType} from "../Comments/CommentsDTO";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";
import {
    deleteLive4TvTarget,
    startListeningToLive4tvApiComments,
    types as live4tvActionTypes
} from "../StreamTargetLive4tvApi/live4tvApiActions";
import {
    isInstanceOfLive4tvApiStreamTarget,
    Live4tvRemoveStreamTargetRequest
} from "../StreamTargetLive4tvApi/live4tvApiDTO";
import {Profile} from "../User/userDTO";
import {isInstanceOfInstagramChannelSettings} from "../InstagramSettings/InstagramSettingsDTO";

const restream = require('config/app.config').restream;

function* streamTargetSaga() {
    yield takeEvery(types.ADD_STREAM_TARGET_REQUEST, addTarget);

    yield takeEvery(types.CREATE_AND_START_LIVE_STREAM_REQUEST, createAndStartLiveStreamRequested);
    yield takeEvery(types.CREATE_AND_START_LIVE_STREAM_SUCCESS, createAndStartLiveStreamSucceeded);

    yield takeEvery(types.STOP_LIVE_STREAM_REQUEST, stopLiveStreamRequested);
    yield takeEvery(types.STOP_LIVE_STREAM_SUCCESS, stopLiveStreamSucceeded);

    yield takeEvery(types.START_LISTENING_TO_COMMENTS_REQUEST, startListeningToCommentsRequested);

    yield takeEvery(types.STOP_LISTENING_TO_COMMENTS_REQUEST, stopListeningToCommentsRequested);

    yield takeEvery(types.SEND_A_COMMENT_REQUEST, sendACommentRequested);

    yield takeEvery(types.REMOVE_STREAM_TARGET, removeStreamTargetRequested);
}

function* addTarget(action: {type: string, streamTargetIntegration: StreamTargetIntegration}) {
    const actionType = (action.streamTargetIntegration.is_live4tv_api_integration)
        ? live4tvActionTypes.ADD_LIVE4TV_TARGET_REQUEST
        : 'ADD_' + action.streamTargetIntegration.code.toUpperCase() + '_TARGET_REQUEST';

    console.debug('[SAGA]', 'addTarget', action.streamTargetIntegration.code, actionType, action.streamTargetIntegration);

    yield put({type: actionType, streamTargetIntegration: action.streamTargetIntegration});
}

function* createAndStartLiveStreamRequested(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    const targetAction = action.liveStreamRequest.streamTarget.type.toUpperCase() + '_' + action.type;
    console.debug('[SAGA]', 'createAndStartLiveStreamRequested', action.liveStreamRequest.streamTarget, targetAction);

    yield put({type: targetAction, liveStreamRequest: action.liveStreamRequest});
}

function* createAndStartLiveStreamSucceeded(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    console.debug('[SAGA]', 'createAndStartLiveStreamSucceeded', action);
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    const streamTarget = streamTargets[action.liveStreamRequest.streamTarget.key];

    try {
        yield call(
            addStreamTarget,
            action.liveStreamRequest.token,
            action.liveStreamRequest.liveEvent,
            streamTarget,
            action.liveStreamRequest.settings,
        );

        action.liveStreamRequest.streamTarget = streamTarget;

        const settings = action.liveStreamRequest.settings[CommentsSettingsType.COMMENTS];
        if (settings && isInstanceOfCommentsSettingsType(settings) && settings.isEnabled) {
            if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                const profile: Profile = yield select((state) => state.user.profile);
                yield put(startListeningToLive4tvApiComments({
                    profile,
                    liveStreamUuid: action.liveStreamRequest.liveStreamUuid,
                    liveEvent: action.liveStreamRequest.liveEvent,
                    settings: action.liveStreamRequest.settings,
                    streamTargets: [streamTarget],
                }));
            } else { // @todo remove this block when everything is migrated to Live4.tv API
                yield put(startListeningToCommentsRequest(action.liveStreamRequest));
            }
        }
    } catch (e) {
        console.error('[SAGA] createAndStartLiveStreamSucceeded.error', e);
        yield put(createAndStartLiveStreamFail(action.liveStreamRequest));
    }

}

async function addStreamTarget(token: string, liveEvent: LiveEvent, streamTarget: StreamTarget, settings?: ChannelSettingsMap) {
    if (streamTarget.transmission === undefined) {
        throw new Error('Stream target had no transmission details.');
    }

    // the targetKey has to always follow the same pattern otherwise the deletion of it will fail
    const targetKey = live4tvStreamTargetsService.prepareTargetKey(streamTarget.key);

    // send to transcoder
    if (settings) {
        const instagramSettings = settings[ChannelSettingsType.INSTAGRAM];
        if ([StreamTargetType.INSTAGRAM, StreamTargetType.TIKTOK].includes(streamTarget.type)
            && instagramSettings
            && isInstanceOfInstagramChannelSettings(instagramSettings)
            && instagramSettings.screenFormat !== ScreenFormat.DEFAULT
        ) {
            const appMapping = {
                [ScreenFormat.VERTICAL_90_CLOCKWISE]: 'horizontal',
                [ScreenFormat.SCALE_CENTER]: 'center',
                [ScreenFormat.SCALE_TOP]: 'top',
            }

            await live4tvStreamTargetsService.addStreamTargetToReference(token, liveEvent.slug, {
                type: StreamTargetType.RTMP,
                access_reference: liveEvent.event_transmission_accesses[0].reference,
                configs: {
                    host: `${restream.host}/${appMapping[instagramSettings.screenFormat]}`,
                    key: liveEvent.event_transmission_accesses[0].reference,
                    name: `${targetKey}_${instagramSettings.screenFormat}`
                }
            });
        }
    }


    let targetConfigs: Live4tvStreamTargetConfig = {
        host: streamTarget.transmission.url,
        key: streamTarget.transmission.key,
        name: targetKey
    };

    if (streamTarget.transmission['username'] !== undefined) {
        targetConfigs.username = streamTarget.transmission['username'];
    }
    if (streamTarget.transmission['password'] !== undefined) {
        targetConfigs.password = streamTarget.transmission['password'];
    }

    const accessReference: string = live4tvStreamTargetsService.decideAccessReference(liveEvent, streamTarget, settings);
    const request: Live4tvStreamTarget = {
        type: streamTarget.type,
        access_reference: accessReference,
        configs: targetConfigs,
    }

    console.debug('[SAGA]', 'addStreamTarget.request', request);

    await live4tvStreamTargetsService.addStreamTargetToReference(token, liveEvent.slug, request);
}

function* stopLiveStreamRequested(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    console.debug('[SAGA]', 'stopLiveStreamRequested', action);
    const targetAction = action.liveStreamRequest.streamTarget.type.toUpperCase() + '_' + action.type;

    yield put({type: targetAction, liveStreamRequest: action.liveStreamRequest});
}

function* stopLiveStreamSucceeded(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    console.debug('[SAGA]', 'stopLiveStreamSucceeded', action);
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    const streamTarget = streamTargets[action.liveStreamRequest.streamTarget.key];

    try {
        yield call(
            deleteStreamTarget,
            action.liveStreamRequest.token,
            action.liveStreamRequest.liveEvent,
            streamTarget,
            action.liveStreamRequest.settings,
        );

        action.liveStreamRequest.streamTarget = streamTarget;

    } catch (e) {
        console.error('[SAGA] stopLiveStreamSucceeded.error', e);
        yield put(stopLiveStreamFail(action.liveStreamRequest));
    }

    const settings = action.liveStreamRequest.settings[CommentsSettingsType.COMMENTS];
    if (settings && isInstanceOfCommentsSettingsType(settings) && settings.isEnabled) {
        yield put(stopListeningToCommentsRequest(action.liveStreamRequest));
    }
}

/**
 * This method deletes the target in the streaming service.
 *
 * If it fails, it should never fail the process as it is not a critical process but rather it aims to increase
 *  the overall system's stability
 *
 * @param token
 * @param liveEvent
 * @param streamTarget
 */
async function deleteStreamTarget(token: string, liveEvent: LiveEvent, streamTarget: StreamTarget, settings?: ChannelSettingsMap) {
    if (streamTarget.transmission === undefined) {
        console.error('[SAGA] deleteStreamTarget', 'streamTarget', 'No streamTarget.transmission to delete.');
        return;
    }

    if (streamTarget.transmission.key === undefined) {
        console.error('[SAGA] deleteStreamTarget', 'streamTarget', 'No streamTarget.transmission.key to delete.');
        return;
    }

    const targetKey = live4tvStreamTargetsService.prepareTargetKey(streamTarget.key);

    if (settings) {
        const instagramSettings = settings[ChannelSettingsType.INSTAGRAM];
        if ([StreamTargetType.INSTAGRAM, StreamTargetType.TIKTOK].includes(streamTarget.type)
            && instagramSettings
            && isInstanceOfInstagramChannelSettings(instagramSettings)
            && instagramSettings.screenFormat !== ScreenFormat.DEFAULT
        ) {
            await live4tvStreamTargetsService.deleteStreamTarget(token, liveEvent.slug, {
                type: StreamTargetType.RTMP,
                access_reference: `${targetKey}_${instagramSettings.screenFormat}`,
            });
        }
    }

    const request: Live4tvStreamTarget = {
        type: streamTarget.type,
        access_reference: targetKey,
    }

    console.debug('[SAGA]', 'deleteStreamTarget.request', request);
    await live4tvStreamTargetsService.deleteStreamTarget(token, liveEvent.slug, request);
}

function* startListeningToCommentsRequested(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    console.debug('[SAGA]', 'startListeningToCommentsRequested', action);
    if (!isInstanceOfLive4tvApiStreamTarget(action.liveStreamRequest.streamTarget)) {
        const targetAction = action.liveStreamRequest.streamTarget.type.toUpperCase() + '_' + action.type;

        yield put({type: targetAction, liveStreamRequest: action.liveStreamRequest});
    }
}

function* stopListeningToCommentsRequested(action: {type: string, liveStreamRequest: LiveStreamRequest}) {
    if (!isInstanceOfLive4tvApiStreamTarget(action.liveStreamRequest.streamTarget)) {
        console.debug('[SAGA]', 'stopListeningToCommentsRequested', action);
        const targetAction = action.liveStreamRequest.streamTarget.type.toUpperCase() + '_' + action.type;

        yield put({type: targetAction, liveStreamRequest: action.liveStreamRequest});
    }
}

function* sendACommentRequested(action: {type: string, liveStreamRequest: LiveStreamRequest, message: string}) {
    if (!isInstanceOfLive4tvApiStreamTarget(action.liveStreamRequest.streamTarget)) {
        console.debug('[SAGA]', 'sendACommentRequested', action);
        const targetAction = action.liveStreamRequest.streamTarget.type.toUpperCase() + '_' + action.type;

        yield put({type: targetAction, liveStreamRequest: action.liveStreamRequest, message: action.message});
    }
}

function* removeStreamTargetRequested(action: {type: string, streamTarget: StreamTarget}) {
    const streamTarget = action.streamTarget;
    const profile: Profile = yield select((state) => state.user.profile);

    if (isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
        yield put(deleteLive4TvTarget(<Live4tvRemoveStreamTargetRequest>{
            streamTarget: streamTarget,
            profile: profile,
        }));
    }
}

export default streamTargetSaga;
