import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import {
    Button,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import classNames from "classnames";
import {Fragment, useEffect, useState} from "react";
import {Download} from "@mui/icons-material";
import {Profile} from "../User/userDTO";
import {EventsUIState} from "../Events/EventsDTO";
import {LiveStream, LiveStreamDownloadRequest, LiveStreamMap} from "./LiveStreamDTO";
import {Dispatch} from "redux";
import {withStyles} from "@mui/styles";
import styles from "../Events/ListEventsPage-jss";
import {connect} from "react-redux";
import {downloadedSuccess, fetchLiveStreamDataDownloadRequest, fetchLiveStreamsRequest} from "./liveStreamActions";
import {useNavigate} from "react-router-dom";
import {LiveStreamState} from "./liveStreams";

type Properties = {
    classes: any,
    profile: Profile,
    eventsUI: EventsUIState,
    liveStreamState: LiveStreamState,
    fetchLiveStreamsRequest: Function,
    fetchLiveStreamDataDownloadRequest: Function,
    downloadedSuccess: Function,
}

function LiveStreamDataPage(props: Properties) {
    const {
        classes,
        profile,
        eventsUI,
        liveStreamState,
        fetchLiveStreamsRequest,
        fetchLiveStreamDataDownloadRequest,
        downloadedSuccess
    } = props;
    console.log({liveStreamState})
    const liveStreams = liveStreamState.liveStreams;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        if (profile == null || profile['token'] === undefined) {
            navigate("/");
            return;
        }
        fetchLiveStreamsRequest(profile.token, profile.code);
    }, []);

    useEffect(() => {
        setLoading(eventsUI.isLoading);
    }, [eventsUI.isLoading]);

    useEffect(() => {
        if (liveStreamState.liveStreamDownloadData.content && liveStreamState.liveStreamDownloadData.content.length > 0) {
            const blob = new Blob([liveStreamState.liveStreamDownloadData.content], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const fileName = slugify(
                `${liveStreamState.liveStreamDownloadData.liveStream.title}  ${formatDateTime(liveStreamState.liveStreamDownloadData.liveStream.started_at)}`
            );
            link.href = url;
            link.setAttribute('download', `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            downloadedSuccess();
            alert(t('The file has been downloaded successfully') + `: ${fileName}`);
        }
    }, [liveStreamState.liveStreamDownloadData]);

    const slugify = (...args: (string | number)[]): string => {
        const value = args.join(' ')

        return value
            .normalize('NFD') // split an accented letter in the base letter and the acent
            .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
            .replace(/\s+/g, '-') // separator
    }

    const formatDateTime = (date: string) => {
        const dateObj = new Date(date);
        return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
    }

    const handleDownload = async (liveStream: LiveStream) => {
        fetchLiveStreamDataDownloadRequest({
            token: profile.token,
            liveStream,
        });
    }

  return (
    <Page title={t('Live Stream Historical')}>
        <Grid item container
              direction="row"
              alignItems="flex-start"
              xs={10}
              md={10}
              lg={10}>
            {isLoading ? (
                <div className={classNames(classes.loaderArea)}>
                    <LinearProgress />
                </div>
            ): (
                <>
                    <h1>{t('The latest 20 lives streams')}</h1>
                    {Object.keys(liveStreams).length > 0 ? (
                        <TableContainer>
                            <Table className={classNames(classes.table)} aria-label="live-stream table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="50%">{t('Event')}</TableCell>
                                        <TableCell width="20%">{t('Start at')}</TableCell>
                                        <TableCell width="30%"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(liveStreams).map(code => (
                                        <TableRow key={liveStreams[code].uuid}>
                                            <TableCell title={liveStreams[code].details}>{liveStreams[code].title}</TableCell>
                                            <TableCell>{formatDateTime(liveStreams[code].started_at)}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={t("Download Comments CSV")||''}>
                                                    <Button
                                                        color="inherit"
                                                        onClick={() => handleDownload(liveStreams[code])}
                                                        startIcon={<Download />}
                                                        aria-label={t('Download')}
                                                    >{t('Download Comments CSV')}</Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : <div className={classNames(classes.noEventDiv)}>{t('You still haven\'t created any live')}</div>}
                </>
            )}
        </Grid>
    </Page>
  );
}

const mapStateToProps = function (state: any) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        profile: state.user.profile,
        liveStreamState: state.liveStreams,
        eventsUI: state.eventsUI,
        liveStreamDownloadData: state.liveStreamDownloadData,
    };
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        fetchLiveStreamsRequest: (token: string, code: string ) => dispatch(fetchLiveStreamsRequest(token, code)),
        fetchLiveStreamDataDownloadRequest: (request: LiveStreamDownloadRequest) => dispatch(fetchLiveStreamDataDownloadRequest(request)),
        downloadedSuccess: () => dispatch(downloadedSuccess()),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LiveStreamDataPage));
