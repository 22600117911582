import {httpClient} from "../../services/live4tv-client/http-client";
import {Live4tvStreamTarget, ScreenFormat, StreamTarget, StreamTargetType} from "./streamTargetDTO";
import {LiveEvent} from "../Events/EventsDTO";
import {ChannelSettingsMap, ChannelSettingsType} from "../Channel/ChannelDTO";
import {isInstanceOfInstagramChannelSettings} from "../InstagramSettings/InstagramSettingsDTO";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;

const live4tvStreamTargetsService = {
    async addStreamTargetToReference(token: string, eventSlug: string, target: Live4tvStreamTarget) {
        const url = `/api/v1/events/${eventSlug}/stream-target`;

        return await httpClient.put(
            url,
            target,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        );
    },

    async deleteStreamTarget(token: string, eventSlug: string, target: Live4tvStreamTarget) {
        const url = `/api/v1/events/${eventSlug}/stream-target/${target.access_reference}`;

        return await httpClient.delete(
            url,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        );
    },

    decideAccessReference(liveEvent: LiveEvent, streamTarget: StreamTarget, settings?: ChannelSettingsMap): string {
        let accessReference: string = liveEvent.event_transmission_accesses[0].reference;

        if (settings) {
            const instagramSettings = settings[ChannelSettingsType.INSTAGRAM];
            if ([StreamTargetType.INSTAGRAM, StreamTargetType.INSTAFEED, StreamTargetType.TIKTOK].includes(streamTarget.type)
                && instagramSettings
                && isInstanceOfInstagramChannelSettings(instagramSettings)
                && instagramSettings.screenFormat !== ScreenFormat.DEFAULT
            ) {
                accessReference = liveEvent.event_transmission_accesses[0].reference + '_TRANSCODED';
            }
        }

        return accessReference;
    },

    prepareTargetKey(targetKey: string): string {
        return targetKey.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    },
}

export default live4tvStreamTargetsService;
