import {StreamTarget, StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {Profile} from "../User/userDTO";
import {LiveEvent} from "../Events/EventsDTO";
import {ChannelSettingsMap} from "../Channel/ChannelDTO";

export interface Live4tvApiStreamTarget extends StreamTarget {
    uuid: string;
}

export interface Live4tvApiAuthRequest {
    profile: Profile,
    network: StreamTargetType,
    streamTarget?: Live4tvApiStreamTarget,
}

export function isInstanceOfLive4tvApiStreamTarget(object: any): object is Live4tvApiStreamTarget {
    return object && object.isLive4tvApi === true
}

export interface Live4tvLiveStreamRequest {
    profile: Profile,
    liveStreamUuid: string,
    streamTargets: Array<Live4tvApiStreamTarget>,
    liveEvent: LiveEvent,
    settings: ChannelSettingsMap,
}

export interface Live4tvListStreamTargetsRequest {
    profile: Profile,
}

export interface Live4tvRemoveStreamTargetRequest {
    profile: Profile,
    streamTarget: Live4tvApiStreamTarget,
}
