import {httpClient} from "../../services/live4tv-client/http-client";
import {FlagParams, FlagResponse} from "./FeatureFlagDTO";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;

const live4tvStreamTargetsService = {
    async getFlag(token: string, flag: string, params: FlagParams = {}): Promise<FlagResponse> {
        const URL = `/api/v1/flags/${flag}/validate`;

        const response = await httpClient.post<FlagResponse>(
            URL,
            params,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        );

        return response.data;
    }
}

export default live4tvStreamTargetsService;
