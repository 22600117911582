import {Auth, Profile} from "./userDTO";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import useCrashReport from "../../packages/useCrashReport/useCrashReport";
import {useTranslation} from "react-i18next";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {
    Alert,
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    Link,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import classNames from "classnames";
import useGTM from "../../packages/useGTM/useGTM";
import PasswordField from "../../components/PasswordField";
import {setIsLoggedIn, setUserProfile} from "./userActions";
import {live4tvUserService} from "./live4tvUserService";
import styles from "./LoginPage-jss";

type Properties = {
    classes: any,
    auth: Auth,
    profile: Profile,
    setUserProfile: Function,
    setIsLoggedIn: Function,
}

function LoginPage(props: Properties) {
    const {
        classes,
        auth,
        profile,
        setUserProfile,
        setIsLoggedIn,
    } = props;

    const navigate = useNavigate();

    const crashReporter = useCrashReport();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const gtm = useGTM({});

    useEffect(() => {
        console.debug('LoginPage');
    }, []);

    useEffect(() => {
        console.debug('signedIn', auth.isLoggedIn);
        if (auth.isLoggedIn === true) {
            crashReporter.setUser(profile.code);
            navigate('/events');
        }
    }, [auth.isLoggedIn]);

    const forgotPassword = (event: any) => {
        event.preventDefault();
        window.open('https://www.live4.tv/en/reset-password', "_blank", "noreferrer");
    }

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await live4tvUserService.login(username, password);
            console.log('handleLogin().login', response);

            const user = await live4tvUserService.getUser(response.token);
            console.log('handleLogin().user', user);

            const profile: Profile = {
                firstname: user.firstname,
                lastnames: user.lastnames,
                code: user.code,
                email: user.email,
                username: user.username,
                token: response.token,
                roles: response.roles,
            }

            setUserProfile(profile);
            setIsLoggedIn(true);
            gtm.trackEvent('login', {
                user: {
                    id: profile.code
                },
            });
        } catch (error: any) {
            console.debug('handleLogin().error', error);
            if (error.status !== undefined && error.status !== "400") {
                console.error('handleLogin().error', error);
            }

            setFormError(t('Invalid email or password'));
        }

        setIsLoading(false);
    }

    return (
        <Grid container className={classNames(classes.root)} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={10} md={8} lg={6} container direction="column">
                <Paper className={classNames(classes.paperForm)} elevation={0} square>
                    <form onSubmit={handleLogin} className={classNames(classes.form)}>
                        {isLoading ? <LinearProgress className={classNames(classes.progressBar)}/> : (
                            <>
                                <Typography component="h1" variant="h5">
                                    {t("Login")}
                                </Typography>

                                {formError ? <Alert severity="error" className={classNames(classes.alert)}>{formError}</Alert> : ''}

                                <FormControl fullWidth className={classNames(classes.textField)}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="login"
                                        label={t("Enter with your Live4.tv email")}
                                        type="text"
                                        fullWidth
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl fullWidth className={classNames(classes.textField)}>
                                    <InputLabel htmlFor="password">{t("Enter with your Live4.tv password")}</InputLabel>
                                    <PasswordField
                                        margin="dense"
                                        id="password"
                                        value={password}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl fullWidth className={classNames(classes.textField)}>
                                </FormControl>

                                <Button fullWidth size="large" type="submit" variant="contained" color="primary"
                                        className={classNames(classes.submit)}>{t("Login")}</Button>
                            </>
                        )}
                    </form>
                </Paper>

                <Link href="#signup" color="textPrimary">
                    {t("You still have no account? Signup here!")}
                </Link>
                {/*@todo move the reset password to this app*/}
                <Link href="#" onClick={forgotPassword} color="textPrimary">
                    {t("Have you forgot your password? Reset it here!")}
                </Link>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        auth: state.auth,
        profile: state.user.profile
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setIsLoggedIn: (signedIn: boolean) => dispatch(setIsLoggedIn(signedIn)),
        setUserProfile: (profile: Profile) => dispatch(setUserProfile(profile)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
