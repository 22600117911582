import {withStyles} from "@mui/styles";
import styles from "./AppStructure-jss";
import {Box, CssBaseline} from "@mui/material";
import {Outlet} from "react-router-dom";

type Properties = {
    classes: any,
    children: any,
}

function EmptyStructure(props: Properties) {
    const {
        children,
    } = props;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Outlet />
            </Box>
        </Box>
    )
}

export default withStyles(styles)(EmptyStructure)
