import {LiveEvent} from "./EventsDTO";

export const types = {
    SET_IS_LOADING: "SET_IS_LOADING",

    SET_CREATE_SUCCESS: "SET_CREATE_SUCCESS",
    SET_UPDATE_SUCCESS: "SET_UPDATE_SUCCESS",

    SET_LIVE_EVENT: "SET_LIVE_EVENT",

    RESET_UI: "RESET_UI",

    SET_ERROR: "SET_ERROR",
}

export function resetUI() {
    return {type: types.RESET_UI};

}

export function setIsLoading(payload: boolean) {
    return {type: types.SET_IS_LOADING, payload};
}

export function setCreateSuccess(payload: boolean) {
    return {type: types.SET_CREATE_SUCCESS, payload};
}

export function setUpdateSuccess(payload: boolean) {
    return {type: types.SET_UPDATE_SUCCESS, payload};
}

export function setError(payload: string) {
    return {type: types.SET_ERROR, payload};
}

export function setLiveEvent(payload: LiveEvent) {
    return {type: types.SET_LIVE_EVENT, payload};
}
