import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import {
    createEventFail,
    createEventSuccess,
    deleteEventFail,
    deleteEventSuccess,
    fetchEventsFail,
    fetchEventsRequest,
    fetchEventsSuccess,
    types,
    updateEventFail,
    updateEventSuccess
} from "./eventsAtcions";
import {
    CreateLiveEventRequest,
    CreateLiveEventResult,
    DeleteEventRequest,
    LiveEvent,
    LiveEventsMap,
    UpdateLiveEventRequest,
    UpdateLiveEventResult
} from "./EventsDTO";
import {live4tvEventsService} from "./live4tvEventsService";
import {setCreateSuccess, setError, setIsLoading, setLiveEvent, setUpdateSuccess} from "./eventsUIActions";
import {createChannelRequest} from "../Channel/channelActions";
import {ChannelStatus} from "../Channel/ChannelDTO";
import {v4 as uuidv4} from 'uuid';

function* eventsSaga() {
    yield takeLatest(types.FETCH_EVENTS_REQUEST, fetchEventsRequested);
    yield takeEvery(types.FETCH_EVENTS_SUCCESS, fetchEventsSucceeded);
    yield takeEvery(types.FETCH_EVENTS_FAIL, fetchEventsFailed);

    yield takeLatest(types.DELETE_EVENT_REQUEST, deleteEventRequested);
    yield takeEvery(types.DELETE_EVENT_SUCCESS, deleteEventSucceeded)
    yield takeEvery(types.DELETE_EVENT_FAIL, deleteEventFailed);

    yield takeLatest(types.CREATE_EVENTS_REQUEST, createEventRequested);
    yield takeEvery(types.CREATE_EVENTS_SUCCESS, createEventSucceeded);
    yield takeEvery(types.CREATE_EVENTS_FAIL, createEventFailed);

    yield takeLatest(types.UPDATE_EVENTS_REQUEST, updateEventRequested);
    yield takeEvery(types.UPDATE_EVENTS_SUCCESS, updateEventSucceeded);
    yield takeEvery(types.UPDATE_EVENTS_FAIL, updateEventFailed);
}

function* fetchEventsRequested(action: {type: string, token: string}) {
    console.debug('[SAGA]', 'fetchEvents');
    yield put(setIsLoading(true));

    try {
        const events:LiveEventsMap = yield call(fetchEvents, action.token);
        console.debug('[SAGA]', 'fetchEvents.succeeded', events);
        yield put(fetchEventsSuccess(events));
    } catch (e: any) {
        console.error('[SAGA]', 'fetchEventsRequest.error', e);
        yield put(fetchEventsFail(e.toString()));
    }
}

async function fetchEvents(token: string) {
    const response = await live4tvEventsService.getUserEvents(token);

    let items: LiveEventsMap = {};
    if (response.data !== undefined) {
        response.data.forEach((el: LiveEvent) => {
            items[el.code] = el;
        })
    }

    return items;
}

function* fetchEventsSucceeded(action: {type: string, payload: LiveEventsMap}) {
    console.debug('[SAGA]', 'fetchEventsSucceeded', action);
    const eventKeys = Object.keys(action.payload);
    for (let i = 0; i < eventKeys.length; i++) {
        const liveEvent = action.payload[eventKeys[i]];
        yield put(createChannelRequest({
            key: liveEvent.code,
            name: liveEvent.title,
            liveStreamUuid: `${liveEvent.code}_${uuidv4()}`,
            status: ChannelStatus.IDLE,
            streamTargets: {},
            settings: {},
        }));
    }

    yield put(setIsLoading(false));
}

function* fetchEventsFailed() {
    yield put(setIsLoading(false));
}

function* deleteEventRequested(action: {type: string, payload: DeleteEventRequest}) {
    console.debug('[SAGA]', 'deleteEventRequested');
    yield put(setIsLoading(true));

    try {
        yield call(deleteEvent, action.payload);
        yield put(deleteEventSuccess(action.payload));
    } catch (e: any) {
        console.error('[SAGA]', 'deleteEventRequested.error', e);
        yield put(deleteEventFail(e.toString()));
    }
}

async function deleteEvent(request: DeleteEventRequest) {
    await live4tvEventsService.deleteLiveEvent(request);
}

function* deleteEventSucceeded(action: {type: string, payload: DeleteEventRequest}) {
    yield put(setIsLoading(false));
    yield put(fetchEventsRequest(action.payload.token));
}

function* deleteEventFailed() {
    yield put(setIsLoading(false));
}

function* createEventRequested(action: {type: string, payload: CreateLiveEventRequest}) {
    console.debug('[SAGA]', 'createEventRequested', action.payload);
    yield put(setIsLoading(true));

    try {
        const liveEvent: LiveEvent = yield call(createEvent, action.payload);
        const result: CreateLiveEventResult = {
            token: action.payload.token,
            event: liveEvent,
        }
        yield put(createEventSuccess(result));
    } catch (e: any) {
        console.error('[SAGA]', 'createEventRequested.error', e.toString());
        yield put(createEventFail('There was an error while creating your event. Please, try again.'));
    }
}

async function createEvent(request: CreateLiveEventRequest): Promise<LiveEvent> {
    return await live4tvEventsService.createLiveEvent(request);
}

function* createEventSucceeded(action: {type: string, payload: CreateLiveEventResult}) {
    console.debug('[SAGA]', 'createEventSucceeded', action.payload);
    const liveEvent: LiveEvent = action.payload.event;
    yield put(createChannelRequest({
        key: liveEvent.code,
        name: liveEvent.title,
        status: ChannelStatus.IDLE,
        liveStreamUuid: `${liveEvent.code}_${uuidv4()}`,
        streamTargets: {},
        settings: {},
    }));
    yield put(fetchEventsRequest(action.payload.token));
    yield put(setLiveEvent(action.payload.event));
    yield put(setCreateSuccess(true));
}

function* createEventFailed(action: {type: string, payload: string}) {
    yield put(setError(action.payload))
}

function* updateEventRequested(action: {type: string, payload: UpdateLiveEventRequest}) {
    console.debug('[SAGA]', 'updateEventRequested', action.payload);
    yield put(setIsLoading(true));
    try {
        const liveEvent: LiveEvent = yield call(updateEvent, action.payload);
        const result: UpdateLiveEventResult = {
            token: action.payload.token,
            event: liveEvent,
        }
        yield put(updateEventSuccess(result));
    } catch (e: any) {
        console.error('[SAGA]', 'updateEventRequested.error', e);
        yield put(updateEventFail('There was an error while updating your event. Please, try again.'))
    }
}

async function updateEvent(request: UpdateLiveEventRequest): Promise<LiveEvent> {
    return await live4tvEventsService.updateLiveEvent(request);
}

function* updateEventSucceeded(action: {type: string, payload: UpdateLiveEventResult}) {
    console.debug('[SAGA]', 'updateEventSucceeded', action.payload);
    yield put(fetchEventsRequest(action.payload.token));
    yield put(setLiveEvent(action.payload.event));
    yield put(setUpdateSuccess(true));
}

function* updateEventFailed(action: {type: string, payload: string}) {
    yield put(setError(action.payload));
}

export default eventsSaga;
