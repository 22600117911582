import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {Alert, Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {Profile} from "../User/userDTO";
import {Fragment, useEffect, useState} from "react";
import styles from "./PlanPage-jss";
import {Dispatch} from "redux";
import Page from "../../components/Page/Page";
import {GrConfigure} from "react-icons/gr";
import live4tvPlanService, {Subscription} from "./live4tvPlanService";

const stripe = require('config/app.config').stripe;

type Properties = {
    classes: any,
    profile: Profile,
}

function PlanPage(props: Properties) {
    const {
        classes,
        profile,
    } = props;

    const [activePlans, setActivePlans] = useState<Array<Subscription>>([]);

    const {t} = useTranslation();

    useEffect(() => {
        checkActivePlan();
    }, []);

    const checkActivePlan = async () => {
        try {
            const activePlans = await live4tvPlanService.getActivePlan(profile.token);
            setActivePlans(activePlans.data);
        } catch (e) {}
    }

    const handleManagePlan = () => {
        window.open(`${stripe.dashboard_url}?prefilled_email=${profile.email}`, '_blank');
    }

    const stripeComponent = () => {
        let clientProfileId = profile.code;
        if (window.Rewardful && window.Rewardful.referral) {
            clientProfileId = window.Rewardful.referral;
        }
        // @ts-ignore
        return activePlans.length > 0 ? null : <stripe-pricing-table pricing-table-id={stripe.plan_table_id} publishable-key={stripe.public_key} client-reference-id={clientProfileId} customer-email={profile.email}></stripe-pricing-table>;
    }

    return (
        <Page desc="" title={t('Subscription')}>
            <Grid container direction="column" spacing={5} alignItems="center">

                {activePlans.length > 0 ? (
                    <Grid item container alignItems="center" direction="row" spacing={3} xs={12}>
                        {activePlans.map((plan: Subscription) => (
                            <Grid item xs={4}>
                                <Card key={plan.code}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {t('Subscription')}
                                        </Typography>

                                        <Typography sx={{mb: 1.5}} color="text.secondary">
                                            {t(plan.status)}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => handleManagePlan() }
                                            startIcon={<GrConfigure />}
                                        >
                                            {t('Manage Subscription')}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid item xs={12}>
                            <Alert severity="success" className={classNames(classes.alert)}>
                                {t("You need an active Live4.tv account to start streaming")}
                            </Alert>
                        </Grid>

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item container direction="column" spacing={3} xs={12}>
                            {stripeComponent()}
                        </Grid>
                    </Fragment>
                )}
            </Grid>

        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PlanPage));
