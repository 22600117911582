import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    root: {

    },
    progressBar: {
        width: "500px", // @todo change this fixed value
        marginTop: theme.spacing(5),
    },
    button: {
        height: "60px"
    }
});

export default styles;
