export enum StudioRightSection {
    COMMENTS = "COMMENTS",
    LIVE_SETTINGS = "LIVE_SETTINGS",
    WIDGETS_AND_OVERLAYS = "WIDGETS_AND_OVERLAYS",
    VIDEO_PREVIEW = "VIDEO_PREVIEW",
}

export interface StudioRightMenuItem {
    key: StudioRightSection,
    icon: any,
}
