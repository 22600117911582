import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import styles from "./Live4tvApi-jss";
import {cancelAddLive4tvApi, loginToLive4tvApi, resetIntegration} from "./live4tvApiActions";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    LinearProgress
} from "@mui/material";
import {Facebook, Instagram, LiveTv, YouTube} from "@mui/icons-material";
import {StreamTargetList, StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {Live4tvApiAuthRequest} from "./live4tvApiDTO";
import {Profile} from "../User/userDTO";
import {State} from "./live4tvApiReducer";
import {SiTiktok, SiTwitch} from "react-icons/si";

type Properties = {
    classes: any,
    open: boolean,
    streamTargetIntegration: StreamTargetIntegration,
    streamTargets: StreamTargetList,
    profile: Profile,
    live4tvApiIntegration: State,
    onClose: Function,
    loginToLive4tvApi: Function,
    cancelAddLive4tvApi: Function,
    resetIntegration: Function,
}

function Live4tvApi(props: Properties) {
    const {
        classes,
        open,
        streamTargetIntegration,
        profile,
        live4tvApiIntegration,
        onClose,
        loginToLive4tvApi,
        cancelAddLive4tvApi,
        resetIntegration,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string|null>('');
    const [infoMessage, setInfoMessage] = useState<string|null>('');

    const {t} = useTranslation();

    useEffect(() => {
        console.debug('Live4tvApi.tsx live4tvApiIntegration', live4tvApiIntegration);
        setIsLoading(live4tvApiIntegration.isLoading);
        setErrorMessage(live4tvApiIntegration.errorMessage || null);
        setInfoMessage(live4tvApiIntegration.infoMessage || null);
    }, [live4tvApiIntegration]);

    function handleCancel() {
        setIsLoading(false);
        cancelAddLive4tvApi();
    }

    function handleLogin(e: any) {
        e.preventDefault();
        resetIntegration();
        const request: Live4tvApiAuthRequest = {
            profile,
            network: streamTargetIntegration.code,
        }

        loginToLive4tvApi(request);
    }

    function getIcon() {
        switch (streamTargetIntegration.code) {
            case StreamTargetType.INSTAGRAM:
                return <Instagram />
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.YOUTUBE:
                return <YouTube />
            case StreamTargetType.TWITCH:
                return <SiTwitch />
            case StreamTargetType.TIKTOK:
                return <SiTiktok />
            default:
                return <LiveTv />
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()} className={classNames(classes.dialog)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div className={classNames(classes.wrapIcon)}>
                    {getIcon()} <span>{t("Stream to {{integration}}", {integration: t(streamTargetIntegration.code)})}</span>
                </div>
            </DialogTitle>

            <DialogContent>
                { isLoading ? (
                    <>
                        <p>{ t('Please proceed the login at your web browser (Google Chrome, Microsoft Edge, Safari, Firefox), after the system automatic close this box') }</p>
                        <LinearProgress className={classNames(classes.progressBar)} />
                    </>
                ) : (
                    <div className={classNames(classes.dataGrid)}>
                        { errorMessage ?
                            <>
                                <Alert severity="error" className={classNames(classes.alert)}>{ t(errorMessage) }</Alert>
                                <FormControl fullWidth className={classNames(classes.radioField)}>
                                </FormControl>
                            </>
                            : ''
                        }

                        { infoMessage ?
                            <>
                                <Alert severity="info" className={classNames(classes.alert)}>{ t(infoMessage) }</Alert>
                                <FormControl fullWidth className={classNames(classes.radioField)}>
                                </FormControl>
                            </>
                            : ''
                        }

                        { live4tvApiIntegration.streamTarget ? <>
                            <div className={classNames(classes.wrapIcon)}><b>{t('Account')}:</b>{getIcon()} <span>{live4tvApiIntegration.streamTarget.title}</span></div>
                            <FormControl fullWidth className={classNames(classes.radioField)}>
                            </FormControl>
                        </> : null }

                        <Button size="large" variant="contained" color="primary" className={classNames(classes.wrapIcon)} onClick={handleLogin}>
                            {getIcon()} <span>{t("Login on {{integration}}", {integration: t(streamTargetIntegration.code)})}</span>
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
        streamTargets: state.streamTargets,
        live4tvApiIntegration: state.live4tvApiIntegration,
    }
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        cancelAddLive4tvApi: () => dispatch(cancelAddLive4tvApi()),
        loginToLive4tvApi: (request: Live4tvApiAuthRequest) => dispatch(loginToLive4tvApi(request)),
        resetIntegration: () => dispatch(resetIntegration()),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Live4tvApi));
