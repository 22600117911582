import {LiveStreamDownloadRequest, LiveStreamDownloadResponse, LiveStreamMap} from "./LiveStreamDTO";

export const types = {
    FETCH_LIVE_STREAMS_REQUEST: "FETCH_LIVE_STREAMS_REQUEST",
    FETCH_LIVE_STREAMS_SUCCESS: "FETCH_LIVE_STREAMS_SUCCESS",
    FETCH_LIVE_STREAMS_FAIL: "FETCH_LIVE_STREAMS_FAIL",

    FETCH_LIVE_STREAM_DATA_DOWNLOAD_REQUEST: "FETCH_LIVE_STREAM_DATA_DOWNLOAD_REQUEST",
    FETCH_LIVE_STREAM_DATA_DOWNLOAD_SUCCESS: "FETCH_LIVE_STREAM_DATA_DOWNLOAD_SUCCESS",
    FETCH_LIVE_STREAM_DATA_DOWNLOADED_SUCCESS: "FETCH_LIVE_STREAM_DATA_DOWNLOADED_SUCCESS",
    FETCH_LIVE_STREAM_DATA_DOWNLOAD_FAIL: "FETCH_LIVE_STREAM_DATA_DOWNLOAD_FAIL",
}

export function fetchLiveStreamsRequest(token: string, code: string) {
    const request = { token, code };
    return {type: types.FETCH_LIVE_STREAMS_REQUEST, request }
}

export function fetchLiveStreamsSuccess(payload: LiveStreamMap) {
    return {type: types.FETCH_LIVE_STREAMS_SUCCESS, payload}
}

export function fetchLiveStreamsFail(error: string) {
    return {type: types.FETCH_LIVE_STREAMS_FAIL, error}
}

export function fetchLiveStreamDataDownloadRequest(payload: LiveStreamDownloadRequest) {
    return {type: types.FETCH_LIVE_STREAM_DATA_DOWNLOAD_REQUEST, payload}
}

export function fetchLiveStreamDataDownloadSuccess(payload: LiveStreamDownloadResponse) {
    return {type: types.FETCH_LIVE_STREAM_DATA_DOWNLOAD_SUCCESS, payload}
}

export function fetchLiveStreamDataDownloadFail(error: string) {
    return {type: types.FETCH_LIVE_STREAM_DATA_DOWNLOAD_FAIL, error}
}

export function downloadedSuccess() {
    return {type: types.FETCH_LIVE_STREAM_DATA_DOWNLOADED_SUCCESS}
}

