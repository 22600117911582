import {useLocation} from "react-router-dom";
import analyticsClient from "../../services/analytics-client/analytics-client";
import {Profile} from "../../features/User/userDTO";

export type GTMDefaultProps = {
    profile?: Profile,
}

function useGTM(props: GTMDefaultProps) {
    const location = useLocation();

    return {
        async trackEvent(eventName: string, eventParams: { [key: string]: any }) {
            eventParams.page = {
                url: document.location.origin + location.pathname + location.search,
                title: document.title,
            };

            if (props.profile) {
                eventParams.user = {
                    id: props.profile.code,
                };
            }

            await analyticsClient.trackEvent(eventName, eventParams);
        },
    }
}

export default useGTM;
