import React from "react";
import {Routes, Route, HashRouter as Router} from "react-router-dom";

// for some reason if login is not at this place, the app fails to load and stays with a blank screen (error Cannot access 'types' before initialization” error)
import LoginPage from "../features/User/LoginPage";
import SignupPage from "../features/User/SignupPage";

import Events from "../features/Events/ListEventsPage";
import Layout from "../components/Layout";
import Studio from "../features/Studio/StudioPage";
import NewEvent from "../features/Events/EventPage";
import StreamTargetPage from "../features/StreamTarget/StreamTargetPage";
import ConfigsPage from "../features/Config/ConfigsPage";
import PlanPage from "../features/Plan/PlanPage";
import Logout from "../features/User/LogoutPage";
import CommentsPage from "../features/Comments/CommentsPage";
import AppStructure from "../components/Layout/AppStructure";
import EmptyAppStructure from "../components/Layout/EmptyAppStructure";
import SubscriptionSuccessPage from "../features/Plan/SubscriptionSuccessPage";
import LiveStreamDataPage from "../features/LiveStreamData/LiveStreamDataPage";
import AffiliatePage from "../features/Affiliate/AffiliatePage";

function App() {
    return (
        // https://reactrouter.com/web/api/Switch
        <Router>
            <Layout>
                <Routes>
                    <Route element={<EmptyAppStructure />}>
                        <Route exact path="/" element={<LoginPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/signup" element={<SignupPage />} />

                        <Route path="/logout" element={<Logout />} />

                        <Route path="/widget">
                            <Route path="comments/:eventCode" element={<CommentsPage />} />
                        </Route>
                    </Route>

                    <Route element={<AppStructure />}>
                        <Route path="/events/new" element={<NewEvent />} />
                        <Route path="/events/:eventCode" element={<NewEvent />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/live-streams-data" element={<LiveStreamDataPage />} />
                        <Route path="/stream-targets" element={<StreamTargetPage />} />

                        <Route path="/studio/:eventCode" element={<Studio />} />

                        <Route path="/configs" element={<ConfigsPage />} />

                        <Route path="/plan" element={<PlanPage />} />
                        <Route path="/affiliate" element={<AffiliatePage />} />
                        <Route path="/subscription/success" element={<SubscriptionSuccessPage />} />
                    </Route>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
