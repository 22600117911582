import {StreamTargetDialog} from "./streamTargetDialogDTO";
import {StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {Dispatch} from "redux";
import {closeStreamTargetDialog} from "./streamTargetDialogActions";
import Live4tvApi from "../StreamTargetLive4tvApi/Live4tvApi";

type Properties = {
    streamTargetDialog: StreamTargetDialog,
    closeStreamTargetDialog: Function,
}

const STREAM_TARGET_DIALOGS: any = {
    [StreamTargetType.LIVE4TVAPI]: Live4tvApi,
};

function StreamTargetDialogWidget(props: Properties) {
    const {
        streamTargetDialog,
        closeStreamTargetDialog,
    } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(streamTargetDialog.isOpen);
    }, [streamTargetDialog]);

    function handleClose(_e: any) {
        console.debug('StreamTargetDialogWidget', 'handleClose');
        closeStreamTargetDialog();
    }

    function getDialog() {
        console.log('getDialog()', streamTargetDialog);
        if (!streamTargetDialog || !streamTargetDialog.type) {
            return <></>;
        }

        if (STREAM_TARGET_DIALOGS[streamTargetDialog.type] === undefined) {
            console.error('[StreamTargetDialog] There is no streamTargetDialog registered for ' + streamTargetDialog.type + ' did you forget to register one at StreamTargetDialogWidget.tsx?');
            return <></>;
        }

        const SpecificDialog = STREAM_TARGET_DIALOGS[streamTargetDialog.type];
        return <SpecificDialog {...streamTargetDialog.props} open={open} onClose={handleClose} />
    }

    return (
        getDialog()
    );
}

const mapStateToProps = function (state: any) {
    return {
        streamTargetDialog: state.streamTargetDialog,
    }
}

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        closeStreamTargetDialog: () => dispatch(closeStreamTargetDialog()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamTargetDialogWidget);
