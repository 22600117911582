import {call, put, takeEvery} from "redux-saga/effects";
import {
    fetchLiveStreamDataDownloadSuccess,
    fetchLiveStreamsFail,
    fetchLiveStreamsSuccess,
    types
} from "./liveStreamActions";
import {setIsLoading} from "../Events/eventsUIActions";
import {LiveStreamDownloadRequest, LiveStreamDownloadResponse, LiveStreamMap} from "./LiveStreamDTO";
import { live4tvLive4tvApiService } from "../StreamTargetLive4tvApi/live4tvLive4tvApiService";

function* liveStreamSaga() {
  yield takeEvery(types.FETCH_LIVE_STREAMS_REQUEST, fetchLiveStreamRequested);
  yield takeEvery(types.FETCH_LIVE_STREAM_DATA_DOWNLOAD_REQUEST, fetchLiveStreamDataDownloadRequested);
}

function* fetchLiveStreamRequested(action: { type: string, request: { token: string, code: string } }) {
  console.debug('[SAGA]', 'fetchLiveStreamRequested');
  yield put(setIsLoading(true));

  try {
      const liveStreams: LiveStreamMap = yield call(fetchLiveStreams, action.request);
        console.debug('[SAGA]', 'fetchLiveStreamRequested.succeeded', liveStreams);
        yield put(fetchLiveStreamsSuccess(liveStreams));
  } catch (e: any) {
    console.error('[SAGA]', 'fetchLiveStreamRequested.error', e);
    yield put(fetchLiveStreamsFail(e.toString()));
  } finally {
      yield put(setIsLoading(false));
  }
}

async function fetchLiveStreams(request: { token: string, code: string }): Promise<LiveStreamMap> {
    const items: LiveStreamMap = {};
    try {
        const data = await live4tvLive4tvApiService.getLiveStreamList(request.token, request.code);
        data.data.map((item: any) => {
            items[item.uuid] = {
                uuid: item.uuid,
                title: item.title,
                details: item.description,
                started_at: item.started_at,

            };
        });
    } catch (error) {
        console.error("fetchLiveStreams", {error});
    }

    return items;
}

function* fetchLiveStreamDataDownloadRequested(action: { type: string, payload: LiveStreamDownloadRequest }){
    console.log('[SAGA]', 'fetchLiveStreamDataDownloadRequested', {action});
    yield put(setIsLoading(true));

    try {
        const response: LiveStreamDownloadResponse = yield call(fetchLiveStreamDataDownload, action.payload);
        console.debug('[SAGA]', 'fetchLiveStreamDataDownloadRequested.succeeded', response);
        yield put(fetchLiveStreamDataDownloadSuccess(response));
    } catch (e: any) {
        console.error('[SAGA]', 'fetchLiveStreamDataDownloadRequested.error', e);
        yield put(fetchLiveStreamsFail(e.toString()));
    } finally {
        yield put(setIsLoading(false));
    }
}

async function fetchLiveStreamDataDownload(request: LiveStreamDownloadRequest): Promise<LiveStreamDownloadResponse> {
    const content = await live4tvLive4tvApiService.getLiveStreamDataDownload(request.token, request.liveStream.uuid);

    return { content, liveStream: request.liveStream };
}

export default liveStreamSaga;